import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { RoundUp } from '../../store/retirements';
import CloseIcon from '../../components/Common/CloseIcon';

const BorderedHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const BottomRow = styled(Row)`
  margin-top: 15px;
`;

const ModalButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const RetirementRounding = ({ retirement, isOpen, toggleModal }) => {
  const dispatch = useDispatch();
  const handleProcessOrder = () => {
    // const params = retirement.id;
    dispatch(RoundUp(retirement.id));
    toggleModal();
  };
  return (
    <StyledModal toggle={toggleModal} isOpen={isOpen}>
      <BorderedHeader>
        <Row>
          <Col>
            <h4>Retirement Round Up</h4>
          </Col>
          <CloseButton color="link" onClick={toggleModal}>
            <CloseIcon />
          </CloseButton>
        </Row>
      </BorderedHeader>
      <ModalBody>
        <Row>
          <Col>Are you sure you want to round up {retirement.projectCode}?</Col>
        </Row>
        <BottomRow>
          <Col>
            <ModalButton onClick={toggleModal} color="secondary">
              Cancel
            </ModalButton>
          </Col>
          <Col className="text-end">
            <ModalButton onClick={handleProcessOrder} color="primary">
              Ok
            </ModalButton>
          </Col>
        </BottomRow>
      </ModalBody>
    </StyledModal>
  );
};

export default RetirementRounding;

RetirementRounding.propTypes = {
  retirement: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
