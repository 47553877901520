import React from 'react';
import noData from '../../assets/images/no-data.svg';

const NoDataIndicationRecords = () => (
  <div className="d-flex justify-content-center m-5" style={{ width: '75vw' }}>
    <div className="text-center">
      <img src={noData} alt="no data" />
      <h4 className="mt-4">Nothing found in records</h4>
      <span className="text-muted">
        You haven&apos;t specified the necessary parameters in the filter yet
      </span>
    </div>
  </div>
);

export default NoDataIndicationRecords;
