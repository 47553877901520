import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { addProject } from '../../store/allProjects';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  display: grid;
`;

const SaveButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const NewProjectForm = () => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => ({
    error: state.allProjects.error,
  }));
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: '',
      title: '',
      indulgenceAmount: '',
      purchasePrice: '',
      salesPrice: '',
      otherFees: '',
      pdfLink: '',
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .min(4)
        .max(20)
        .required('Please Enter Project No')
        .matches(
          /^[- "'`.+()]*[A-Za-z0-9][- "'`.+()A-Za-z0-9]*$/,
          'Field must contain letters or numbers',
        ),
      title: Yup.string()
        .min(1)
        .max(250)
        .required('Please Enter Project Name')
        .matches(
          /^[- '`.+()]*[A-Za-z0-9][- '`.+()A-Za-z0-9]*$/,
          'Field must contain letters or numbers',
        ),
      indulgenceAmount: Yup.string()
        .max(7)
        .matches(/^\d+$/, 'Field must contain only an integer')
        .required('Please Enter Purchased Quantity')
        .typeError('You must specify a number'),
      purchasePrice: Yup.string()
        .required('Please Enter Purchase Price')
        .typeError('You must specify a number'),
      salesPrice: Yup.string()
        .required('Please Enter Sales Price')
        .typeError('You must specify a number'),
      pdfLink: Yup.string().max(250).nullable().url('URL is not valid'),
    }),
    onSubmit: (params) => {
      dispatch(
        addProject({
          params: params.otherFees ? params : { ...params, otherFees: 0 },
        }),
      );
    },
  });

  return (
    <FormContainer>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {error ? <Alert color="danger">{error}</Alert> : null}
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-label">
                Project No
                <Asterix>*</Asterix>
              </Label>
              <Input
                name="code"
                className="form-control"
                placeholder="Project No"
                type="code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.code || ''}
                invalid={!!(validation.touched.code && validation.errors.code)}
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                Project Name
                <Asterix>*</Asterix>
              </Label>
              <div className="input-group">
                <Input
                  name="title"
                  value={validation.values.title || ''}
                  type="title"
                  placeholder="Project Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(validation.touched.title && validation.errors.title)
                  }
                />
                {validation.touched.title && validation.errors.title ? (
                  <FormFeedback type="invalid">
                    {validation.errors.title}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                Purchased Quantity
                <Asterix>*</Asterix>
              </Label>
              <div className="input-group">
                <Input
                  name="indulgenceAmount"
                  value={validation.values.indulgenceAmount || ''}
                  type="indulgenceAmount"
                  placeholder="Purchased Quantity"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(
                      validation.touched.indulgenceAmount &&
                      validation.errors.indulgenceAmount
                    )
                  }
                />
                {validation.touched.indulgenceAmount &&
                validation.errors.indulgenceAmount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.indulgenceAmount}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">URL Link</Label>
              <div className="input-group">
                <Input
                  name="pdfLink"
                  value={validation.values.pdfLink || ''}
                  type="pdfLink"
                  placeholder="URL link to document"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(validation.touched.pdfLink && validation.errors.pdfLink)
                  }
                />
                {validation.touched.pdfLink && validation.errors.pdfLink ? (
                  <FormFeedback type="invalid">
                    {validation.errors.pdfLink}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                Purchase Price, $<Asterix>*</Asterix>
              </Label>
              <div className="input-group">
                <Input
                  name="purchasePrice"
                  value={validation.values.purchasePrice || ''}
                  type="purchasePrice"
                  placeholder="Purchase Price"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(
                      validation.touched.purchasePrice &&
                      validation.errors.purchasePrice
                    )
                  }
                />
                {validation.touched.purchasePrice &&
                validation.errors.purchasePrice ? (
                  <FormFeedback type="invalid">
                    {validation.errors.purchasePrice}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                Sales Price, $<Asterix>*</Asterix>
              </Label>
              <div className="input-group">
                <Input
                  name="salesPrice"
                  value={validation.values.salesPrice || ''}
                  type="salesPrice"
                  placeholder="Sales Price"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(
                      validation.touched.salesPrice &&
                      validation.errors.salesPrice
                    )
                  }
                />
                {validation.touched.salesPrice &&
                validation.errors.salesPrice ? (
                  <FormFeedback type="invalid">
                    {validation.errors.salesPrice}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">Other Fees, $</Label>
              <div className="input-group">
                <Input
                  name="otherFees"
                  value={validation.values.otherFees}
                  type="otherFees"
                  placeholder="Other Fees"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(
                      validation.touched.otherFees &&
                      validation.errors.otherFees
                    )
                  }
                />
                {validation.touched.otherFees && validation.errors.otherFees ? (
                  <FormFeedback type="invalid">
                    {validation.errors.otherFees}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>

        <BottomRow>
          <Col>
            <SaveButton type="submit" color="primary">
              Save
            </SaveButton>
          </Col>
        </BottomRow>
      </Form>
    </FormContainer>
  );
};

export default NewProjectForm;
