import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import IconButton from '../../components/Tables/IconButton';
import CloseIcon from '../../components/Common/CloseIcon';

const StyledModal = styled(Modal)`
  width: 430px;
`;

const MainHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const TitleCol = styled(Col)`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const TotalsHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;

  .modal-title {
    width: 100%;
    padding: 0 28px;
  }
`;

const TotalRow = styled(Row)`
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
`;

const Totals = styled.div`
  padding: 0px 28px 0 28px;
`;

const BoldCol = styled(Col)`
  font-size: 16px;
  font-weight: 600;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  width: 113px;
  height: 36px;
  padding: 0px;
  margin-top: 7px;
  font-size: 18px;
  border-radius: 8px;
`;

const OrderInfo = ({ selectedOrders }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  return (
    <>
      <IconButton
        color="info"
        icon="bx-info-circle"
        text="Info"
        onClick={toggleModal}
      />
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <MainHeader>
          <Row>
            <TitleCol>Info</TitleCol>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </MainHeader>
        <TotalsHeader>
          <TotalRow>
            <BoldCol xs={12}>General Info</BoldCol>
          </TotalRow>
          <TotalRow>
            <Col xs={8}>Selected Orders</Col>
            <Col xs={4}>{selectedOrders.length}</Col>
          </TotalRow>
        </TotalsHeader>
        <ModalBody>
          <Totals>
            <TotalRow>
              <BoldCol xs={12}>Offset summary</BoldCol>
            </TotalRow>
            <TotalRow>
              <Col xs={8}>Total offsets</Col>
              <Col xs={4}>
                {selectedOrders.reduce((sum, o) => sum + +o.totalOffset, 0)}
              </Col>
            </TotalRow>
            <TotalRow>
              <Col xs={8}>Retired offsets</Col>
              <Col xs={4}>
                {selectedOrders.reduce((sum, o) => sum + +o.totalRetirement, 0)}
              </Col>
            </TotalRow>
            <TotalRow>
              <Col xs={8}>Total Remaining</Col>
              <Col xs={4}>
                {selectedOrders.reduce((sum, o) => sum + +o.totalRemaning, 0)}
              </Col>
            </TotalRow>
          </Totals>
          <BottomRow>
            <StyledButton onClick={toggleModal} color="primary">
              Close
            </StyledButton>
          </BottomRow>
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default OrderInfo;

OrderInfo.propTypes = {
  selectedOrders: PropTypes.array.isRequired,
};
