import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import PageTitle from '../../components/Common/PageTitle';
import error from '../../assets/images/error-img.png';

const Pages404 = () => (
  <>
    <div className="account-pages my-5 pt-5">
      <PageTitle title="404 Not Found" />
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 font-weight-medium">
                4<i className="bx bx-buoy bx-spin text-primary display-3" />4
              </h1>
              <h4 className="text-uppercase">
                We&apos;re sorry, but this page either doesn&apos;t exist or you
                have no permissions to view it
              </h4>
              <div className="mt-5 text-center">
                <Link className="btn btn-primary " to="/home">
                  Back to Home
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={error} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Pages404;
