import { takeEvery, call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import {
  getBillingSummary,
  editBillingSummary,
  downloadBillingSummary,
  deleteBillingSummary,
  sentBillingSummary,
} from './actions';
import {
  GET_BILLING_SUMMARY,
  EDIT_BILLING_SUMMARY,
  DOWNLOAD_BILLING_SUMMARY,
  DELETE_BILLING_SUMMARY,
  SENT_BILLING_SUMMARY,
} from './actionTypes';
import { asyncSaga } from '../common';
import { billings } from '../../api';
import Notification from '../../components/Common/Notification';

function* downloadBillingSummarySaga({ payload: { id } }) {
  try {
    const response = yield call(billings.downloadBillings, { id });
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `billings_${new Date().getTime()}.xlsx`);
    yield put(downloadBillingSummary.success(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

function* editBillingSummarySaga({ payload: { id, params } }) {
  try {
    const response = yield call(billings.editBillings, {
      id,
      params,
    });

    const { billing } = response.data.data;
    yield put(editBillingSummary.success({ billing }));
    if (!params.name && params.status === 'SENT') {
      Notification('success', `${billing.name} has been marked as sent.`);
    }

    if (!params.name && params.status === 'UNSENT') {
      Notification('success', `${billing.name} has been marked as unsent.`);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(editBillingSummary.fail(error.response.data.message));
      case 409:
        return yield put(editBillingSummary.fail(error.response.data.message));
      default:
        return yield put(editBillingSummary.fail('Something went wrong'));
    }
  }
}

function* sentBillingSummarySaga({ payload: { id, params } }) {
  try {
    const response = yield call(billings.sentBillings, {
      id,
      params,
    });

    const { billing } = response.data.data;
    Notification('success', `${billing.name} has been marked as sent.`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(sentBillingSummary.fail(error.response.data.message));
      case 409:
        return yield put(sentBillingSummary.fail(error.response.data.message));
      default:
        return yield put(sentBillingSummary.fail('Something went wrong'));
    }
  }
}

function* deleteBillingSummarySaga({ payload: { id } }) {
  try {
    yield call(billings.deleteBilling, { id });
    yield put(deleteBillingSummary.success({ id }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default function* billingSaga() {
  yield takeEvery(
    GET_BILLING_SUMMARY.RUN,
    asyncSaga((action) => billings.getBillings(action), getBillingSummary),
  );
  yield takeEvery(DOWNLOAD_BILLING_SUMMARY.RUN, downloadBillingSummarySaga);
  yield takeEvery(EDIT_BILLING_SUMMARY.RUN, editBillingSummarySaga);
  yield takeEvery(SENT_BILLING_SUMMARY.RUN, sentBillingSummarySaga);
  yield takeEvery(DELETE_BILLING_SUMMARY.RUN, deleteBillingSummarySaga);
}
