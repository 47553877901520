import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Form,
  Input,
  FormFeedback,
  Label,
} from 'reactstrap';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { verifyOTPApp, clearUserError } from '../../store/users';

const OTPRegister = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.users.currentUser,
  }));
  const { error } = useSelector((state) => ({
    error: state.users.error,
  }));

  useEffect(() => {
    dispatch(clearUserError());
  }, []);

  useEffect(() => {
    if (!currentUser) {
      history.push('/login');
    }
  }, [currentUser, history]);

  const [bindingUri, setBindingUri] = useState('');

  useEffect(() => {
    const fetchQRCode = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/api/users/registerOTPApp`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: currentUser.email }),
        },
      );
      const result = await response.json();
      if (result.bindingUri) {
        setBindingUri(result.bindingUri);
      }
    };

    fetchQRCode();
  }, [currentUser]);

  const validation = useFormik({
    initialValues: { otpCode: '' },
    validationSchema: Yup.object({
      otpCode: Yup.string()
        .required('Please enter the verification code')
        .matches(/^\d{6}$/, 'Code must be a 6-digit number'),
    }),
    onSubmit: async (values) => {
      dispatch(
        verifyOTPApp({
          params: {
            email: currentUser.email,
            code: values.otpCode,
          },
          history,
        }),
      );
    },
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card
              className="overflow-hidden p-4"
              style={{
                minHeight: '500px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardBody className="d-flex flex-column justify-content-between">
                <h4 className="text-left mb-4">Register Authenticator App</h4>
                <p className="text-muted text-left mb-4">
                  Scan the QR code below to register your authenticator app.
                </p>
                {bindingUri && (
                  <div className="text-center mb-4">
                    <QRCodeSVG value={bindingUri} size={200} />
                  </div>
                )}
                <Form
                  onSubmit={validation.handleSubmit}
                  className="d-flex flex-column justify-content-between"
                  style={{ flex: 1 }}
                >
                  <div>
                    <div className="mb-3">
                      <Label>Verification Code</Label>
                      <Input
                        name="otpCode"
                        type="text"
                        placeholder="XXXXXX"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.otpCode}
                        invalid={
                          !!(
                            validation.touched.otpCode &&
                            validation.errors.otpCode
                          ) || !!error
                        }
                        className="form-control text-left"
                      />
                      {validation.touched.otpCode &&
                        validation.errors.otpCode && (
                          <FormFeedback type="invalid">
                            {validation.errors.otpCode}
                          </FormFeedback>
                        )}
                      {error && !validation.errors.otpCode && (
                        <FormFeedback type="invalid">{error}</FormFeedback>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="d-grid">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={validation.values.otpCode.length < 1}
                      >
                        Verify
                      </Button>
                    </div>
                    <div className="mt-2 text-center">
                      <Button
                        color="link"
                        onClick={() => history.push('/2fa-login/authenticator')}
                      >
                        &lt; Back
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OTPRegister;
