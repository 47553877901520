import { takeEvery, call, put } from 'redux-saga/effects';
import {
  getRetirements,
  editRetirement,
  checkRetirement,
  deleteRetirement,
  exportCsv,
  RoundUp,
  countSameReference,
} from './actions';
import {
  GET_RETIREMENTS,
  EDIT_RETIREMENT,
  CHECK_RETIREMENT,
  DELETE_RETIREMENT,
  EXPORT_RETIREMENT_CSV,
  ROUND_UP_RETIREMENT,
  COUNT_SAME_REFERENCE,
} from './actionTypes';
import { asyncSaga } from '../common';
import { retirements } from '../../api';
import Notification from '../../components/Common/Notification';

function* editRetirementSaga({ payload: { id, params } }) {
  try {
    const response = yield call(retirements.editRetirement, { id, params });
    const { retirement } = response.data.data;
    yield put(editRetirement.success({ retirement }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    if (error.response?.status === 504) {
      Notification(
        'warning',
        'Your request has been timed out, please try refreshing the page within the next few minutes',
      );
    } else {
      Notification('error', error.message);
    }
    switch (error.response?.status) {
      case 422:
        return yield put(editRetirement.fail('Field must be positive number'));
      case 504:
        return;
      default:
        return yield put(editRetirement.fail('Something went wrong'));
    }
  }
}

function* checkRetirementSaga({ payload: { id, params } }) {
  try {
    const response = yield call(retirements.checkRetirement, {
      id,
      params,
    });
    const { retirementId, nonReadyIndulgences } = response.data.data;
    yield put(checkRetirement.success({ retirementId, nonReadyIndulgences }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* deleteRetirementSaga({ payload: { id } }) {
  try {
    yield call(retirements.deleteRetirement, { id });
    yield put(deleteRetirement.success({ id }));
    Notification('success', `Retirement has been deleted`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* exportCsvSaga({ payload }) {
  try {
    const response = yield call(retirements.exportCsv, payload);
    const csvBlob = new Blob([response.data], { type: 'text/csv' });
    saveAs(csvBlob, `retirements_${new Date().getTime()}.csv`);
    yield put(exportCsv.success(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(exportCsv.fail(error.message));
      default:
        return yield put(exportCsv.fail('Something went wrong'));
    }
  }
}

function* RoundUpRetirementSaga({ payload: id }) {
  try {
    const response = yield call(retirements.RoundUp, { id });
    const { retirement } = response.data.data;
    yield put(RoundUp.success({ retirement }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* countSameReferenceSaga({ payload: reference }) {
  try {
    const response = yield call(retirements.countSameReference, { reference });
    const { data } = response.data;
    yield put(countSameReference.success({ data }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default function* retirementsSaga() {
  yield takeEvery(
    GET_RETIREMENTS.RUN,
    asyncSaga((action) => retirements.getRetirements(action), getRetirements),
  );
  yield takeEvery(ROUND_UP_RETIREMENT.RUN, RoundUpRetirementSaga);
  yield takeEvery(EDIT_RETIREMENT.RUN, editRetirementSaga);
  yield takeEvery(CHECK_RETIREMENT.RUN, checkRetirementSaga);
  yield takeEvery(DELETE_RETIREMENT.RUN, deleteRetirementSaga);
  yield takeEvery(EXPORT_RETIREMENT_CSV.RUN, exportCsvSaga);
  yield takeEvery(COUNT_SAME_REFERENCE.RUN, countSameReferenceSaga);
}
