import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import IconButton from '../../components/Tables/IconButton';
import CloseIcon from '../../components/Common/CloseIcon';
import MoveToBilledForm from './MoveToBilledForm';
import { toggleBilledOrdersModal } from '../../store/orders';

const BorderedHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const MoveToBilled = ({ selectedOrders, error, isOpenBilledModal }) => {
  const dispatch = useDispatch();
  const toggleModal = () => dispatch(toggleBilledOrdersModal());

  return (
    <>
      <IconButton
        color="primary"
        icon="bx-wallet"
        text="Create Billing Summary"
        onClick={toggleModal}
      />
      <StyledModal toggle={toggleModal} isOpen={isOpenBilledModal}>
        <BorderedHeader>
          <Row>
            <Col>
              <h4>Create Billing Summary</h4>
            </Col>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </BorderedHeader>
        <ModalBody>
          <MoveToBilledForm
            selectedOrders={selectedOrders}
            toggleModal={toggleModal}
            error={error}
          />
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default MoveToBilled;

MoveToBilled.propTypes = {
  selectedOrders: PropTypes.array.isRequired,
  isOpenBilledModal: PropTypes.bool.isRequired,
  error: PropTypes.string,
};
