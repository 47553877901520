import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Switch from 'react-switch';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DropdownToggleSpan = styled(DropdownToggle)`
  cursor: pointer;
  color: #556ee6;
  text-decoration: underline;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 240px;
  transform: none !important;
`;

const SwitchItemContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const SwitchItemText = styled.span`
  margin-left: 15px;
  font-size: 14px;
  line-height: 21px;
`;

const SwitchItem = ({ item, switchableColumns, setSwitchableColumns }) => {
  const onSwitch = (checked) => {
    const updatedColumns = switchableColumns.map((column) =>
      column.text === item.text ? { ...column, active: checked } : column,
    );

    setSwitchableColumns(updatedColumns);
    localStorage.setItem('settings', JSON.stringify(updatedColumns));
  };

  return (
    <SwitchItemContainer>
      <span>
        <Switch
          id={item.text}
          onChange={onSwitch}
          width={24}
          height={10}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#556EE6"
          offColor="#CCCCCC"
          checked={item.active}
        />
        <SwitchItemText>{item.text}</SwitchItemText>
      </span>
      <i className="mdi mdi-drag" />
    </SwitchItemContainer>
  );
};

const SettingsDropdown = ({
  isSettingsShown,
  onClick,
  switchableColumns,
  setSwitchableColumns,
}) => {
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    const updatedColumns = [...switchableColumns];

    const [reorderedItem] = updatedColumns.splice(droppedItem.source.index, 1);
    updatedColumns.splice(droppedItem.destination.index, 0, reorderedItem);

    setSwitchableColumns(updatedColumns);
    localStorage.setItem('settings', JSON.stringify(updatedColumns));
  };

  const handleToggle = (e) => {
    onClick(e);
  };

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Dropdown
        className="ButtonDropdown"
        toggle={handleToggle}
        isOpen={isSettingsShown}
      >
        <span>
          <DropdownToggleSpan
            className="cursor-pointercursor-pointercursor-pointer"
            color="primary"
            data-toggle="dropdown"
            tag="span"
          >
            Display Settings
          </DropdownToggleSpan>
        </span>
        <StyledDropdownMenu>
          <Droppable droppableId="settings-container">
            {(provided) => (
              <div
                className="settings-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {switchableColumns.map((item, index) => (
                  <Draggable
                    key={item.dataField}
                    draggableId={item.dataField}
                    index={index}
                  >
                    {(providedItem, snapshot) => (
                      <div
                        className="item-container"
                        ref={providedItem.innerRef}
                        {...providedItem.dragHandleProps}
                        {...providedItem.draggableProps}
                        style={{
                          backgroundColor: snapshot.isDragging
                            ? '#556EE678'
                            : '#FFFFFF',
                          ...providedItem.draggableProps.style,
                        }}
                      >
                        <SwitchItem
                          item={item}
                          switchableColumns={switchableColumns}
                          setSwitchableColumns={setSwitchableColumns}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </StyledDropdownMenu>
      </Dropdown>
    </DragDropContext>
  );
};

SwitchItem.propTypes = {
  item: PropTypes.object.isRequired,
  switchableColumns: PropTypes.array.isRequired,
  setSwitchableColumns: PropTypes.func.isRequired,
};

SettingsDropdown.propTypes = {
  isSettingsShown: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  switchableColumns: PropTypes.array.isRequired,
  setSwitchableColumns: PropTypes.func.isRequired,
  isSettingsEnabled: PropTypes.bool.isRequired,
};

export default SettingsDropdown;
