import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import moment from 'moment';
import { editBillingSummary } from '../../store/billings';
import { DatePickerField } from '../../components/Common/DatePicker';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  display: grid;
`;

const SaveButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const EditBillngSummary = ({ row: { sendDate, name, id } }) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.billings.error);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: sendDate
      ? {
          name,
          sendDate: moment(sendDate).format('YYYY.MM.DD'),
        }
      : {
          name,
        },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(250)
        .required('Please Enter name')
        .matches(
          /^[^\s].+[^\s]*[- .+()]*[A-Za-z0-9][- .+()A-Za-z0-9]*$/,
          'Field must contain letters and numbers',
        ),
      sendDate: Yup.date().nullable().optional(),
    }),
    onSubmit: (params) => {
      dispatch(editBillingSummary({ id, params }));
    },
  });

  return (
    <FormContainer>
      <FormikProvider value={validation}>
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          {error ? <Alert color="danger">{error}</Alert> : null}
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">
                  Billing Summary Name
                  <Asterix>*</Asterix>
                </Label>
                <Input
                  name="name"
                  className="form-control"
                  placeholder="Billing Summary Name"
                  type="name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ''}
                  invalid={
                    !!(validation.touched.name && validation.errors.name)
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">Send Date</Label>
                <DatePickerField
                  onChange={validation.handleChange}
                  name="sendDate"
                  placeholderText="Send Date"
                  maxDate={null}
                />
              </FormGroup>
            </Col>
          </Row>

          <BottomRow>
            <Col>
              <SaveButton type="submit" color="primary">
                Save
              </SaveButton>
            </Col>
          </BottomRow>
        </Form>
      </FormikProvider>
    </FormContainer>
  );
};

EditBillngSummary.propTypes = {
  row: PropTypes.object.isRequired,
};

export default EditBillngSummary;
