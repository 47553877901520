import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  Input,
  Label,
  FormFeedback,
  Container,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import MetaTags from 'react-meta-tags';
import { useUsers } from '../../hooks';
import { checkToken } from '../../store/users';

const Asterix = styled.span`
  color: red;
`;

const SaveButton = styled(Button)`
  width: 100%;
  font-size: 16px;
`;

const ChangePassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === '/reset-password') {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const email = params.get('email');
      if (!token || !email) {
        history.push('/login');
      }
      dispatch(checkToken({ email, token, history }));
    }
  }, [location]);
  const { editPassword } = useUsers();

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const togglePasswordShown = () => setIsPasswordShown(!isPasswordShown);

  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
  const toggleConfirmPasswordShown = () =>
    setIsConfirmPasswordShown(!isConfirmPasswordShown);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Please Enter User Password')
        .matches(
          /^(?=.*\d)(?=.*[!@#$%^&*?_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
      confirmPassword: Yup.string()
        .required('Please Confirm User Password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: (params) => {
      const query = new URLSearchParams(location.search);
      // eslint-disable-next-line no-param-reassign
      params.email = query.get('email');
      editPassword({ params, history });
    },
  });

  const { error } = useSelector((state) => ({
    error: state.users.error,
  }));

  return (
    <>
      <MetaTags>
        <title>Reset password</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={6}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <CardTitle>Create new password</CardTitle>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? (
                        <Alert color="danger">{error.toString()}</Alert>
                      ) : null}
                      <Row className="mt-4">
                        <Col>
                          <div className="mb-3">
                            <Label className="form-label">
                              Password
                              <Asterix>*</Asterix>
                            </Label>
                            <div className="input-group">
                              <Input
                                name="password"
                                value={validation.values.password || ''}
                                type={isPasswordShown ? 'text' : 'password'}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  !!(
                                    validation.touched.password &&
                                    validation.errors.password
                                  )
                                }
                              />
                              <button
                                onClick={togglePasswordShown}
                                className="btn btn-light"
                                type="button"
                              >
                                <i
                                  className={
                                    !isPasswordShown
                                      ? 'mdi mdi-eye-outline'
                                      : 'mdi mdi-eye-off-outline'
                                  }
                                ></i>
                              </button>
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <div className="mb-3">
                            <Label className="form-label">
                              Confirm Password
                              <Asterix>*</Asterix>
                            </Label>
                            <div className="input-group">
                              <Input
                                name="confirmPassword"
                                value={validation.values.confirmPassword || ''}
                                type={
                                  isConfirmPasswordShown ? 'text' : 'password'
                                }
                                placeholder="Confirm Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  !!(
                                    validation.touched.confirmPassword &&
                                    validation.errors.confirmPassword
                                  )
                                }
                              />
                              <button
                                onClick={toggleConfirmPasswordShown}
                                className="btn btn-light"
                                type="button"
                              >
                                <i
                                  className={
                                    !isConfirmPasswordShown
                                      ? 'mdi mdi-eye-outline'
                                      : 'mdi mdi-eye-off-outline'
                                  }
                                ></i>
                              </button>
                              {validation.touched.confirmPassword &&
                              validation.errors.confirmPassword ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.confirmPassword}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <p>
                          At least 8 characters, 1 lower case letter, 1
                          uppercase letter, 1 special character, 1 number.
                        </p>
                      </Row>
                      <Row className="mt-1">
                        <SaveButton type="submit" color="primary">
                          Reset password
                        </SaveButton>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

ChangePassword.propTypes = {};

export default ChangePassword;
