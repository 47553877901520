/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';

export const CustomSelect = ({ onChange, options, value }) => {
  const defaultValue = (opt, val) => {
    return opt ? opt.filter((option) => option.value === val) : '';
  };

  return (
    <div>
      <Select
        value={defaultValue(options, value)}
        onChange={(val) => {
          onChange(val);
        }}
        options={options}
      />
    </div>
  );
};
