import axios from 'axios';
import accessToken from './jwt-token-access/accessToken';

// pass new generated access token here
const token = accessToken;

// apply base url for axios
const API_URL = '';

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common.Authorization = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export function get(url, config = {}) {
  return axiosApi.get(url, { ...config }).then((response) => response.data);
}

export function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export function del(url, config = {}) {
  return axiosApi.delete(url, { ...config }).then((response) => response.data);
}
