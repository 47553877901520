import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import Overlay from './Overlay';
import NoDataIndication from './NoDataIndication';

const BillingSummaryTable = ({
  isLoading,
  data,
  columns,
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  sort,
}) => (
  <PaginationProvider
    pagination={paginationFactory({
      page,
      sizePerPage,
      totalSize,
      custom: true,
    })}
    keyField="id"
    columns={columns}
    data={data}
  >
    {({ paginationProps, paginationTableProps }) => (
      <>
        <Row>
          <Col xl="12">
            <div className="mt-4">
              <BootstrapTable
                keyField={'id'}
                columns={columns}
                data={data}
                loading={isLoading}
                overlay={Overlay}
                noDataIndication={NoDataIndication}
                responsive
                bordered={false}
                striped={false}
                classes={'table align-middle table-nowrap'}
                headerWrapperClasses={'table-light'}
                onTableChange={onTableChange}
                remote={{
                  filter: true,
                  pagination: true,
                  sort: true,
                }}
                sort={sort}
                {...paginationTableProps}
              />
            </div>
          </Col>
        </Row>

        <Row className="align-items-md-center mt-30">
          <Col className="inner-custom-pagination d-flex">
            <div className="text-md-right ms-auto">
              <PaginationListStandalone {...paginationProps} />
            </div>
          </Col>
        </Row>
      </>
    )}
  </PaginationProvider>
);

BillingSummaryTable.propTypes = {
  data: PropTypes.array,
  page: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number,
  sort: PropTypes.shape({
    dataField: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  onTableChange: PropTypes.func.isRequired,
};

export default BillingSummaryTable;
