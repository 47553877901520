import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailRecipients } from '../store/emailRecipients';

export const useEmailRecipients = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.emailRecipients.emailRecipients);
  const isLoading = useSelector((state) => state.emailRecipients.isLoading);
  const error = useSelector((state) => state.emailRecipients.error);

  useEffect(() => {
    dispatch(getEmailRecipients());
  }, [dispatch]);

  return {
    data,
    isLoading,
    error,
  };
};
