import urls from './urls';
import client from '../utils/api';

const getRetirements = (query = {}) =>
  client.get(urls.retirements.getRetirements(), query);
const editRetirement = ({ id, params }) =>
  client.post(urls.retirements.editRetirement(id), params);
const checkRetirement = ({ id, params }) =>
  client.post(urls.retirements.checkRetirement(id), params);
const checkStatus = ({ id }) => client.get(urls.retirements.checkStatus(id));
const checkBillings = ({ id }) =>
  client.get(urls.retirements.checkBillings(id));
const deleteRetirement = ({ id }) =>
  client.del(urls.retirements.deleteRetirement(id), { id });
const exportCsv = (query = {}) =>
  client.get(urls.retirements.exportCsv(), query);
const RoundUp = ({ id }) => client.put(urls.retirements.RoundUp(id), { id });
const countSameReference = ({ reference }) =>
  client.post(urls.retirements.countSameReference(), { reference });
export default {
  getRetirements,
  editRetirement,
  checkRetirement,
  deleteRetirement,
  exportCsv,
  RoundUp,
  checkStatus,
  checkBillings,
  countSameReference,
};
