const buildApiClient = (gateway) => {
  return {
    get: (url, query) => gateway.get(url, { params: query }),
    post: (url, body, query, options = {}) =>
      gateway.post(url, body, { params: query }, options),
    put: (url, body) => gateway.put(url, body),
    patch: (url, body) => gateway.patch(url, body),
    del: (url, body) => gateway.delete(url, body),
    gateway,
  };
};

export default buildApiClient;
