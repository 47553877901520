import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';

const PageTitle = ({ title }) => (
  <MetaTags>
    <title>Lenovo OMS | {title}</title>
  </MetaTags>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default memo(PageTitle);
