/* eslint-disable no-shadow */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CustomSelect } from './CustomSelect';
import { useProjects } from '../../hooks';
import Loader from '../../components/Common/Loader';
import { editRecord } from '../../store/records';

const BottomRow = styled(Row)`
  display: grid;
  margin-top: 10px;
  margin-left 205px;
`;

const FormContainer = styled.div`
  display: grid;
`;

const SaveButton = styled(Button)`
  width: 130px;
  font-size: 16px;
  margin-left 25px;
  border-radius: 8px;
  height: 40px;
`;
const projectOptions = (projects) =>
  projects.map((project) => ({
    value: project,
    label: project.code,
  }));

const statusField = [
  { value: 'NEW', label: 'New' },
  { value: 'INVALID', label: 'Invalid' },
  { value: 'DUPLICATE', label: 'Duplicate' },
];

const EditOrderForm = ({
  records: {
    uid,
    serialNumber,
    svcpn,
    svcpnDescription,
    sdfCode,
    salesOrderItemShippingCountry,
    salesOrderNumber,
    customerSoldName,
    customerShipName,
    certificateId,
    status,
    projectCode,
    projectTitle,
    id,
  },
  orderedAt,
  salesOrderItemShipAt,
  toggleModal,
}) => {
  const { data, isLoading } = useProjects();

  const dispatch = useDispatch();
  const { error } = useSelector((state) => ({
    error: state.records.error,
  }));
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      project: null,
      uid,
      orderedAt: orderedAt || '',
      serialNumber: serialNumber || '' || null,
      salesOrderItemShipAt: salesOrderItemShipAt || '',
      svcpn,
      svcpnDescription,
      sdfCode,
      salesOrderItemShippingCountry,
      salesOrderNumber,
      customerSoldName: customerSoldName || '' || null,
      customerShipName: customerShipName || '' || null,
      certificateId,
      projectCode,
      status,
      id,
    },
    validationSchema: Yup.object({
      serialNumber: Yup.string()
        .nullable()
        .matches(/^[^\s].+[^\s]*$/, 'Field must contain letters or numbers'),
      customerSoldName: Yup.string()
        .nullable()
        .matches(/^[^\s].+[^\s]*$/, 'Field must contain letters or numbers'),
      customerShipName: Yup.string()
        .nullable()
        .matches(/^[^\s].+[^\s]*$/, 'Field must contain letters or numbers'),
      status: Yup.string().required('Please Enter Project Name'),
    }),
    onSubmit: (params) => {
      const {
        serialNumber,
        customerSoldName,
        customerShipName,
        project,
        status,
      } = params;
      const projectId = project?.id || null;
      if (projectId !== null) {
        dispatch(
          editRecord({
            id,
            params: {
              projectId,
              serialNumber,
              customerSoldName,
              customerShipName,
              status,
            },
          }),
        );
      } else {
        dispatch(
          editRecord({
            id,
            params: {
              serialNumber,
              customerSoldName,
              customerShipName,
              status,
            },
          }),
        );
      }
      if (error) {
        toggleModal();
      }
    },
  });

  if (isLoading || !data)
    return (
      <FormContainer>
        <Loader isShown />
      </FormContainer>
    );

  return (
    <FormContainer>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {error ? <Alert color="danger">{error}</Alert> : null}
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-label">UID</Label>
              <Input
                disabled
                name="uid"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.uid || ''}
                type="uid"
                placeholder="UID"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label className="form-label">Order Date</Label>
              <div className="input-group">
                <Input
                  disabled
                  name="orderedAt"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.orderedAt || ''}
                  type="orderedAt"
                  placeholder="Order Date"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-label">Serial No</Label>
              <Input
                name="serialNumber"
                className="form-control"
                placeholder="Serial No"
                type="serialNumber"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.serialNumber || ''}
                invalid={
                  !!(
                    validation.touched.serialNumber &&
                    validation.errors.serialNumber
                  )
                }
              />
              {validation.touched.serialNumber &&
              validation.errors.serialNumber ? (
                <FormFeedback type="invalid">
                  {validation.errors.serialNumber}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label className="form-label">Machine Ship Date</Label>
              <div className="input-group">
                <Input
                  disabled
                  name="salesOrderItemShipAt"
                  value={validation.values.salesOrderItemShipAt || ''}
                  type="salesOrderItemShipAt"
                  placeholder="Machine Ship Date"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-label">SVC PN</Label>
              <Input
                disabled
                name="svcpn"
                className="form-control"
                placeholder="SVC PN"
                type="svcpn"
                value={validation.values.svcpn || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label className="form-label">SVCPN Description</Label>
              <div className="input-group">
                <Input
                  disabled
                  name="svcpnDescription"
                  value={validation.values.svcpnDescription || ''}
                  type="svcpnDescription"
                  placeholder="SVCPN Description"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-label">SDF code</Label>
              <Input
                disabled
                name="sdfCode"
                className="form-control"
                placeholder="SDF code"
                type="sdfCode"
                value={validation.values.sdfCode || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label className="form-label">Ship to Country</Label>
              <div className="input-group">
                <Input
                  disabled
                  name="salesOrderItemShippingCountry"
                  value={validation.values.salesOrderItemShippingCountry || ''}
                  type="salesOrderItemShippingCountry"
                  placeholder="Ship to Country"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-label">Sales Order No</Label>
              <Input
                disabled
                name="salesOrderNumber"
                className="form-control"
                placeholder="Sales Order No"
                type="salesOrderNumber"
                value={validation.values.salesOrderNumber || ''}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label className="form-label">Sold to Customer Name</Label>
              <div className="input-group">
                <Input
                  name="customerSoldName"
                  value={validation.values.customerSoldName || ''}
                  type="customerSoldName"
                  placeholder="Sold to Customer Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(
                      validation.touched.customerSoldName &&
                      validation.errors.customerSoldName
                    )
                  }
                />
                {validation.touched.customerSoldName &&
                validation.errors.customerSoldName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.customerSoldName}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="form-label">Shipped to Customer Name</Label>
              <Input
                name="customerShipName"
                className="form-control"
                placeholder="Shipped to Customer Name"
                type="customerShipName"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.customerShipName || ''}
                invalid={
                  !!(
                    validation.touched.customerShipName &&
                    validation.errors.customerShipName
                  )
                }
              />
              {validation.touched.customerShipName &&
              validation.errors.customerShipName ? (
                <FormFeedback type="invalid">
                  {validation.errors.customerShipName}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}></Col>
        </Row>
        <hr />
        {status === 'NEW' || status === 'IN_PROGRESS' ? (
          <Row className="pt-3">
            <Col md={6}>
              <FormGroup>
                <Label className="form-label">Project No</Label>
                <div className="input-group">
                  <CustomSelect
                    inputId="project"
                    name="project"
                    onChange={(value) =>
                      validation.setFieldValue('project', value.value)
                    }
                    onBlur={validation.handleBlur}
                    value={validation.values.project}
                    options={projectOptions(data)}
                    invalid={
                      !!(
                        validation.touched.project && validation.errors.project
                      )
                    }
                  />
                </div>
              </FormGroup>
            </Col>
            {validation.values.project && (
              <Col>
                <FormGroup>
                  <Label className="form-label">Project Name</Label>
                  <Input
                    plaintext
                    value={validation.values.project.title}
                    readOnly
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        ) : (
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="form-label">Project No</Label>
                <Input
                  disabled
                  name="projectCode"
                  className="form-control"
                  placeholder="Project No"
                  type="projectCode"
                  value={validation.values.projectCode || ''}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label className="form-label">Project Name</Label>
                <div className="input-group">
                  <span className="pt-2 text-truncate">{projectTitle}</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          {status === 'IN_PROGRESS' ||
          status === 'READY' ||
          status === 'DONE' ||
          status === 'BILLED' ? (
            <Col md={6}>
              <div className="mb-3">
                <Label className="form-label">Status</Label>
                <div className="input-group">
                  <Input
                    disabled
                    name="status"
                    value={validation.values.status || ''}
                    type="status"
                    placeholder="Status"
                  />
                </div>
              </div>
            </Col>
          ) : (
            <Col md={6}>
              <FormGroup>
                <Label className="form-label">Status</Label>
                <div className="input-group">
                  <CustomSelect
                    onChange={(value) =>
                      validation.setFieldValue('status', value.value)
                    }
                    onBlur={validation.handleBlur}
                    value={validation.values.status}
                    options={statusField}
                    invalid={
                      !!(validation.touched.status && validation.errors.status)
                    }
                  />
                </div>
              </FormGroup>
            </Col>
          )}
          <Col md={6}>
            <div className="mb-3">
              <Label className="form-label">Certificate ID</Label>
              <div className="input-group">
                <span className="pt-2 text-truncate">{certificateId}</span>
              </div>
            </div>
          </Col>
        </Row>

        <BottomRow>
          <Col>
            <SaveButton color="outline-primary" onClick={toggleModal}>
              Close
            </SaveButton>
            <SaveButton type="submit" color="primary">
              Save
            </SaveButton>
          </Col>
        </BottomRow>
      </Form>
    </FormContainer>
  );
};

export default EditOrderForm;

EditOrderForm.propTypes = {
  records: PropTypes.shape({
    id: PropTypes.number.isRequired,
    uid: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    svcpn: PropTypes.string.isRequired,
    svcpnDescription: PropTypes.string.isRequired,
    sdfCode: PropTypes.string.isRequired,
    salesOrderItemShippingCountry: PropTypes.string.isRequired,
    salesOrderNumber: PropTypes.string.isRequired,
    customerSoldName: PropTypes.string.isRequired,
    customerShipName: PropTypes.string.isRequired,
    certificateId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    projectCode: PropTypes.string.isRequired,
    projectTitle: PropTypes.string.isRequired,
  }).isRequired,
  orderedAt: PropTypes.string.isRequired,
  salesOrderItemShipAt: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
