import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'reactstrap';
import { ProductFilter, OutdatedAtFilter } from './Filters';

const SvcpnFilters = ({ filters, availableFilters, makeSetFilter }) => {
  return (
    <>
      <Form>
        <Row className="align-items-end">
          <Col xs={6}>
            <OutdatedAtFilter
              availableFilters={availableFilters}
              filters={filters}
              makeSetFilter={makeSetFilter}
            />
          </Col>
          <Col xs={6}>
            <ProductFilter
              availableFilters={availableFilters}
              filters={filters}
              makeSetFilter={makeSetFilter}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

SvcpnFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  availableFilters: PropTypes.object.isRequired,
  makeSetFilter: PropTypes.func.isRequired,
};

export default SvcpnFilters;
