import React, { useState, useEffect } from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ThreeDotsMenu from '../../components/Common/ThreeDotsMenu';
import { useToggle } from '../../hooks';
import EditRetirement from './EditRetirement';
import ReprocessIndulgences from './ReprocessIndulgences';
import DeleteRetirement from './DeleteRetirement';
import DeleteCompleteRetirement from './DeleteCompleteRetirement';
import CannotDeleteRetirement from './CannotDeleteRetirement';
import { withUser } from '../../context/UserContext';
import RetirementRounding from './RoundingPopUp';
import { retirements } from '../../api';
import { processRecord } from '../../store/records';
import { editRetirement } from '../../store/retirements';

const RetirementActionMenu = ({ row, user }) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, toggleDropdown] = useToggle();
  const [isEditOpen, toggleEditModal] = useToggle();
  const [isReprocessOpen, toggleReprocessModal] = useToggle();
  const [isDeleteOpen, toggleDeleteModal] = useToggle();
  const [isRoundOpen, toggleRoundModal] = useToggle();
  const [isDeleteCompleteOpen, toggleDeleteCompleteModal] = useToggle();
  const [isCannotDeleteOpen, toggleCannotDeleteModal] = useToggle();
  const [billings, setBilling] = useState(null);

  const [formData, setFormData] = useState(null);

  const { indulgenceIds = [], retirementId = null } = useSelector(
    (state) => state.retirements.retirements.indulgencesToReprocess || {},
  );

  useEffect(() => {
    if (retirementId === row.id) {
      if (retirementId && indulgenceIds.length > 0) {
        toggleReprocessModal();
      }
    }
  }, [indulgenceIds, retirementId]);

  const handleReprocess = async (indulgencesIds) => {
    await dispatch(processRecord({ params: { recordIds: indulgencesIds } }));
    await dispatch(
      editRetirement({
        id: row.id,
        params: {
          reference: formData.reference,
          offsetedAt: formData.offsetedAt,
          url: formData.url,
        },
      }),
    );
    toggleReprocessModal();
    toggleEditModal();
  };

  const checkRetirementForBilling = async () => {
    if (row.billingId) {
      const { data } = await retirements.checkBillings({ id: row.id });
      setBilling(data.data.retirement);
      toggleCannotDeleteModal();
    } else {
      toggleDeleteCompleteModal();
    }
  };

  const enabled = (user && user.role === 'ADMIN') || user.role === 'SALES';

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          disabled={!enabled}
          color="link"
          className="shadow-none"
        >
          <ThreeDotsMenu />
        </DropdownToggle>
        <DropdownMenu>
          {row?.billingId ? (
            <DropdownItem>
              <Button color="link" disabled onClick={toggleEditModal}>
                Edit Retirement
              </Button>
            </DropdownItem>
          ) : (
            <DropdownItem>
              <Button color="link" onClick={toggleEditModal}>
                Edit Retirement
              </Button>
            </DropdownItem>
          )}
          <DropdownItem>
            {row?.status === 'COMPLETE' ? (
              <Button color="link" onClick={checkRetirementForBilling}>
                <span>Delete Retirement</span>
              </Button>
            ) : (
              <Button color="link" onClick={toggleDeleteModal}>
                <span>Delete Retirement</span>
              </Button>
            )}
          </DropdownItem>
          <DropdownItem>
            {!row?.isRounded &&
            !Number.isInteger(row.retirement) &&
            row?.status !== 'COMPLETE' ? (
              <Button color="link" onClick={toggleRoundModal}>
                Round up
              </Button>
            ) : (
              <Button color="link" disabled>
                Round up
              </Button>
            )}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <EditRetirement
        isOpen={isEditOpen}
        toggleModal={toggleEditModal}
        onClick={toggleDropdown}
        retirement={row}
        onSubmit={(data) => setFormData(data)}
      />
      <ReprocessIndulgences
        isOpen={isReprocessOpen}
        toggleModal={toggleReprocessModal}
        indulgencesToReprocess={indulgenceIds}
        handleReprocess={handleReprocess}
      />
      <DeleteRetirement
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        onClick={toggleDropdown}
        retirement={row}
      />
      <DeleteCompleteRetirement
        isOpen={isDeleteCompleteOpen}
        toggleModal={toggleDeleteCompleteModal}
        onClick={toggleDropdown}
        retirement={row}
      />

      {row.billingId ? (
        <CannotDeleteRetirement
          isOpen={isCannotDeleteOpen}
          toggleModal={toggleCannotDeleteModal}
          onClick={toggleDropdown}
          retirement={row}
          billings={billings}
        />
      ) : null}
      <RetirementRounding
        isOpen={isRoundOpen}
        toggleModal={toggleRoundModal}
        onClick={toggleDropdown}
        retirement={row}
      />
    </div>
  );
};

RetirementActionMenu.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withUser(RetirementActionMenu);
