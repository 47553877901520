export function format(number) {
  const parsedNumber = parseFloat(number);
  return parsedNumber % 1 === 0
    ? parsedNumber.toLocaleString('en-US')
    : parsedNumber.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
}

export function formatDateForExport(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  const ss = String(date.getSeconds()).padStart(2, '0');

  return `${yyyy}-${mm}-${dd}_${hh}.${min}.${ss}`;
}
