import React from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DeleteBillingSummary from './DeleteBillingSummary';
import ThreeDotsMenu from '../../components/Common/ThreeDotsMenu';
import { useToggle } from '../../hooks';
import { withUser } from '../../context/UserContext';
import EditBillingSummaryModal from './EditBillingSummaryModal';
import SendDateModal from './SendDateModal';
import { downloadBillingSummary } from '../../store/billings';
import UnmarkAsSentModal from './UnmarkAsSentModal';

const ActionMenu = ({ row, user }) => {
  const [isDropdownOpen, toggleDropdown] = useToggle();
  const [isEditOpen, toggleEditModal] = useToggle();
  const [isDeleteOpen, toggleDeleteModal] = useToggle();
  const [isSendDateOpen, toggleSendDateModal] = useToggle();
  const [isUnmarkAsSentOpen, toggleUnmarkAsSentModal] = useToggle();
  const dispatch = useDispatch();
  const handleBilledSummaryReport = () => {
    const { id } = row;
    dispatch(downloadBillingSummary({ id }));
  };

  const enabled = (user && user.role === 'ADMIN') || user.role === 'SALES';

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          disabled={!enabled}
          color="link"
          className="shadow-none"
        >
          <ThreeDotsMenu />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <Button color="link" onClick={handleBilledSummaryReport}>
              Billing Summary Report
            </Button>
          </DropdownItem>
          <DropdownItem>
            <Button color="link" onClick={toggleEditModal}>
              Edit Billing Summary
            </Button>
          </DropdownItem>
          {(user && user.role === 'ADMIN') || user.role === 'SALES' ? (
            <DropdownItem>
              <Button
                color="link"
                onClick={
                  row.status === 'UNSENT'
                    ? toggleSendDateModal
                    : toggleUnmarkAsSentModal
                }
              >
                {row.status === 'UNSENT' ? 'Mark as Sent' : 'Unmark as Sent'}
              </Button>
            </DropdownItem>
          ) : (
            ''
          )}
          <DropdownItem>
            <Button color="link" onClick={toggleDeleteModal}>
              <span>Delete Billing Summary</span>
            </Button>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <EditBillingSummaryModal
        row={row}
        isOpen={isEditOpen}
        toggleModal={toggleEditModal}
        onClick={toggleDropdown}
      />
      <SendDateModal
        row={row}
        isOpen={isSendDateOpen}
        isEditOpen={isEditOpen}
        toggleModal={toggleSendDateModal}
        toggleEditModal={toggleEditModal}
      />
      <UnmarkAsSentModal
        row={row}
        isOpen={isUnmarkAsSentOpen}
        toggleModal={toggleUnmarkAsSentModal}
      />
      <DeleteBillingSummary
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        onClick={toggleDropdown}
        row={row}
      />
    </div>
  );
};

ActionMenu.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withUser(ActionMenu);
