import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from './query';
import { getTransmissionLogs } from '../store/transmissionLogs';

export const useTransmissionLogs = (
  initialParams = {
    page: 1,
    pageSize: 50,
    sort: 'createdAt',
    order: 'desc',
  },
) => {
  const [params, updateParams] = useQuery(initialParams);
  const dispatch = useDispatch();
  const count = useSelector(
    (state) => state.transmissionLogs.transmissionLogs.count,
  );
  const transmissionLogsData = useSelector(
    (state) => state.transmissionLogs.transmissionLogs.results,
  );
  const isTransmissionLogsLoading = useSelector(
    (state) => state.transmissionLogs.isLoading,
  );

  useEffect(() => {
    dispatch(getTransmissionLogs(params));
  }, [params]);

  return {
    transmissionLogsData,
    isTransmissionLogsLoading,
    count,
    sortableColumns:
      useSelector(
        (state) =>
          state.transmissionLogs.transmissionLogs.params.sortableColumns,
      ) || [],
    updateParams,
    params,
  };
};
