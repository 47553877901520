import React from 'react';
import {
  Row,
  Col,
  CardTitle,
  Container,
  Card,
  CardBody,
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import cx from 'classnames';
import RetirementsTable from '../../components/Tables/RetirementsTable';
import { useRetirements } from '../../hooks';
import RetirementActionMenu from './RetirementActionMenu';
import PageTitle from '../../components/Common/PageTitle';
import Export from './Export';
import { withUser } from '../../context/UserContext';
import { format } from '../../helpers/formatter';

const statusFormatter = (cell) => {
  if (cell === 'IN_PROGRESS') {
    return (
      <span
        style={{
          color: '#F1B44C',
          background: '#FBECD2',
          borderRadius: '10px',
          textAlign: 'center',
          padding: '0px 9px',
        }}
      >
        In Process
      </span>
    );
  }
  return (
    <span
      style={{
        color: '#34C38F',
        background: '#CCF0E3',
        borderRadius: '10px',
        textAlign: 'center',
        padding: '0px 10px',
      }}
    >
      Complete
    </span>
  );
};

const noteFormatter = (cell, row) => {
  if (!cell) {
    return '';
  }
  const id = `note-tooltip-${row.id}`;
  return (
    <>
      <span
        id={id}
        style={{
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {cell}
      </span>
      <UncontrolledTooltip placement="left" target={id}>
        {cell}
      </UncontrolledTooltip>
    </>
  );
};

const RoundFormatter = (cell, row) => {
  if (row.isRounded) {
    return (
      <span
        style={{
          color: '#556EE6',
          fontWeight: '600',
        }}
      >
        {format(Math.round(cell))}
      </span>
    );
  }
  return format(cell);
};

const pdfLinkIconFormater = (cell, row) => {
  if (cell !== null && cell !== '') {
    return (
      <div>
        <a
          title={'Click to follow the link'}
          href={row.url}
          target="_blank"
          rel="noreferrer"
        >
          <i
            style={{ color: '#32CD32' }}
            className={cx('bx', 'bx bxs-file-pdf', 'bx-md')}
          />
        </a>
      </div>
    );
  }
  return (
    <i
      style={{ color: '#C0C0C0' }}
      className={cx('bx', 'bx bxs-file-pdf', 'bx-md')}
    />
  );
};

function dateFormatter(cell) {
  if (!cell) {
    return '';
  }

  return moment.utc(cell).format('YYYY.MM.DD');
}
function offSetDateFormatter(cell) {
  if (!cell) {
    return '';
  }

  return moment.utc(cell).format('YYYY.MM.DD');
}
const columns = [
  {
    dataField: 'assignedAt',
    text: 'Assign Date',
    formatter: dateFormatter,
  },
  {
    dataField: 'projectCode',
    text: 'Project No',
  },
  {
    dataField: 'retirement',
    text: 'Retirement',
    formatter: RoundFormatter,
  },
  {
    dataField: 'offsetedAt',
    text: 'Offset Date',
    formatter: offSetDateFormatter,
  },
  {
    dataField: 'reference',
    text: 'Reference No',
    headerStyle: () => {
      return { width: '5%' };
    },
  },
  {
    dataField: 'note',
    text: 'Note',
    formatter: noteFormatter,
    style: {
      maxWidth: '200px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  {
    dataField: 'url',
    text: 'Document',
    formatter: pdfLinkIconFormater,
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: statusFormatter,
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: (cell, row) => <RetirementActionMenu row={row} />,
  },
];

const Reirements = () => {
  const { retirementsData, params, count, updateParams, isRetirementLoading } =
    useRetirements();

  const onTableChange = (type, newState) => {
    if (type === 'pagination') {
      updateParams({
        ...params,
        page: newState.page,
        pageSize: newState.sizePerPage,
      });
    }
  };

  return (
    <div className="page-content">
      <PageTitle title="Retirements" />
      <Container fluid>
        <Row>
          <div className="mb-2">
            <div className="d-flex justify-content-between ">
              <h3>Retirements</h3>
              <span>
                <Export params={params} />
              </span>
            </div>
          </div>
          <Col xl={12}>
            <Card>
              <CardBody>
                <div>
                  <CardTitle tag="span" className="h4">
                    Retirement Requests Summary
                  </CardTitle>
                </div>
                <RetirementsTable
                  columns={columns}
                  isLoading={isRetirementLoading}
                  data={retirementsData || []}
                  page={params.page}
                  sizePerPage={params.pageSize}
                  totalSize={count}
                  onTableChange={onTableChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Reirements.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withUser(Reirements);
