import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Multiselect from 'multiselect-react-dropdown';
import cx from 'classnames';
import arrow from '../../../assets/images/arrow.svg';

const makeMultiSelectorFilter = ({ column, placeholder }) => {
  const multiSelectorFilter = ({
    filters,
    availableFilters,
    makeSetFilter,
  }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [options, setOptions] = useState([]);

    const setValue = useCallback(makeSetFilter(column), [makeSetFilter]);

    useEffect(() => {
      if (!filters[column]?.value) {
        setSelectedValues([]);
        return;
      }

      const preselected = filters[column]?.value;
      setSelectedValues(
        preselected.map((val) => ({
          value: val,
          label:
            availableFilters[column]?.options?.find((opt) => opt.value === val)
              ?.label || val,
        })),
      );
    }, [filters, column, availableFilters]);

    useEffect(() => {
      const allOptions = availableFilters[column]?.options || [];
      const selectedValuesSet = new Set(selectedValues.map((opt) => opt.value));

      const updatedOptions = [
        ...allOptions.filter((option) => selectedValuesSet.has(option.value)),
        ...allOptions.filter((option) => !selectedValuesSet.has(option.value)),
      ];
      setOptions(updatedOptions);
    }, [availableFilters, selectedValues]);

    const handleUpdate = (selectedList) => {
      const updatedValues = selectedList.map((option) => option.value);
      setValue(updatedValues);
    };

    const dynamicPlaceholder =
      selectedValues.length > 0
        ? `${placeholder}: ${selectedValues.length}`
        : placeholder;

    const inputClass = cx({
      'filled-input': selectedValues.length > 0,
    });

    return (
      <>
        <div className={inputClass} style={{ borderRadius: '0.25rem' }}>
          <Multiselect
            options={options}
            selectedValues={selectedValues}
            placeholder={dynamicPlaceholder}
            displayValue="value"
            onSelect={handleUpdate}
            onRemove={handleUpdate}
            id={`${column}-multiselect-id`}
            showCheckbox={true}
            showArrow={true}
            customArrow={<img src={arrow} style={{ width: '12px' }} />}
            style={{
              inputField: {
                margin: '0 6px 0 6px',
                borderRadius: '0.25rem',
              },
              chips: {
                display: 'none',
              },
              searchBox: {
                padding: '7px',
              },
            }}
          />
        </div>
      </>
    );
  };

  multiSelectorFilter.propTypes = {
    filters: PropTypes.object.isRequired,
    availableFilters: PropTypes.object.isRequired,
    makeSetFilter: PropTypes.func.isRequired,
  };

  return multiSelectorFilter;
};

export default makeMultiSelectorFilter;
