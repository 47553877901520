import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useUsers } from '../hooks';
import UserContext from '../context/UserContext';

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  // eslint-disable-next-line no-unused-vars
  const { currentUser } = useUsers();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !currentUser) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        return (
          <UserContext.Provider value={currentUser}>
            <Layout>
              <Component {...props} />
            </Layout>
          </UserContext.Provider>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
