/* LAYOUT */
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH';
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME';
export const CHANGE_SIDEBAR_THEME_IMAGE = 'CHANGE_SIDEBAR_THEME_IMAGE';
export const CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE';

// topbar
export const CHANGE_TOPBAR_THEME = 'CHANGE_TOPBAR_THEME';

// show sidebar
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
