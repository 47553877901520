import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Form,
  Input,
  FormFeedback,
  Label,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { verifyOTPCode, clearUserError } from '../../store/users';

const OTPAuthenticator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error } = useSelector((state) => ({
    error: state.users.error,
  }));
  const { currentUser } = useSelector((state) => ({
    currentUser: state.users.currentUser,
  }));
  const { rememberMe } = useSelector((state) => ({
    rememberMe: state.users.rememberMe,
  }));

  useEffect(() => {
    dispatch(clearUserError());
  }, []);

  useEffect(() => {
    if (!currentUser) {
      history.push('/login');
    }
  }, [currentUser, history]);

  const validation = useFormik({
    initialValues: { otpCode: '' },
    validationSchema: Yup.object({
      otpCode: Yup.string()
        .required('Please enter the verification code')
        .matches(/^\d{6}$/, 'Code must be a 6-digit number'),
    }),
    onSubmit: async (values) => {
      await dispatch(
        verifyOTPCode({
          params: {
            code: values.otpCode,
            email: currentUser.email,
            rememberMe,
          },
          history,
        }),
      );
    },
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card
              className="overflow-hidden p-4"
              style={{
                minHeight: '500px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardBody className="d-flex flex-column justify-content-between">
                <h4 className="text-left mb-4">Authenticator App</h4>
                <p className="text-muted text-left">
                  Enter the 6-Digit code from your authenticator app. Please
                  wait for a new code if you’ve just registered your
                  authenticator app.
                </p>
                <Form
                  onSubmit={validation.handleSubmit}
                  className="d-flex flex-column justify-content-between"
                  style={{ flex: 1 }}
                >
                  <div>
                    <div className="mb-3">
                      <Label>Verification Code</Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-lock-outline" />
                        </span>
                        <Input
                          name="otpCode"
                          type="text"
                          placeholder="6-Digit Code"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.otpCode}
                          invalid={
                            !!(
                              validation.touched.otpCode &&
                              validation.errors.otpCode
                            ) || !!error
                          }
                          className="form-control text-left"
                        />
                        {validation.errors.otpCode &&
                          validation.touched.otpCode && (
                            <FormFeedback type="invalid">
                              {validation.errors.otpCode}
                            </FormFeedback>
                          )}
                        {error && !validation.errors.otpCode && (
                          <FormFeedback type="invalid">{error}</FormFeedback>
                        )}
                      </div>
                    </div>
                    {!currentUser?.factorSid && (
                      <div className="text-left mb-4">
                        <Link to="/2fa-login/authenticator/register">
                          Register New Authenticator App
                        </Link>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="d-grid">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={validation.values.otpCode.length < 1}
                      >
                        Verify
                      </Button>
                    </div>
                    <div className="mt-2 text-center">
                      <Button
                        color="link"
                        onClick={() => history.push('/2fa-login')}
                      >
                        &lt; Back
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OTPAuthenticator;
