import urls from './urls';
import client from '../utils/api';

const addProject = (body) => client.post(urls.projects.addProject(), body);
const getProjects = (query = {}) =>
  client.get(urls.projects.getProjects(), query);
const editProject = ({ id, params }) =>
  client.put(urls.projects.editProject(id), params);
const deleteProject = ({ id }) =>
  client.del(urls.projects.deleteProject(id), { id });
const exportCsv = (query = {}) => client.get(urls.projects.exportCsv(), query);
const getAllProjects = (query = {}) =>
  client.get(urls.projects.getAllProjects(), query);
const findRelatedRetirement = (query = {}) =>
  client.get(urls.projects.findRelatedRetirement(), query);

export default {
  addProject,
  getAllProjects,
  getProjects,
  editProject,
  deleteProject,
  exportCsv,
  findRelatedRetirement,
};
