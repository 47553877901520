import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import { useProjects } from '../../../hooks';
import { DatePickerField } from '../../../components/Common/DatePicker';
import { CustomSelect } from '../CustomSelect';
import { assignAllToProject } from '../../../store/records';
import Loader from '../../../components/Common/Loader';
import ButtonLoader from '../../../components/Common/ButtonLoader';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  display: grid;
  padding: 0 28px;
`;

const SaveButton = styled(Button)`
  width: 113px;
  height: 36px;
  padding: 0px;
  font-size: 18px;
  border-radius: 8px;
`;

const projectOptions = (projects) =>
  projects.map((project) => ({
    value: project,
    label: project.code,
  }));

const AssignAllToProjectForm = ({ params: queryParams, totalOffsetOfNew }) => {
  const { isLoading, data } = useProjects();

  const dispatch = useDispatch();
  const { isLoading: isLoadingRequest, error } = useSelector((state) => ({
    error: state.records.error,
    isLoading: state.records.isLoading,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      project: null,
      assignedAt: moment(new Date()).format('YYYY.MM.DD'),
      offset: '',
    },
    validationSchema: Yup.object({
      project: Yup.object().required('Please select the project'),
      offset: Yup.number()
        .required('Please enter an offset')
        .max(
          totalOffsetOfNew,
          'Cannot be more than total remaining carbon or remaining value of assigned project',
        )
        .when('project', (project) => {
          if (project) {
            return Yup.number()
              .required('Please enter an offset')
              .max(
                Math.min(
                  totalOffsetOfNew,
                  (validation.values.project &&
                    validation.values.project.remaning) ||
                    0,
                ),
                'Cannot be more than total remaining carbon or remaining value of assigned project',
              )
              .typeError('You must specify a number');
          }
        })
        .typeError('You must specify a number'),
      assignedAt: Yup.date().nullable().required('Assign Date is required'),
    }),
    onSubmit: (formParams) => {
      const { assignedAt, offset, project } = formParams;
      const { id } = project;
      dispatch(
        assignAllToProject({
          params: {
            projectId: id,
            assignedAt: moment.utc(assignedAt, 'YYYY.MM.DD').format(),
            offset,
          },
          queryParams,
        }),
      );
    },
  });

  if (isLoading || !data)
    return (
      <FormContainer>
        <Loader isShown />
      </FormContainer>
    );

  return (
    <FormikProvider value={validation}>
      <FormContainer>
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          {error ? <Alert color="danger">{error}</Alert> : null}
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">
                  Project No
                  <Asterix>*</Asterix>
                </Label>
                <div className="input-group">
                  <CustomSelect
                    inputId="project"
                    name="project"
                    onChange={(value) =>
                      validation.setFieldValue('project', value.value)
                    }
                    onBlur={validation.handleBlur}
                    value={validation.values.project}
                    options={projectOptions(data)}
                    invalid={
                      !!(
                        validation.touched.project && validation.errors.project
                      )
                    }
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          {validation.values.project && (
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-label">Project Name</Label>
                  <Input
                    plaintext
                    value={validation.values.project.title}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">
                  Assign Date
                  <Asterix>*</Asterix>
                </Label>
                <DatePickerField
                  onChange={validation.handleChange}
                  name="assignedAt"
                  placeholderText="Assign Date"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">
                  Offset Carbon to the Project
                  <Asterix>*</Asterix>
                </Label>
                <Input
                  name="offset"
                  className="form-control"
                  placeholder="Offset"
                  type="offset"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.offset || ''}
                  invalid={
                    !!(validation.touched.offset && validation.errors.offset)
                  }
                />
                {validation.touched.offset && validation.errors.offset ? (
                  <FormFeedback type="invalid">
                    {validation.errors.offset}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <BottomRow>
            <Col>
              <SaveButton
                disabled={isLoadingRequest}
                type="submit"
                color="primary"
              >
                {isLoadingRequest ? <ButtonLoader isShown /> : 'Save'}
              </SaveButton>
            </Col>
          </BottomRow>
        </Form>
      </FormContainer>
    </FormikProvider>
  );
};

export default AssignAllToProjectForm;

AssignAllToProjectForm.propTypes = {
  params: PropTypes.object,
  totalOffsetOfNew: PropTypes.number,
};
