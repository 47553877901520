import { takeEvery, call, put } from 'redux-saga/effects';
import {
  getSvcpns,
  addSvcpn,
  editSvcpn,
  toggleAddSvcpnModal,
  clearEditSvcpnError,
  getUnknownSvcpns,
  exportCsv,
} from './actions';
import {
  GET_SVCPNS,
  GET_UNKNOWN_SVCPNS,
  ADD_SVCPN,
  EDIT_SVCPN,
  EXPORT_SVCPN_CSV,
} from './actionTypes';
import { asyncSaga } from '../common';
import { svcpns } from '../../api';
import { formatDateForExport } from '../../helpers/formatter';

function* addSvcpnSaga({ payload: { params } }) {
  try {
    const response = yield call(svcpns.addSvcpn, params);
    const { svcpn } = response.data.data;
    yield put(addSvcpn.success({ svcpn }));
    yield put(toggleAddSvcpnModal.success({ isOpen: true }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    switch (error.response?.status) {
      case 422:
        return yield put(editSvcpn.fail('Field must be positive number'));
      case 409:
        return yield put(
          addSvcpn.fail('This SVCPN number is already registered!'),
        );
      default:
        return yield put(addSvcpn.fail('Something went wrong'));
    }
  }
}

function* editSvcpnSaga({ payload: { id, params } }) {
  try {
    const response = yield call(svcpns.editSvcpn, { id, params });
    const { svcpn } = response.data.data;
    yield put(editSvcpn.success({ svcpn }));
    yield put(clearEditSvcpnError.success());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 409:
        return yield put(
          addSvcpn.fail('This SVCPN number is already registered!'),
        );
      case 422:
        return yield put(editSvcpn.fail('Invalid request params'));
      default:
        return yield put(editSvcpn.fail('Something went wrong'));
    }
  }
}

function* exportCsvSaga({ payload }) {
  try {
    const response = yield call(svcpns.exportCsv, payload);
    const csvBlob = new Blob([response.data], { type: 'text/csv' });
    saveAs(csvBlob, `svcpns_${formatDateForExport(new Date())}.csv`);
    yield put(exportCsv.success(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(exportCsv.fail(error.message));
      default:
        return yield put(exportCsv.fail('Something went wrong'));
    }
  }
}

export default function* svcpnsSaga() {
  // yield takeEvery(GET_SVCPNS.RUN, getSvcpnsSaga);
  yield takeEvery(
    GET_SVCPNS.RUN,
    asyncSaga((action) => svcpns.getSvcpns(action), getSvcpns),
  );
  yield takeEvery(
    GET_UNKNOWN_SVCPNS.RUN,
    asyncSaga((action) => svcpns.getUnknownSvcpns(action), getUnknownSvcpns),
  );
  yield takeEvery(ADD_SVCPN.RUN, addSvcpnSaga);
  yield takeEvery(EDIT_SVCPN.RUN, editSvcpnSaga);
  yield takeEvery(EXPORT_SVCPN_CSV.RUN, exportCsvSaga);
}
