import * as actions from './actions';

export const loginUser = actions.loginUser.run;
export const sendSMSCode = actions.sendSMSCode.run;
export const verifySMSCode = actions.verifySMSCode.run;
export const registerOTPApp = actions.registerOTPApp.run;
export const verifyOTPApp = actions.verifyOTPApp.run;
export const verifyOTPCode = actions.verifyOTPCode.run;
export const storeRememberMe = actions.storeRememberMe.run;
export const logOutUser = actions.logOutUser.run;
export const getUsers = actions.getUsers.run;
export const createUser = actions.createUser.run;
export const deleteUser = actions.deleteUser.run;
export const updateUser = actions.updateUser.run;
export const updateUserPassword = actions.updateUserPassword.run;
export const updatePassword = actions.updatePassword.run;
export const toggleUserModal = actions.toggleUserModal.run;
export const sendPasswordResetEmail = actions.sendPasswordResetEmail.run;
export const checkToken = actions.checkToken.run;
export const clearUserError = actions.clearUserError.run;
export const resetSecondFactor = actions.resetSecondFactor.run;
