import urls from './urls';
import client from '../utils/api';

const getEmailRecipients = (query = {}) =>
  client.get(urls.emailRecipients.getEmailRecipients(), query);

const createEmailRecipient = (email, type) => {
  return client.post(urls.emailRecipients.createEmailRecipient(), {
    email,
    type,
  });
};

const deleteEmailRecipient = (id) => {
  return client.del(urls.emailRecipients.deleteEmailRecipient(id));
};

const updateEmailRecipient = (id, email) => {
  return client.put(urls.emailRecipients.updateEmailRecipient(id), { email });
};

export default {
  getEmailRecipients,
  createEmailRecipient,
  deleteEmailRecipient,
  updateEmailRecipient,
};
