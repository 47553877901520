import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import { DatePickerField } from '../../components/Common/DatePicker';
import {
  editRetirement,
  checkRetirement,
  countSameReference,
} from '../../store/retirements';
import { format } from '../../helpers/formatter';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  display: grid;
`;

const SaveButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const EditRetirementForm = ({ retirement, onSubmit }) => {
  const { id, reference, offsetedAt, url, note } = retirement;
  const dispatch = useDispatch();
  const { indulgenceIds, retirementId } = useSelector(
    (state) => state.retirements.retirements.indulgencesToReprocess || {},
  );
  const { error, sameReferenceCount } = useSelector((state) => ({
    error: state.retirements.error,
    sameReferenceCount: state.retirements.retirements.sameReferenceCount,
  }));

  const formatOffsetedAt = (date) => moment(date, 'YYYY.MM.DD').format();

  const onSubmitHandler = (params) => {
    dispatch(
      checkRetirement({
        id,
        params: {
          offsetedAt: formatOffsetedAt(params.offsetedAt),
        },
      }),
    );
    onSubmit(params);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      reference: reference || '',
      offsetedAt: (offsetedAt && moment(offsetedAt).format('YYYY.MM.DD')) || '',
      url: url || '',
      note: note || '',
    },
    validationSchema: Yup.object({
      reference: Yup.string()
        .required('Please enter Reference No')
        .matches(
          /^[^\s].+[^\s]*[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
          'Field must contain letters or numbers',
        )
        .min(4, 'Minimum must contain 4 characters')
        .max(250, 'Maximum must contain 250 characters'),
      offsetedAt: Yup.date().nullable().required('Offset Date is required'),
      url: Yup.string().max(250).nullable().url('URL is not valid'),
      note: Yup.string().max(1000, 'Note cannot exceed 1000 characters'),
    }),
    onSubmit: onSubmitHandler,
  });

  useEffect(() => {
    if (retirementId === id && indulgenceIds?.length === 0) {
      dispatch(
        editRetirement({
          id,
          params: {
            reference: validation.values.reference,
            offsetedAt: formatOffsetedAt(validation.values.offsetedAt),
            url: validation.values.url,
            note: validation.values.note,
          },
        }),
      );
    }
  }, [retirementId]);

  const handleReferenceChange = () => {
    dispatch(countSameReference(validation.values.reference));
  };

  return (
    <FormikProvider value={validation}>
      <FormContainer>
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          {error ? <Alert color="danger">{error}</Alert> : null}
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">
                  Reference No
                  <Asterix>*</Asterix>
                </Label>
                <Input
                  name="reference"
                  className="form-control"
                  placeholder="Reference No"
                  type="reference"
                  onChange={validation.handleChange}
                  onBlur={(e) => {
                    validation.handleBlur(e);
                    handleReferenceChange();
                  }}
                  value={validation.values.reference || ''}
                  invalid={
                    !!(
                      validation.touched.reference &&
                      validation.errors.reference
                    )
                  }
                />
                {validation.touched.reference && validation.errors.reference ? (
                  <FormFeedback type="invalid">
                    {validation.errors.reference}
                  </FormFeedback>
                ) : null}
                {!!sameReferenceCount && (
                  <p style={{ color: 'gray', fontSize: '12px' }}>
                    This Reference Number is used on{' '}
                    {format(sameReferenceCount)} other Retirement Request(s)
                  </p>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">
                  Offset Date
                  <Asterix>*</Asterix>
                </Label>
                <DatePickerField
                  onChange={validation.handleChange}
                  name="offsetedAt"
                  placeholderText="Offset Date"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">URL Link</Label>
                <div className="input-group">
                  <Input
                    name="url"
                    value={validation.values.url || ''}
                    type="url"
                    placeholder="URL link to document"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      !!(validation.touched.url && validation.errors.url)
                    }
                  />
                  {validation.touched.url && validation.errors.url ? (
                    <FormFeedback type="invalid">
                      {validation.errors.url}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">Note</Label>
                <Input
                  type="textarea"
                  name="note"
                  className="form-control"
                  placeholder="Provide additional information for the Retirement Request"
                  maxLength="1000"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.note || ''}
                  invalid={
                    !!(validation.touched.note && validation.errors.note)
                  }
                />
                {validation.touched.note && validation.errors.note ? (
                  <FormFeedback type="invalid">
                    {validation.errors.note}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>

          <BottomRow>
            <Col>
              <SaveButton type="submit" color="primary">
                Save
              </SaveButton>
            </Col>
          </BottomRow>
        </Form>
      </FormContainer>
    </FormikProvider>
  );
};

export default EditRetirementForm;

EditRetirementForm.propTypes = {
  retirement: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
