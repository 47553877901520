import { asyncAction } from '../common';
import {
  GET_RETIREMENTS,
  EDIT_RETIREMENT,
  CHECK_RETIREMENT,
  DELETE_RETIREMENT,
  CLEAR_RETIREMENT_ERROR,
  CLEAR_INDULGENCES_TO_REPROCESS,
  EXPORT_RETIREMENT_CSV,
  ROUND_UP_RETIREMENT,
  COUNT_SAME_REFERENCE,
  CLEAR_SAME_REFERENCE_COUNT,
} from './actionTypes';

export const getRetirements = asyncAction(GET_RETIREMENTS);
export const editRetirement = asyncAction(EDIT_RETIREMENT);
export const checkRetirement = asyncAction(CHECK_RETIREMENT);
export const deleteRetirement = asyncAction(DELETE_RETIREMENT);
export const clearRetirementError = asyncAction(CLEAR_RETIREMENT_ERROR);
export const clearindulgencesToReprocess = asyncAction(
  CLEAR_INDULGENCES_TO_REPROCESS,
);
export const exportCsv = asyncAction(EXPORT_RETIREMENT_CSV);
export const RoundUp = asyncAction(ROUND_UP_RETIREMENT);
export const countSameReference = asyncAction(COUNT_SAME_REFERENCE);
export const clearSameReferenceCount = asyncAction(CLEAR_SAME_REFERENCE_COUNT);
