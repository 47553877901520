import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  CardTitle,
  Alert,
} from 'reactstrap';

// redux
import { useDispatch, useSelector } from 'react-redux';

import { Link, useHistory, withRouter } from 'react-router-dom';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// action
import { sendPasswordResetEmail } from '../../store/users';

const ForgetPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
    }),
    onSubmit: (values) => {
      dispatch(sendPasswordResetEmail({ email: values.email, history }));
    },
  });

  const { error, isLoading } = useSelector((state) => ({
    error: state.users.error,
    isLoading: state.users.isLoading,
  }));
  return (
    <React.Fragment>
      <MetaTags>
        <title>Reset password</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-2">
                    {error ? (
                      <Alert color="danger">
                        {error === 'Invalid request params'
                          ? 'Invalid email'
                          : error}
                      </Alert>
                    ) : null}
                    <CardTitle>Reset Password</CardTitle>
                    <p>
                      Enter your Email and instructions will be sent to you!
                    </p>

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            !!(
                              validation.touched.email &&
                              validation.errors.email
                            )
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <div>
                          <button
                            disabled={isLoading}
                            className="btn btn-primary btn-block w-100"
                            type="submit"
                          >
                            Reset password
                          </button>
                          <Link to="/">
                            <button className="btn btn-block w-100 mt-1">
                              Back to Log In
                            </button>
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
