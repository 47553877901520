import urls from './urls';
import client from '../utils/api';

const getRecords = (query = {}) => client.get(urls.records.getRecords(), query);
const getFilters = (query = {}) => client.get(urls.records.getFilters(), query);
const assignToProject = ({ id, params, queryParams = {} }) =>
  client.post(urls.records.assignToProject(id), params, queryParams);
const processRecord = ({ params, queryParams = {} }) =>
  client.post(urls.records.processRecord(), params, queryParams);
const moveRecordsToBilled = (body) =>
  client.post(urls.records.moveRecordsToBilled(), body);
const assignAllToProject = ({ params, queryParams = {} }) =>
  client.post(urls.records.assignAllToProject(), params, queryParams);
const exportCsv = (query = {}) => client.get(urls.records.exportCsv(), query);
const bulkAction = (query = {}) =>
  client.post(urls.records.bulkAction(), query);
const editRecord = ({ id, params }) =>
  client.put(urls.records.editRecord(id), params);
const checkStatus = async (params) => {
  const { data } = await client.post(urls.records.checkStatus(), params);
  return data;
};

export default {
  getRecords,
  getFilters,
  assignToProject,
  assignAllToProject,
  editRecord,
  exportCsv,
  processRecord,
  moveRecordsToBilled,
  checkStatus,
  bulkAction,
};
