import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from '../../components/Common/CloseIcon';
import EditSvcpnForm from './EditSvcpnForm';
import { clearEditSvcpnError } from '../../store/svcpns/index';
import { withUser } from '../../context/UserContext';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const EditUnknownSvcpns = ({ row, isOpen, toggleModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearEditSvcpnError());
  }, [isOpen]);

  return (
    <>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>Edit SVCPN</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <EditSvcpnForm svcpn={row} toggleModal={toggleModal} />
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default withUser(EditUnknownSvcpns);

EditUnknownSvcpns.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
