import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { exportCsv } from '../../store/retirements';
import { withUser } from '../../context/UserContext';
import exportIcon from '../../assets/images/export.svg';

const Export = ({ params }) => {
  const dispatch = useDispatch();
  const handleExportCsv = () => {
    dispatch(exportCsv(params));
  };

  return (
    <Button className="white-btn mr-2" onClick={handleExportCsv}>
      <img src={exportIcon} alt="export" />
      <span>Export CSV</span>
    </Button>
  );
};

Export.propTypes = {
  params: PropTypes.object.isRequired,
};

export default React.memo(withUser(Export));
