import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logOutUser } from '../../store/users';

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logOutHandler = () => {
    dispatch(logOutUser({ history }));
  };

  return (
    <>
      <h5 type="button" onClick={logOutHandler}>
        Log Out
      </h5>
    </>
  );
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;
