import * as actions from './actions';

export const getRetirements = actions.getRetirements.run;
export const editRetirement = actions.editRetirement.run;
export const checkRetirement = actions.checkRetirement.run;
export const deleteRetirement = actions.deleteRetirement.run;
export const clearRetirementError = actions.clearRetirementError.run;
export const clearindulgencesToReprocess =
  actions.clearindulgencesToReprocess.run;
export const exportCsv = actions.exportCsv.run;
export const RoundUp = actions.RoundUp.run;
export const countSameReference = actions.countSameReference.run;
export const clearSameReferenceCount = actions.clearSameReferenceCount.run;
