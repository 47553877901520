import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useUsers } from '../../hooks';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const SaveButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const ChangePasswordForm = ({ toggleModal, user: { id, email } }) => {
  const { editUserPassword } = useUsers();

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const togglePasswordShown = () => setIsPasswordShown(!isPasswordShown);

  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
  const toggleConfirmPasswordShown = () =>
    setIsConfirmPasswordShown(!isConfirmPasswordShown);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Please Enter User Password')
        .matches(
          /^(?=.*\d)(?=.*[!@#$%^&*?_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
      confirmPassword: Yup.string()
        .required('Please Confirm User Password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: (params) => {
      editUserPassword({ params, id });
      toggleModal();
    },
  });

  const { error } = useSelector((state) => ({
    error: state.users.error,
  }));

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {error ? <Alert color="danger">{error.toString()}</Alert> : null}
      <Row className="overflow-hidden">
        <Col>
          <FormGroup>
            <Label className="form-label">Email: {email}</Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">
              Password
              <Asterix>*</Asterix>
            </Label>
            <div className="input-group">
              <Input
                name="password"
                value={validation.values.password || ''}
                type={isPasswordShown ? 'text' : 'password'}
                placeholder="Enter Password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  !!(validation.touched.password && validation.errors.password)
                }
              />
              <button
                onClick={togglePasswordShown}
                className="btn btn-light"
                type="button"
              >
                <i
                  className={
                    !isPasswordShown
                      ? 'mdi mdi-eye-outline'
                      : 'mdi mdi-eye-off-outline'
                  }
                ></i>
              </button>
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">
              Confirm Password
              <Asterix>*</Asterix>
            </Label>
            <div className="input-group">
              <Input
                name="confirmPassword"
                value={validation.values.confirmPassword || ''}
                type={isConfirmPasswordShown ? 'text' : 'password'}
                placeholder="Confirm Password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  !!(
                    validation.touched.confirmPassword &&
                    validation.errors.confirmPassword
                  )
                }
              />
              <button
                onClick={toggleConfirmPasswordShown}
                className="btn btn-light"
                type="button"
              >
                <i
                  className={
                    !isConfirmPasswordShown
                      ? 'mdi mdi-eye-outline'
                      : 'mdi mdi-eye-off-outline'
                  }
                ></i>
              </button>
              {validation.touched.confirmPassword &&
              validation.errors.confirmPassword ? (
                <FormFeedback type="invalid">
                  {validation.errors.confirmPassword}
                </FormFeedback>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>

      <BottomRow>
        <Col>
          <SaveButton type="submit" color="primary">
            Save
          </SaveButton>
        </Col>
      </BottomRow>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  user: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ChangePasswordForm;
