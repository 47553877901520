import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { processOrder } from '../../../store/orders';
import { useToggle } from '../../../hooks';
import CloseIcon from '../../../components/Common/CloseIcon';
import { withUser } from '../../../context/UserContext';

const StyledButton = styled(Button)`
  display: inline-block;
  width: 136px;
  height: 29px;
  padding: 4px 20px;
`;

const BorderedHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const BottomRow = styled(Row)`
  margin-top: 15px;
`;

const ModalButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const ProcessOrder = ({ orderData, isReady, user }) => {
  const [isOpen, toggleModal] = useToggle();
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  const dispatch = useDispatch();
  const handleProcessOrder = () => {
    const params = { orderIds: [orderData.id] };
    dispatch(processOrder({ params }));
    toggleModal();
    setIsDisabledButton(true);
  };

  return (
    <>
      {(user && user.role === 'ADMIN') || user.role === 'SALES' ? (
        <StyledButton
          disabled={!isReady || isDisabledButton}
          color="primary"
          onClick={toggleModal}
        >
          <span>Process order</span>
        </StyledButton>
      ) : null}

      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <BorderedHeader>
          <Row>
            <Col>
              <h4>Process order</h4>
            </Col>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </BorderedHeader>
        <ModalBody>
          <Row>
            <Col>Are you sure you want to process the order?</Col>
          </Row>
          <BottomRow>
            <Col>
              <ModalButton onClick={toggleModal} color="secondary">
                Cancel
              </ModalButton>
            </Col>
            <Col className="text-end">
              <ModalButton onClick={handleProcessOrder} color="primary">
                Ok
              </ModalButton>
            </Col>
          </BottomRow>
        </ModalBody>
      </StyledModal>
    </>
  );
};

ProcessOrder.propTypes = {
  isReady: PropTypes.bool,
  orderData: PropTypes.object,
  user: PropTypes.object.isRequired,
};

export default React.memo(withUser(ProcessOrder));
