import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Input } from 'reactstrap';

const makeSelectorFilter = ({ column, placeholder }) => {
  const SelectorFilter = ({ filters, availableFilters, makeSetFilter }) => {
    const options = useMemo(
      () => availableFilters[column]?.options || [],
      [availableFilters],
    );
    const value = useMemo(() => filters[column]?.value, [filters]);
    const setValue = useCallback(makeSetFilter(column), [makeSetFilter]);

    return (
      <Input
        type="select"
        id={`${column}-selector-id`}
        value={value}
        onChange={(e) => {
          if (e.target.value === placeholder) return setValue(null);

          setValue(e.target.value);
        }}
        className={cx('form-control', { 'filled-input': value })}
      >
        <option value={null} selected={!value}>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        ))}
      </Input>
    );
  };

  SelectorFilter.propTypes = {
    filters: PropTypes.object.isRequired,
    availableFilters: PropTypes.object.isRequired,
    makeSetFilter: PropTypes.func.isRequired,
  };

  return SelectorFilter;
};

export default makeSelectorFilter;
