import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Input } from 'reactstrap';
import FilterRecorsOneOrder from '../../../components/Tables/FilterRecorsOneOrder';
import { useOrder } from '../../../hooks/order';
import OrderRecordsPage from './OrderRecordsPage';
import RecordsTable from '../../../components/Tables/RecordsTable';
import SortCaret from '../../../components/Tables/SortCaret';
import RecordActionButtons from './RecordActionButtons';
import EditOrder from '../EditOrder';

const statusFormatter = (cell, row) => {
  let statusParams;
  switch (row.status) {
    case 'NEW':
      statusParams = {
        styles: { color: '#50A5F1', background: '#D3E8FB' },
        text: 'New',
      };
      break;
    case 'IN_PROGRESS':
      statusParams = {
        styles: { color: '#556EE6', background: '#D4DBF9' },
        text: 'In progress',
      };
      break;
    case 'READY':
      statusParams = {
        styles: { color: '#F1B44C', background: '#FBECD2' },
        text: 'Ready',
      };
      break;
    case 'DONE':
      statusParams = {
        styles: { color: '#34C38F', background: '#CCF0E3' },
        text: 'Done',
      };
      break;
    case 'INVALID':
      statusParams = {
        styles: { color: '#F46A6A', background: '#FCDADA' },
        text: 'Invalid',
      };
      break;
    case 'BILLED':
      statusParams = {
        styles: { color: '#343A40', background: '#CCCECF' },
        text: 'Billed',
      };
      break;
    case 'DUPLICATE':
      statusParams = {
        styles: { color: '#74788D', background: '#DCDDE2' },
        text: 'Duplicate',
      };
      break;
    default:
      statusParams = {
        styles: { color: '#F46A6A', background: '#FCDADA' },
        text: 'Unknown',
      };
  }

  return (
    <span
      style={{
        ...{ borderRadius: '10px', textAlign: 'center', padding: '0px 10px' },
        ...statusParams.styles,
      }}
    >
      {statusParams.text}
    </span>
  );
};

const dateFormatter = (cell) => {
  if (!cell) {
    return '';
  }

  return moment.utc(cell).format('YYYY.MM.DD');
};

const initialColumns = [
  {
    dataField: 'uid',
    text: 'UID',
    style: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    active: true,
  },
  {
    dataField: 'serialNumber',
    text: 'Serial No',
    active: true,
  },
  {
    dataField: 'orderedAt',
    text: 'Order Date',
    formatter: dateFormatter,
    active: false,
  },
  {
    dataField: 'product',
    text: 'Product',
    active: true,
  },
  {
    dataField: 'salesOrderItemShipAt',
    text: 'Machine Ship Date',
    formatter: dateFormatter,
    active: true,
  },
  {
    dataField: 'salesOrderItemShippingCountry',
    text: 'Ship Country',
    active: true,
  },
  {
    dataField: 'svcpn',
    text: 'SVCPN',
    style: (_cell, row) => {
      if (!row.offset) {
        return {
          backgroundColor: '#FCDADA',
          backgroundClip: 'content-box',
        };
      }
    },
    active: true,
  },
  {
    dataField: 'svcpnDescription',
    text: 'SVCPN Description',
    style: (_cell, row) => {
      if (!row.offset) {
        return {
          backgroundColor: '#FCDADA',
          backgroundClip: 'content-box',
        };
      }
    },
    active: true,
  },
  {
    dataField: 'sdfCode',
    text: 'SDF Code',
    active: true,
  },
  {
    dataField: 'offset',
    text: 'Offset',
    style: (cell) => {
      if (!cell) {
        return {
          backgroundColor: '#FCDADA',
          backgroundClip: 'content-box',
        };
      }
    },
    active: true,
  },
  {
    dataField: 'salesOrderNumber',
    text: 'Sales Order No',
    active: true,
  },
  {
    dataField: 'customerShipName',
    text: 'Customer Ship Name',
    active: true,
  },
  {
    dataField: 'customerSoldName',
    text: 'Customer Sold Name',
    active: true,
  },
];

const persistentColumns = [
  {
    dataField: 'projectCode',
    text: 'Project No',
  },
  {
    dataField: 'reference',
    text: 'Reference No',
  },
  {
    dataField: 'offsetedAt',
    text: 'Offset Date',
    formatter: dateFormatter,
  },
  {
    dataField: 'certificateId',
    text: 'Certificate ID',
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: statusFormatter,
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: (_, row) => <EditOrder row={row} />,
  },
];

const applySettings = (settings) => {
  const appliedSettings = settings.map((column) => {
    if (['orderedAt', 'salesOrderItemShipAt'].includes(column.dataField)) {
      return { ...column, formatter: dateFormatter };
    }
    if (['svcpn', 'svcpnDescription', 'offset'].includes(column.dataField)) {
      return {
        ...column,
        style: (_cell, row) => {
          if (!row.offset) {
            return {
              backgroundColor: '#FCDADA',
              backgroundClip: 'content-box',
            };
          }
        },
      };
    }
    return column;
  });

  return appliedSettings;
};

const ListOfRecords = () => {
  const [switchableColumns, setSwitchableColumns] = useState(initialColumns);
  const [rowData, setRowData] = useState([]);

  const {
    orderData,
    data,
    sortableColumns,
    filteredCount,
    count,
    withStatusNew,
    totalOffsetOfNew,
    isOpenAssignAll,
    isLoading,
    isReady,
    params,
    isOpen,
    updateParams,
    makeSetFilter,
    filters,
    availableFilters,
    clearFilter,
    hasUnknownSvcpn,
  } = useOrder();

  useEffect(() => {
    setRowData([]);
    const settings = JSON.parse(localStorage.getItem('settings'));
    if (settings) {
      const configuredColumns = applySettings(settings);
      setSwitchableColumns(configuredColumns);
    }
  }, [data]);

  const nonSelectableRows =
    data && data.flatMap((row) => (row.status !== 'NEW' ? row.id : []));

  const selectRow = {
    mode: 'checkbox',
    selected: rowData.map((row) => row.id),
    style: { backgroundColor: '#556EE654' },
    nonSelectable: nonSelectableRows,
    selectionHeaderRenderer: ({ indeterminate, checked }) => (
      <Input
        type="checkbox"
        readOnly
        checked={checked}
        innerRef={(input) => {
          // eslint-disable-next-line no-param-reassign
          if (input) input.indeterminate = indeterminate;
        }}
      />
    ),
    selectionRenderer: ({ checked, disabled }) => (
      <Input type="checkbox" readOnly checked={checked} disabled={disabled} />
    ),
    onSelect: (row, isSelect) => {
      if (isSelect === true) {
        setRowData((oldData) => [...oldData, row]);
      } else {
        setRowData((dataToFilter) =>
          dataToFilter.filter((x) => x.id !== row.id),
        );
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect === true) {
        setRowData((oldData) => [...oldData, ...rows]);
      } else {
        const idsToRemove = rows.map((row) => row.id);
        setRowData((oldData) =>
          oldData.filter((x) => !idsToRemove.includes(x.id)),
        );
      }
    },
  };

  const columnsWithSorting = useMemo(() => {
    const enabledColumns = switchableColumns.filter(
      (item) => item.active === true,
    );
    return [...enabledColumns, ...persistentColumns].map((column) => ({
      ...column,
      sort: sortableColumns.includes(column.dataField),
      sortCaret: (order) => <SortCaret order={order} />,
    }));
  }, [switchableColumns, sortableColumns.join('')]);

  const onTableChange = (type, newState) => {
    if (type === 'sort') {
      updateParams({
        ...params,
        sortingColumn: newState.sortField,
        sortingDirection: newState.sortOrder,
      });
    }
    if (type === 'pagination') {
      updateParams({
        ...params,
        page: newState.page,
        pageSize: newState.sizePerPage,
      });
    }
  };

  const formatingDate = moment.utc(orderData?.orderedAt).format('YYYY.MM.DD');

  return (
    <div>
      <OrderRecordsPage
        title={`Order ${formatingDate}`}
        subTitle="List of records"
        orderData={orderData}
        count={count}
        isReady={isReady}
        filteredCount={filteredCount}
        warning={hasUnknownSvcpn}
        params={params}
        isOpenAssignAll={isOpenAssignAll}
        withStatusNew={withStatusNew}
        totalOffsetOfNew={totalOffsetOfNew}
      >
        <>
          {rowData && rowData.length > 0 && (
            <RecordActionButtons
              params={params}
              isOpen={isOpen}
              selectedRecords={rowData}
            />
          )}
          <FilterRecorsOneOrder
            makeSetFilter={makeSetFilter}
            availableFilters={availableFilters}
            filters={filters}
            clearFilter={clearFilter}
            switchableColumns={switchableColumns}
            setSwitchableColumns={setSwitchableColumns}
          />
          <RecordsTable
            data={data}
            page={params.page}
            isLoading={isLoading}
            sizePerPage={params.pageSize}
            sort={{
              dataField: params.sortingColumn,
              order: params.sortingDirection,
            }}
            totalSize={filteredCount}
            columns={columnsWithSorting}
            onTableChange={onTableChange}
            selectRow={selectRow}
          />
        </>
      </OrderRecordsPage>
    </div>
  );
};

export default ListOfRecords;
