import { asyncActionType } from '../common';

export const GET_RETIREMENTS = asyncActionType('GET_RETIREMENTS');
export const EDIT_RETIREMENT = asyncActionType('EDIT_RETIREMENT');
export const CHECK_RETIREMENT = asyncActionType('CHECK_RETIREMENT');
export const DELETE_RETIREMENT = asyncActionType('DELETE_RETIREMENT');
export const CLEAR_RETIREMENT_ERROR = asyncActionType('CLEAR_RETIREMENT_ERROR');
export const CLEAR_INDULGENCES_TO_REPROCESS = asyncActionType(
  'CLEAR_INDULGENCES_TO_REPROCESS',
);
export const EXPORT_RETIREMENT_CSV = asyncActionType('EXPORT_RETIREMENT_CSV');
export const ROUND_UP_RETIREMENT = asyncActionType('ROUND_UP_RETIREMENT');
export const COUNT_SAME_REFERENCE = asyncActionType('COUNT_SAME_REFERENCE');
export const CLEAR_SAME_REFERENCE_COUNT = asyncActionType(
  'CLEAR_SAME_REFERENCE_COUNT',
);
