import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from './query';
import { getRetirements } from '../store/retirements';

export const useRetirements = (
  initialParams = {
    page: 1,
    pageSize: 10,
  },
) => {
  const [params, updateParams] = useQuery(initialParams);
  const dispatch = useDispatch();
  const retirementsData = useSelector(
    (state) => state.retirements.retirements.results,
  );
  const count = useSelector((state) => state.retirements.retirements.count);
  const isRetirementLoading = useSelector(
    (state) => state.retirements.isLoading,
  );

  useEffect(() => {
    dispatch(getRetirements(params));
  }, [params]);

  return {
    retirementsData,
    isRetirementLoading,
    count,
    updateParams,
    params,
  };
};
