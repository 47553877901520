import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import {
  getAllProjects,
  editProject,
  deleteProject,
  addProject,
  toggleAddProjectModal,
  exportCsv,
} from './actions';
import {
  GET_ALL_PROJECTS,
  EDIT_PROJECT,
  DELETE_PROJECT,
  ADD_PROJECT,
  EXPORT_PROJECT_CSV,
} from './actionTypes';
import { asyncSaga } from '../common';
import { projects } from '../../api';

function* addProjectSaga({ payload: { params } }) {
  try {
    const response = yield call(projects.addProject, params);
    const { project } = response.data.data;
    yield put(addProject.success(project));
    yield put(toggleAddProjectModal.success({ isOpen: true }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    switch (error.response?.status) {
      case 422:
        return yield put(editProject.fail(error.response.data.message));
      case 409:
        return yield put(addProject.fail(error.response.data.message));
      default:
        return yield put(addProject.fail('Something went wrong'));
    }
  }
}

function* editProjectSaga({ payload: { id, params } }) {
  try {
    const response = yield call(projects.editProject, {
      id,
      params,
    });
    const { project } = response.data.data;
    yield put(editProject.success({ project }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(editProject.fail(error.response.data.message));
      case 409:
        return yield put(editProject.fail(error.response.data.message));
      default:
        return yield put(editProject.fail('Something went wrong'));
    }
  }
}

function* deleteProjectSaga({ payload: { id } }) {
  try {
    yield call(projects.deleteProject, { id });
    yield put(deleteProject.success({ id }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* exportCsvSaga({ payload }) {
  try {
    const response = yield call(projects.exportCsv, payload);
    const csvBlob = new Blob([response.data], { type: 'text/csv' });
    saveAs(csvBlob, `projects_${new Date().getTime()}.csv`);
    yield put(exportCsv.success(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(exportCsv.fail(error.message));
      default:
        return yield put(exportCsv.fail('Something went wrong'));
    }
  }
}

export default function* allProjectsSaga() {
  yield takeLatest(
    GET_ALL_PROJECTS.RUN,
    asyncSaga((action) => projects.getAllProjects(action), getAllProjects),
  );
  yield takeEvery(EXPORT_PROJECT_CSV.RUN, exportCsvSaga);
  yield takeEvery(EDIT_PROJECT.RUN, editProjectSaga);
  yield takeEvery(DELETE_PROJECT.RUN, deleteProjectSaga);
  yield takeEvery(ADD_PROJECT.RUN, addProjectSaga);
}
