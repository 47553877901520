import { asyncAction } from '../common';
import {
  GET_PROJECTS,
  TOGGLE_ADD_PROJECT,
  FIND_RELATED_RETIREMENT,
  CLEAR_RELATED_RETIREMENT,
} from './actionTypes';

export const toggleAddProjectModal = asyncAction(TOGGLE_ADD_PROJECT);
export const getProjects = asyncAction(GET_PROJECTS);
export const findRelatedRetirement = asyncAction(FIND_RELATED_RETIREMENT);
export const clearRelatedRetirement = asyncAction(CLEAR_RELATED_RETIREMENT);
