import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import ViewDetails from './ViewDetails';
import ProcessOrder from './ProcessOrder';
import UnknownSvcpnWarning from './UnknownSvcpnWarning';
import PageTitle from '../../../components/Common/PageTitle';
import AssignAllToProject from './AssignAllToProject';
import Export from './Export';

const HeaderRow = styled(Row)`
  margin-bottom: 20px;
`;

const Title = styled.h3`
  width: auto;
  margin: 0 5px 0 0;
`;

const OrderRecordsPage = ({
  children,
  title,
  subTitle,
  params,
  filteredCount,
  count,
  isOpenAssignAll,
  withStatusNew,
  totalOffsetOfNew,
  orderData,
  warning,
  isReady,
}) => {
  return (
    <div className="page-content">
      <PageTitle title={title} />
      <Container fluid>
        <HeaderRow>
          <Col className="col-10">
            <Row className="align-items-center">
              <Title>{title}</Title>
              <ViewDetails orderData={orderData} />
              <UnknownSvcpnWarning warning={warning} />
            </Row>
          </Col>
          <Col className="col-2 text-end">
            <Col>
              <ProcessOrder isReady={isReady} orderData={orderData} />
            </Col>
          </Col>
        </HeaderRow>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <span>
                    <CardTitle tag="span" className="h4">
                      {subTitle}
                    </CardTitle>
                    <span className="ml-4">
                      {count && ` ${filteredCount}/${count} records`}
                    </span>
                  </span>
                  <span>
                    <Export params={params} />
                    <AssignAllToProject
                      params={params}
                      isOpenAssignAll={isOpenAssignAll}
                      withStatusNew={withStatusNew}
                      totalOffsetOfNew={totalOffsetOfNew}
                    />
                  </span>
                </div>
                <div className="mt-3">{children}</div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

OrderRecordsPage.defaultProps = {
  orderData: null,
  filteredCount: null,
  count: null,
};

OrderRecordsPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  params: PropTypes.object,
  orderData: PropTypes.object,
  filteredCount: PropTypes.number,
  count: PropTypes.number,
  isOpenAssignAll: PropTypes.bool.isRequired,
  withStatusNew: PropTypes.number,
  totalOffsetOfNew: PropTypes.number,
  warning: PropTypes.bool,
  isReady: PropTypes.bool,
};

export default OrderRecordsPage;
