import { takeEvery, call, put } from 'redux-saga/effects';
import {
  getEmailRecipients,
  deleteEmailRecipient,
  updateEmailRecipient,
  createEmailRecipient,
} from './actions';
import {
  GET_EMAIL_RECIPIENTS,
  DELETE_EMAIL_RECIPIENT,
  UPDATE_EMAIL_RECIPIENT,
  CREATE_EMAIL_RECIPIENT,
} from './actionTypes';
import { emailRecipients } from '../../api';
import Notification from '../../components/Common/Notification';

function* getEmailRecipientsSaga() {
  try {
    const response = yield call(emailRecipients.getEmailRecipients);
    yield put(getEmailRecipients.success(response.data.data));
  } catch (error) {
    yield put(getEmailRecipients.fail({ error: error.message }));
    console.error(error);
  }
}

function* createEmailRecipientSaga({ payload: { email, type } }) {
  try {
    yield call(emailRecipients.createEmailRecipient, email, type);
    yield put(createEmailRecipient.success({ email, type }));
    yield put(getEmailRecipients.run());
  } catch (error) {
    Notification('error', error.message);
    yield put(createEmailRecipient.fail({ error: error.message }));
    console.error(error);
  }
}

function* deleteEmailRecipientSaga({ payload: { id } }) {
  try {
    yield call(emailRecipients.deleteEmailRecipient, id);
    yield put(deleteEmailRecipient.success({ id }));
    yield put(getEmailRecipients.run());
  } catch (error) {
    Notification('error', error.message);
    yield put(deleteEmailRecipient.fail({ error: error.message }));
    console.log(error);
  }
}

function* updateEmailRecipientSaga({ payload: { id, email } }) {
  try {
    yield call(emailRecipients.updateEmailRecipient, id, email);
    yield put(updateEmailRecipient.success({ id, email }));
    yield put(getEmailRecipients.run());
  } catch (error) {
    Notification('error', error.message);
    yield put(updateEmailRecipient.fail({ error: error.message }));
    console.log(error);
  }
}

export default function* emailRecipientSaga() {
  yield takeEvery(GET_EMAIL_RECIPIENTS.RUN, getEmailRecipientsSaga);
  yield takeEvery(DELETE_EMAIL_RECIPIENT.RUN, deleteEmailRecipientSaga);
  yield takeEvery(UPDATE_EMAIL_RECIPIENT.RUN, updateEmailRecipientSaga);
  yield takeEvery(CREATE_EMAIL_RECIPIENT.RUN, createEmailRecipientSaga);
}
