import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CloseIcon from '../../../components/Common/CloseIcon';
import MoveToBilledForm from './MoveToBilledForm';
import { toggleBilledRecordsModal } from '../../../store/records';

const BorderedHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const StyledButton = styled(Button)`
  display: inline-block;
  width: auto;
  height: 37px;
  padding: 4px 20px;
  margin-left: 10px;
`;

const MoveToBilled = ({ setIsOpen, isOpenBilledModal, params }) => {
  const dispatch = useDispatch();
  const toggleModal = () => {
    setIsOpen(!isOpenBilledModal);
    return dispatch(toggleBilledRecordsModal());
  };

  const { withStatusDone } = useSelector((state) => ({
    withStatusDone: state.records.records.withStatusDone,
  }));
  return (
    <>
      <StyledButton
        color="primary"
        disabled={!withStatusDone || withStatusDone === 0}
        onClick={toggleModal}
        className="mt-on-tablet"
      >
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <span>Bill all "done" records</span>
      </StyledButton>
      <StyledModal toggle={toggleModal} isOpen={isOpenBilledModal}>
        <BorderedHeader>
          <Row>
            <Col>
              <h4>Create Billing Summary</h4>
            </Col>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </BorderedHeader>
        <ModalBody>
          <MoveToBilledForm
            toggleModal={toggleModal}
            params={params}
            setIsOpen={setIsOpen}
            isOpenBilledModal={isOpenBilledModal}
          />
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default MoveToBilled;

MoveToBilled.propTypes = {
  setIsOpen: PropTypes.func,
  isOpenBilledModal: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
};
