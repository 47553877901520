import { asyncAction } from '../common';
import {
  GET_RECORDS,
  FETCH_FILTERS,
  TOGGLE_ASSIGN_RECORDS_MODAL,
  ASSIGN_RECORDS_TO_PROJECT,
  ASSIGN_ALL_TO_PROJECT,
  TOGGLE_ASSIGN_ALL_MODAL,
  EDIT_RECORD,
  CLEAR_EDIT_RECORD_ERROR,
  EXPORT_CSV,
  TOGGLE_BILLED_RECORDS_MODAL,
  PROCESS_RECORD,
  MOVE_RECORDS_TO_BILLED,
  SET_ROW_DATA_ARRAY,
  BULK_ACTION,
} from './actionTypes';

export const getRecords = asyncAction(GET_RECORDS);
export const fetchFilters = asyncAction(FETCH_FILTERS);
export const toggleAssignRecordsModal = asyncAction(
  TOGGLE_ASSIGN_RECORDS_MODAL,
);
export const toggleBilledRecordsModal = asyncAction(
  TOGGLE_BILLED_RECORDS_MODAL,
);
export const assignToProject = asyncAction(ASSIGN_RECORDS_TO_PROJECT);
export const processRecord = asyncAction(PROCESS_RECORD);
export const moveRecordsToBilled = asyncAction(MOVE_RECORDS_TO_BILLED);
export const setRowDataArray = asyncAction(SET_ROW_DATA_ARRAY);
export const toggleAssignAllModal = asyncAction(TOGGLE_ASSIGN_ALL_MODAL);
export const assignAllToProject = asyncAction(ASSIGN_ALL_TO_PROJECT);
export const editRecord = asyncAction(EDIT_RECORD);
export const clearEditRecordError = asyncAction(CLEAR_EDIT_RECORD_ERROR);
export const exportCsv = asyncAction(EXPORT_CSV);
export const bulkAction = asyncAction(BULK_ACTION);
