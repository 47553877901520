import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

const Link = ({ icon, isCollapsed, children, ...rest }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <li>
      <NavLink
        {...rest}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        activeClassName="mm-active"
      >
        <i className={cx('bx', icon)} />
        {(!isCollapsed || isHover) && <span>{children}</span>}
      </NavLink>
    </li>
  );
};

Link.propTypes = {
  icon: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default Link;
