import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import cx from 'classnames';

const IconButton = ({ icon, text, ...props }) => (
  <Button className="btn-label" {...props}>
    <i className={cx('bx label-icon', icon)} />
    {text}
  </Button>
);

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export default IconButton;
