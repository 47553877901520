import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from './query';
import { getAllProjects } from '../store/allProjects';

const isEqual = require('react-fast-compare');

export const useAllProjects = (
  initialParams = {
    page: 1,
    pageSize: 10,
    deleted: 'Valid',
  },
) => {
  const [params, updateParams] = useQuery(initialParams);
  const dispatch = useDispatch();
  const allProjectsData = useSelector(
    (state) => state.allProjects.allProjects.results,
    isEqual,
  );

  const makeSetFilter = useCallback(
    (column) => (value) => {
      const newParams = {
        ...params,
        page: 1,
        [column]: value,
      };
      const keysToClear = [];
      if (!value) keysToClear.push(column);

      updateParams(newParams, keysToClear);
    },
    [params, updateParams],
  );
  const projectsCount = useSelector(
    (state) => state.allProjects.allProjects.count,
  );
  const isProjectsLoading = useSelector((state) => state.allProjects.isLoading);
  const isOpen = useSelector((state) => state.allProjects.isOpen);
  const totalRemaining = useSelector(
    (state) => state.allProjects.allProjects.totalRemaining,
  );

  const totalPurchased = useSelector(
    (state) => state.allProjects.allProjects.totalPurchased,
  );

  useEffect(() => {
    dispatch(getAllProjects(params));
  }, [params]);

  return {
    allProjectsData,
    makeSetFilter,
    sortableColumns: useSelector(
      (state) => state.allProjects.allProjects.params.sortableColumns,
    ),
    availableFilters: useSelector(
      (state) =>
        state.allProjects.allProjects.params.availableFilters.reduce(
          (acc, filter) => {
            acc[filter.column] = filter;
            return acc;
          },
          {},
        ),
      isEqual,
    ),
    filteredCount: useSelector(
      (state) => state.allProjects.allProjects.filteredCount,
    ),
    filters: useSelector(
      (state) =>
        state.allProjects.allProjects.params.filters.reduce((acc, filter) => {
          acc[filter.column] = filter;
          return acc;
        }, {}),
      isEqual,
    ),
    isProjectsLoading,
    projectsCount,
    updateParams,
    params,
    isOpen,
    totalRemaining,
    totalPurchased,
  };
};
