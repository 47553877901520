import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from './query';
import { getRecords } from '../store/records';

const isEqual = require('react-fast-compare');

export const useOrder = (
  initialParams = {
    page: 1,
    pageSize: 50,
    sortingColumn: 'customerShipName',
    sortingDirection: 'desc',
  },
) => {
  const { id } = useParams();
  const [params, updateParams] = useQuery({
    ...initialParams,
    ...{ orderId: id },
  });
  const dispatch = useDispatch();

  const clearFilter = useCallback(() => {
    updateParams({ ...initialParams, orderId: id }, [], true);
  }, [initialParams]);

  const makeSetFilter = useCallback(
    (column) => (value) => {
      const newParams = {
        ...params,
        page: 1,
        [column]: value,
      };
      const keysToClear = [];
      if (!value) keysToClear.push(column);

      updateParams(newParams, keysToClear);
    },
    [params, updateParams],
  );

  useEffect(() => {
    dispatch(getRecords(params));
  }, [params, id]);

  const fetchEdit = () =>
    useEffect(() => {
      dispatch(getRecords());
    }, []);

  return {
    orderData: useSelector((state) => state.order.order),
    data: useSelector((state) => state.records.records.results),
    hasUnknownSvcpn: useSelector(
      (state) => state.records.records.hasUnknownSvcpn,
    ),
    sortableColumns: useSelector(
      (state) => state.records.records.params.sortableColumns,
    ),
    filteredCount: useSelector((state) => state.records.records.filteredCount),
    count: useSelector((state) => state.records.records.count),
    withStatusNew: useSelector((state) => state.records.records.withStatusNew),
    totalOffsetOfNew: useSelector(
      (state) => state.records.records.totalOffsetOfNew,
    ),
    isOpenAssignAll: useSelector((state) => state.records.isOpenAssignAll),
    isReady: useSelector((state) => state.records.records.isReady),
    hasUnfinishedTask: useSelector(
      (state) => state.records.records.hasUnfinishedTask,
    ),
    isLoading: useSelector((state) => state.records.isLoading),
    isOpen: useSelector((state) => state.records.isOpen),
    availableFilters: useSelector(
      (state) =>
        state.records.records.params.availableFilters.reduce((acc, filter) => {
          acc[filter.column] = filter;
          return acc;
        }, {}),
      isEqual,
    ),
    filters: useSelector(
      (state) =>
        state.records.records.params.filters.reduce((acc, filter) => {
          acc[filter.column] = filter;
          return acc;
        }, {}),
      isEqual,
    ),
    params,
    makeSetFilter,
    updateParams,
    fetchEdit,
    clearFilter,
  };
};
