import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';
import { deleteProject } from '../../store/allProjects';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const BottomRow = styled(Row)`
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const DeleteProject = ({ project, isOpen, toggleModal }) => {
  const dispatch = useDispatch();
  const handleDeleteUser = () => {
    dispatch(deleteProject({ id: project.id }));
    toggleModal();
  };

  return (
    <StyledModal toggle={toggleModal} isOpen={isOpen}>
      <ModalBody>
        <HeaderRow>
          <Col xs={10}>
            <h4>Delete Project</h4>
          </Col>
          <Col xs={2}>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Col>
        </HeaderRow>
        <Row>
          <Col>Are you sure you want to delete {project.code}?</Col>
        </Row>
        <BottomRow>
          <Col sm={8}>
            <StyledButton onClick={toggleModal} color="secondary">
              Cancel
            </StyledButton>
          </Col>
          <Col sm={4}>
            <StyledButton onClick={handleDeleteUser} color="primary">
              Ok
            </StyledButton>
          </Col>
        </BottomRow>
      </ModalBody>
    </StyledModal>
  );
};

DeleteProject.propTypes = {
  project: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default DeleteProject;
