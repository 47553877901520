import { makeReducer } from '../common';
import {
  GET_BILLING_SUMMARY,
  EDIT_BILLING_SUMMARY,
  DOWNLOAD_BILLING_SUMMARY,
  DELETE_BILLING_SUMMARY,
  CLEAR_EDIT_BILLINGS_ERROR,
  SENT_BILLING_SUMMARY,
} from './actionTypes';

export default makeReducer(
  {
    [GET_BILLING_SUMMARY.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [GET_BILLING_SUMMARY.SUCCESS]: (state, { payload }) => ({
      ...state,
      billings: payload,
      isLoading: false,
    }),
    [GET_BILLING_SUMMARY.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [DOWNLOAD_BILLING_SUMMARY.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [DOWNLOAD_BILLING_SUMMARY.SUCCESS]: (state, { payload }) => ({
      ...state,
      payload,
      isLoading: false,
    }),
    [DOWNLOAD_BILLING_SUMMARY.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [EDIT_BILLING_SUMMARY.RUN]: (state) => ({
      ...state,
    }),
    [EDIT_BILLING_SUMMARY.SUCCESS]: (state, { payload: { billing } }) => ({
      ...state,
      billings: {
        ...state.billings,
        results: state.billings.results.map((row) =>
          row.id === billing.id ? { ...row, ...billing } : row,
        ),
      },
    }),
    [EDIT_BILLING_SUMMARY.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [SENT_BILLING_SUMMARY.RUN]: (state) => ({
      ...state,
    }),
    [SENT_BILLING_SUMMARY.SUCCESS]: (state, { payload: { billing } }) => ({
      ...state,
      billings: {
        ...state.billings,
        results: state.billings.results.map((row) =>
          row.id === billing.id ? { ...row, ...billing } : row,
        ),
      },
    }),
    [SENT_BILLING_SUMMARY.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [DELETE_BILLING_SUMMARY.RUN]: (state) => ({
      ...state,
    }),
    [DELETE_BILLING_SUMMARY.SUCCESS]: (state, { payload: { id } }) => ({
      ...state,
      billings: {
        ...state.billings,
        results: state.billings.results.filter((row) => row.id !== id),
      },
    }),
    [DELETE_BILLING_SUMMARY.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [CLEAR_EDIT_BILLINGS_ERROR.RUN]: (state) => ({
      ...state,
      error: null,
    }),
  },
  {
    billings: {
      results: [],
      filteredCount: null,
      count: null,
      params: {
        sortableColumns: [],
        availableFilters: [],
        filters: [],
      },
    },
    error: null,
    isLoading: false,
  },
);
