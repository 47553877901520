import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import cx from 'classnames';
import styled from 'styled-components';
import cross from '../../../assets/images/cross.svg';

const ClearButton = styled.span`
  position: absolute;
  top: 8px;
  right: 30px;
  cursor: pointer;
`;

const inpuFilter = ({ column, placeholder }) => {
  const SelectorFilter = ({ filters, makeSetFilter }) => {
    const initialFilterValue = filters[column]?.value || '';
    const [value, setValue] = useState(initialFilterValue);

    const updateFilter = useCallback(makeSetFilter(column), [
      makeSetFilter,
      column,
    ]);

    const handleChange = (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      updateFilter(newValue);
    };

    const clearInput = () => {
      setValue('');
      updateFilter('');
    };

    return (
      <div className="input-wrapper">
        <DebounceInput
          id={`${column}-input-id`}
          value={value}
          onChange={handleChange}
          className={cx('form-control', { 'filled-input': value })}
          style={{ paddingRight: '35px' }}
          debounceTimeout={1000}
          placeholder={placeholder}
        />
        {value && (
          <ClearButton onClick={clearInput}>
            <img src={cross} alt="cross" />
          </ClearButton>
        )}
      </div>
    );
  };

  SelectorFilter.propTypes = {
    filters: PropTypes.object.isRequired,
    makeSetFilter: PropTypes.func.isRequired,
  };

  return SelectorFilter;
};

export default inpuFilter;
