import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';
import NewProjectForm from './NewProjectForm';
import { toggleAddProjectModal } from '../../store/projects';

const StyledButton = styled(Button)`
  display: inline-block;
  width: 150px;
`;

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const AddNewProject = ({ isOpen }) => {
  const dispatch = useDispatch();
  const toggleModal = () => dispatch(toggleAddProjectModal(!isOpen));

  return (
    <>
      <StyledButton color="primary" onClick={toggleModal}>
        <span>+ Add New Project</span>
      </StyledButton>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>Add New Project</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <NewProjectForm />
        </ModalBody>
      </StyledModal>
    </>
  );
};

AddNewProject.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default AddNewProject;
