import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const SortCaret = ({ order }) => {
  const isDown = order === 'asc';

  return (
    <>
      <i
        className={cx('bx', 'ml-1', 'small', {
          'bxs-down-arrow': order && !isDown,
          'bx-down-arrow': !order || isDown,
        })}
      />
      <i
        className={cx('bx', 'small', {
          'bxs-up-arrow': order && isDown,
          'bx-up-arrow': !order || !isDown,
        })}
      />
    </>
  );
};

SortCaret.propTypes = {
  order: PropTypes.string,
};

export default memo(SortCaret);
