import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';
import { deleteRetirement } from '../../store/retirements';
import { retirements } from '../../api';
import { useToggle } from '../../hooks';
import HasDoneRecords from './HasDoneRecords';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const BottomRow = styled(Row)`
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  width: 120px;
  font-size: 16px;
`;

const DeleteCompleteRetirement = ({
  retirement,
  isOpen,
  toggleModal,
  onClick,
}) => {
  const dispatch = useDispatch();
  const [isHasDoneRecordsOpen, toggleHasDoneRecordsModal] = useToggle();

  const handleDeleteRetirement = async () => {
    const { data } = await retirements.checkStatus({ id: retirement.id });

    if (data.data.length) {
      toggleModal();
      toggleHasDoneRecordsModal();
    } else {
      dispatch(deleteRetirement({ id: retirement.id }));
      toggleModal();
    }
  };

  return (
    <>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>Delete retirement</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <Row>
            <Col>
              You are about to delete a &apos;Complete&apos; retirement. This
              action will result in all associated records becoming
              &apos;New&apos;.
              <p>Are you sure you want to delete the retirement?</p>
            </Col>
          </Row>
          <BottomRow>
            <Col sm={7}>
              <StyledButton onClick={toggleModal} color="secondary">
                No, cancel
              </StyledButton>
            </Col>
            <Col sm={4}>
              <StyledButton onClick={handleDeleteRetirement} color="primary">
                Yes, delete
              </StyledButton>
            </Col>
          </BottomRow>
        </ModalBody>
      </StyledModal>
      <HasDoneRecords
        isOpen={isHasDoneRecordsOpen}
        toggleModal={toggleHasDoneRecordsModal}
        onClick={onClick}
        retirement={retirement}
      />
    </>
  );
};

DeleteCompleteRetirement.propTypes = {
  retirement: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DeleteCompleteRetirement;
