import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import { DatePickerField } from '../../../components/Common/DatePicker';
import { CustomSelect } from '../../Orders/CustomSelect';
import { bulkAction } from '../../../store/records';
import { findRelatedRetirement } from '../../../store/projects';
import Loader from '../../../components/Common/Loader';
import ButtonLoader from '../../../components/Common/ButtonLoader';
import { format } from '../../../helpers/formatter';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  display: grid;
  padding: 0 28px;
`;

const SaveButton = styled(Button)`
  width: 113px;
  height: 36px;
  padding: 0px;
  font-size: 18px;
  border-radius: 8px;
`;

const projectOptions = (projects) =>
  projects.map((project) => ({
    value: project,
    label: project.code,
  }));

const AssignToProjectForm = ({
  params,
  isOpenAssignModal,
  setIsOpen,
  data,
}) => {
  const dispatch = useDispatch();

  const { isLoading: isLoadingRequest } = useSelector((state) => ({
    isLoading: state.records.isLoading,
  }));

  const { totalOffsetOfNew } = useSelector((state) => ({
    totalOffsetOfNew: state.records.records.totalOffsetOfNew,
  }));

  const { relatedRetirement } = useSelector((state) => ({
    relatedRetirement: state.projects.relatedRetirement?.results[0],
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      project: null,
      assignedAt: moment(new Date()).format('YYYY.MM.DD'),
      offset: '',
      note: '',
    },
    validationSchema: Yup.object({
      project: Yup.object().required('Please select the project'),
      offset: Yup.number()
        .required('Please enter an offset')
        .max(
          totalOffsetOfNew,
          'Cannot be more than total remaining carbon or remaining value of assigned project',
        )
        .when('project', (project) => {
          if (project) {
            return Yup.number()
              .required('Please enter an offset')
              .min(0.5)
              .max(
                Math.min(
                  totalOffsetOfNew,
                  (validation.values.project &&
                    validation.values.project.remaning) ||
                    0,
                ),
                'Cannot be more than total remaining carbon or remaining value of assigned project',
              )
              .typeError('You must specify a number');
          }
        })
        .typeError('You must specify a number'),
      assignedAt: Yup.date().nullable().required('Assign Date is required'),
      note: Yup.string().max(1000, 'Note cannot exceed 1000 characters'),
    }),
    onSubmit: (formParams) => {
      const { assignedAt, offset, project, note } = formParams;
      const { id, code } = project;
      dispatch(
        bulkAction({
          params: {
            projectId: id,
            projectName: code,
            assignedAt: moment.utc(assignedAt, 'YYYY.MM.DD').format(),
            offset,
            note,
          },
          queryParams: params,
          action: 'assign',
        }),
      );
      setIsOpen(!isOpenAssignModal);
    },
  });

  useEffect(() => {
    const { project, assignedAt } = validation.values;

    if (project && assignedAt) {
      dispatch(findRelatedRetirement({ projectId: project.id, assignedAt }));
    }
  }, [validation.values.project, validation.values.assignedAt, dispatch]);

  useEffect(() => {
    if (relatedRetirement?.note) {
      validation.setFieldValue('note', relatedRetirement.note);
    } else {
      validation.setFieldValue('note', '');
    }
  }, [relatedRetirement?.note]);

  if (!data)
    return (
      <FormContainer>
        <Loader isShown />
      </FormContainer>
    );

  return (
    <FormikProvider value={validation}>
      <FormContainer>
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">
                  Project No
                  <Asterix>*</Asterix>
                </Label>
                <div className="input-group">
                  <CustomSelect
                    inputId="project"
                    name="project"
                    onChange={(value) => {
                      validation.setFieldValue('project', value.value);
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.project}
                    options={projectOptions(data)}
                    invalid={
                      !!(
                        validation.touched.project && validation.errors.project
                      )
                    }
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          {validation.values.project && (
            <>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-label">Project Name</Label>
                    <Input
                      plaintext
                      value={validation.values.project.title}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    The remaining total tonnage is{' '}
                    {format(validation.values.project.remaning)} tonne
                  </p>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">
                  Assign Date
                  <Asterix>*</Asterix>
                </Label>
                <DatePickerField
                  onChange={(value) => {
                    validation.setFieldValue('assignedAt', value);
                  }}
                  name="assignedAt"
                  placeholderText="Assign Date"
                />
                {relatedRetirement && (
                  <p style={{ color: 'gray', fontSize: '12px' }}>
                    Retirement already exists for this date
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">
                  Offset Carbon to the Project
                  <Asterix>*</Asterix>
                </Label>
                <Input
                  name="offset"
                  className="form-control"
                  placeholder="Offset"
                  type="offset"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.offset || ''}
                  invalid={
                    !!(validation.touched.offset && validation.errors.offset)
                  }
                />
                {validation.touched.offset && validation.errors.offset ? (
                  <FormFeedback type="invalid">
                    {validation.errors.offset}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-label">Note</Label>
                <Input
                  type="textarea"
                  name="note"
                  className="form-control"
                  placeholder="Provide additional information for the Retirement Request"
                  maxLength="1000"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.note || ''}
                  invalid={
                    !!(validation.touched.note && validation.errors.note)
                  }
                />
                {validation.touched.note && validation.errors.note ? (
                  <FormFeedback type="invalid">
                    {validation.errors.note}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <BottomRow>
            <Col>
              <SaveButton
                disabled={isLoadingRequest}
                type="submit"
                color="primary"
              >
                {isLoadingRequest ? <ButtonLoader isShown /> : 'Save'}
              </SaveButton>
            </Col>
          </BottomRow>
        </Form>
      </FormContainer>
    </FormikProvider>
  );
};

export default AssignToProjectForm;

AssignToProjectForm.propTypes = {
  setIsOpen: PropTypes.func,
  params: PropTypes.object.isRequired,
  isOpenAssignModal: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
};
