import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import Overlay from './Overlay';
import NoDataIndicationRecords from './NoDataIndicationRecords';

const RecordsTable = ({
  isLoading,
  data,
  columns,
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  sort,
  children,
  selectRow,
}) => {
  return (
    <PaginationProvider
      pagination={paginationFactory({
        page,
        sizePerPage,
        totalSize,
        custom: true,
      })}
      keyField="id"
      columns={columns}
      data={data}
    >
      {({ paginationProps, paginationTableProps }) => (
        <>
          {!!children && children}
          <Row>
            <Col xl="12">
              <div className="table-responsive">
                <BootstrapTable
                  keyField={'id'}
                  columns={columns}
                  data={data}
                  loading={isLoading}
                  overlay={Overlay}
                  noDataIndication={NoDataIndicationRecords}
                  responsive
                  bordered={false}
                  striped={false}
                  selectRow={selectRow}
                  classes={'table align-middle table-nowrap'}
                  headerWrapperClasses={'table-light'}
                  remote={{
                    filter: true,
                    pagination: true,
                    sort: true,
                  }}
                  sort={sort}
                  onTableChange={onTableChange}
                  {...paginationTableProps}
                />
              </div>
            </Col>
          </Row>

          <Row className="align-items-md-center mt-30">
            <Col className="inner-custom-pagination d-flex">
              <div className="text-md-right ms-auto">
                <PaginationListStandalone {...paginationProps} />
              </div>
            </Col>
          </Row>
        </>
      )}
    </PaginationProvider>
  );
};

RecordsTable.propTypes = {
  data: PropTypes.array,
  page: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  onTableChange: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    dataField: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }),
  selectRow: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default RecordsTable;
