import urls from './urls';
import client from '../utils/api';

const getSvcpns = (query = {}) => client.get(urls.svcpns.getSvcpns(), query);
const getUnknownSvcpns = (query = {}) =>
  client.get(urls.svcpns.getUnknownSvcpns(), query);
const addSvcpn = (body) => client.post(urls.svcpns.addSvcpn(), body);
const editSvcpn = ({ id, params }) =>
  client.put(urls.svcpns.editSvcpn(id), params);
const getSvcpnsOrders = (id) => client.get(urls.svcpns.getSvcpnsOrders(id));
const exportCsv = (query = {}) => client.get(urls.svcpns.exportCsv(), query);

export default {
  getSvcpns,
  getUnknownSvcpns,
  addSvcpn,
  editSvcpn,
  getSvcpnsOrders,
  exportCsv,
};
