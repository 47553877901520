import React, { useMemo } from 'react';
import { Row, Col, CardTitle, Container, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import BillingSummaryTable from '../../components/Tables/BillngsSummaryTable';
import PageTitle from '../../components/Common/PageTitle';
import SortCaret from '../../components/Tables/SortCaret';
import { withUser } from '../../context/UserContext';
import { useBillings } from '../../hooks/billings';
import ActionMenu from './ActionMenu';
import { format } from '../../helpers/formatter';

const BillingSummaryPage = () => {
  const {
    billingsData,
    updateParams,
    params,
    filteredCount,
    isLoading,
    sortableColumns,
  } = useBillings();

  function dateFormatter(cell) {
    if (!cell) {
      return '';
    }

    return moment(cell).format('YYYY.MM.DD');
  }

  function priceFormatter(cell) {
    return <span>$ {format(cell)}</span>;
  }

  const statusFormatter = (cell) => {
    if (cell === 'SENT') {
      return (
        <span
          style={{
            color: '#34C38F',
            background: '#CCF0E3',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '0px 10px',
          }}
        >
          Sent
        </span>
      );
    }
    return '';
  };

  const columns = [
    {
      dataField: 'createdAt',
      text: 'Date Created',
      formatter: dateFormatter,
    },
    {
      dataField: 'name',
      text: 'Name',
      classes: 'text-break text-wrap',
    },
    {
      dataField: 'totalRecords',
      text: 'Total Records',
      formatter: format,
    },
    {
      dataField: 'totalTonnes',
      text: 'Total Tonnes',
      formatter: format,
    },
    {
      dataField: 'totalPrice',
      text: 'Total Price',
      formatter: priceFormatter,
    },
    {
      dataField: 'sendDate',
      text: 'Send Date',
      formatter: dateFormatter,
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: statusFormatter,
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (_, row) => <ActionMenu row={row} />,
    },
  ];

  const columnsWithSorting = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        sort: sortableColumns.includes(column.dataField),
        sortCaret: (order) => <SortCaret order={order} />,
      })),
    [sortableColumns.join('')],
  );

  const onBillingsTableChange = (type, newState) => {
    if (type === 'sort') {
      updateParams({
        ...params,
        sortingColumn: newState.sortField,
        sortingDirection: newState.sortOrder,
      });
    }
    if (type === 'pagination') {
      updateParams({
        ...params,
        page: newState.page,
        pageSize: newState.sizePerPage,
      });
    }
  };

  return (
    <div className="page-content">
      <PageTitle title="Billing Summary" />
      <Container fluid>
        <Row>
          <div className="mb-2">
            <div className="d-flex justify-content-between ">
              <h3>Billing Summary</h3>
            </div>
          </div>
          <Col xl={12}>
            <Card>
              <CardBody>
                <div>
                  <CardTitle tag="span" className="h4">
                    List of Billing Summaries
                  </CardTitle>
                </div>
                <BillingSummaryTable
                  columns={columnsWithSorting}
                  data={billingsData || []}
                  sizePerPage={params.pageSize}
                  totalSize={filteredCount}
                  page={params.page}
                  sort={{
                    dataField: params.sortingColumn,
                    order: params.sortingDirection,
                  }}
                  onTableChange={onBillingsTableChange}
                  isLoading={isLoading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

BillingSummaryPage.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withUser(BillingSummaryPage);
