export default {
  records: {
    getRecords: () => 'records',
    getFilters: () => 'records/filters',
    assignToProject: (id) => `records/${id}/assign`,
    processRecord: () => `records/statusProcessed`,
    assignAllToProject: () => `records/assignAll`,
    editRecord: (id) => `records/${id}`,
    exportCsv: () => `records/exportAsCSV`,
    bulkAction: () => `records/bulkAction`,
    moveRecordsToBilled: () => 'records/statusBilled',
    checkStatus: () => 'records/checkStatus',
  },
  svcpns: {
    getSvcpns: () => 'svcpns/known',
    getUnknownSvcpns: () => 'svcpns/unknown',
    addSvcpn: () => 'svcpns',
    editSvcpn: (id) => `svcpns/${id}`,
    getSvcpnsOrders: (id) => `/svcpns/orders/${id}`,
    exportCsv: () => 'svcpns/exportAsCSV',
  },
  orders: {
    getOrders: () => 'orders',
    getOrder: (id) => `orders/${id}`,
    moveToBilled: () => 'orders/statusBilled',
    processOrder: () => 'orders/statusProcessed',
    lastOrders: () => 'orders/lastUnprocessed',
  },
  users: {
    logIn: () => 'users/login',
    sendSMSCode: () => 'users/sendSMSCode',
    verifySMSCode: () => 'users/verifySMSCode',
    registerOTPApp: () => 'users/registerOTPApp',
    verifyOTPApp: () => 'users/verifyOTPApp',
    verifyOTPCode: () => 'users/verifyOTPCode',
    logOut: () => 'users/logout',
    createUser: () => 'users',
    deleteUser: (id) => `users/${id}`,
    updateUser: (id) => `users/${id}`,
    getUsers: () => 'users',
    updateUserPassword: (id) => `users/${id}/passwords`,
    updatePassword: () => `users/passwords`,
    sendPasswordResetEmail: () => `users/sendPasswordResetEmail`,
    checkPassResetToken: () => `users/resetPassword`,
    resetSecondFactor: () => `users/resetSecondFactor`,
  },
  projects: {
    addProject: () => 'projects',
    getProjects: () => 'projects',
    editProject: (id) => `projects/${id}`,
    deleteProject: (id) => `projects/${id}`,
    assignToProject: (id) => `projects/${id}/assignOrders`,
    getAllProjects: () => 'projectsAll',
    exportCsv: () => '/projectsAll/exportAsCSV',
    findRelatedRetirement: () => 'projects/findRelatedRetirement',
  },
  retirements: {
    getRetirements: () => 'retirements',
    editRetirement: (id) => `/retirements/${id}/complete`,
    checkRetirement: (id) => `/retirements/${id}/checkRelatedIndulgence`,
    checkStatus: (id) => `/retirements/checkStatus/${id}`,
    checkBillings: (id) => `/retirements/${id}`,
    deleteRetirement: (id) => `retirements/${id}`,
    exportCsv: () => '/retirementsAll/exportAsCSV',
    RoundUp: (id) => `/retirements/${id}`,
    countSameReference: () => `/retirements/countSameReference`,
  },
  billings: {
    getBillings: () => 'billings',
    getBilling: (id) => `billings/${id}`,
    editBillings: (id) => `billings/${id}`,
    sentBillings: (id) => `billings/${id}/sent`,
    downloadBillings: (id) => `billings/${id}/download`,
    deleteBilling: (id) => `billings/${id}`,
  },
  emailRecipients: {
    getEmailRecipients: () => 'settings/emailRecipients',
    createEmailRecipient: () => 'settings/emailRecipients',
    deleteEmailRecipient: (id) => `settings/emailRecipients/${id}`,
    updateEmailRecipient: (id) => `settings/emailRecipients/${id}`,
  },
  transmissionLogs: {
    getTransmissionLogs: () => 'transmissionLogs',
  },
};
