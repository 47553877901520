import { all, fork } from 'redux-saga/effects';

import recordsSaga from './records/saga';
import usersSaga from './users/saga';
import svcpnsSaga from './svcpns/saga';
import projectSaga from './projects/saga';
import retirementsSaga from './retirements/saga';
import ordersSaga from './orders/saga';
import orderSaga from './order/saga';
import allProjectsSaga from './allProjects/saga';
import emailRecipientsSaga from './emailRecipients/saga';

// TODO: will be deprecated
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';
import calendarSaga from './calendar/saga';
import chatSaga from './chat/saga';
import cryptoSaga from './crypto/saga';
import invoiceSaga from './invoices/saga';
import tasksSaga from './tasks/saga';
import mailsSaga from './mails/saga';
import contactsSaga from './contacts/saga';
import dashboardSaga from './dashboard/saga';
import billingSaga from './billings/saga';
import transmissionLogsSaga from './transmissionLogs/saga';

export default function* rootSaga() {
  yield all([
    fork(allProjectsSaga),
    fork(billingSaga),
    fork(recordsSaga),
    fork(usersSaga),
    fork(svcpnsSaga),
    fork(projectSaga),
    fork(retirementsSaga),
    fork(ordersSaga),
    fork(orderSaga),
    // TODO: will be deprecated
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(emailRecipientsSaga),
    fork(transmissionLogsSaga),
  ]);
}
