import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Form } from 'reactstrap';
import cx from 'classnames';

import { useToggle } from '../../hooks';
import IconButton from './IconButton';
import {
  GeographyFilter,
  OrderStatusFilter,
  RangeOrderDateFilter,
} from './Filters';

const OrdersFilter = ({
  filters,
  availableFilters,
  makeSetFilter,
  clearFilter,
}) => {
  const [isFilterShown, toggleFilterShown] = useToggle();
  const hasFilter = filters && Object.keys(filters).length > 0;

  return (
    <>
      <Row className="mb-3 mt-3">
        <Form className="row row-cols-lg-auto">
          <Col xs={12}>
            <span
              className={cx({
                'red-dot-container': hasFilter,
              })}
            >
              <IconButton
                color="primary"
                icon="bx-slider"
                text="Filter"
                onClick={toggleFilterShown}
              />
            </span>
          </Col>
        </Form>
      </Row>
      {isFilterShown && (
        <>
          <Row>
            <Form className="row row-cols-lg-auto align-items-center">
              <Col className="mb-3" xs={12}>
                <RangeOrderDateFilter
                  filters={filters}
                  makeSetFilter={makeSetFilter}
                />
              </Col>
              <Col className="mb-3" xs={12}>
                <GeographyFilter
                  availableFilters={availableFilters}
                  filters={filters}
                  makeSetFilter={makeSetFilter}
                />
              </Col>
              <Col className="mb-3" xs={12}>
                <OrderStatusFilter
                  availableFilters={availableFilters}
                  filters={filters}
                  makeSetFilter={makeSetFilter}
                />
              </Col>
              <Col className="mb-3" xs={12}>
                <Button color="primary" outline="true" onClick={clearFilter}>
                  <span>Clear filter</span>
                </Button>
              </Col>
            </Form>
          </Row>
        </>
      )}
    </>
  );
};

OrdersFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  availableFilters: PropTypes.object.isRequired,
  makeSetFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
};

export default OrdersFilter;
