import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';
import ChangePasswordForm from './ChangePasswordForm';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const ChangePassword = ({ user, isOpen, toggleModal }) => (
  <StyledModal toggle={toggleModal} isOpen={isOpen}>
    <ModalBody>
      <HeaderRow>
        <Col xs={10}>
          <h4>Change Password</h4>
        </Col>
        <Col xs={2}>
          <CloseButton color="link" onClick={toggleModal}>
            <CloseIcon />
          </CloseButton>
        </Col>
      </HeaderRow>
      <ChangePasswordForm toggleModal={toggleModal} user={user} />
    </ModalBody>
  </StyledModal>
);

ChangePassword.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ChangePassword;
