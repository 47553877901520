import React, { forwardRef } from 'react';

const UserContext = React.createContext({ user: {} });

export const withUser = (Component) =>
  // eslint-disable-next-line react/display-name
  forwardRef((props, ref) => (
    <UserContext.Consumer>
      {(user) => <Component {...props} user={user} ref={ref} />}
    </UserContext.Consumer>
  ));

export default UserContext;
