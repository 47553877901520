import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import cx from 'classnames';
import styled, { createGlobalStyle } from 'styled-components';
import cross from '../../../assets/images/cross.svg';

const ClearButton = styled.span`
  position: absolute;
  top: 8px;
  right: 17px;
  cursor: pointer;
`;

const DatePickerWrapperStyles = createGlobalStyle`
  .react-datepicker__input-container input {
    padding-right: 35px;
  }
`;

const inpuFilter = ({ column, placeholder }) => {
  const SelectorFilter = ({ filters, makeSetFilter }) => {
    const initialFilterValue = filters[column]?.value || null;
    const [startDate, setStartDate] = useState(
      initialFilterValue ? new Date(JSON.parse(initialFilterValue)[0]) : null,
    );
    const [endDate, setEndDate] = useState(
      initialFilterValue ? new Date(JSON.parse(initialFilterValue)[1]) : null,
    );

    useEffect(() => {
      if (initialFilterValue) {
        const [start, end] = JSON.parse(initialFilterValue);
        setStartDate(new Date(start));
        setEndDate(new Date(end));
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    }, [initialFilterValue]);

    const setValue = useCallback(makeSetFilter(column), [
      makeSetFilter,
      column,
    ]);

    const onChange = ([start, end]) => {
      setStartDate(start);
      setEndDate(end);
      if (!start && !end) {
        setValue(null);
      } else if (start && end) {
        const fromDate = moment(start).format('YYYY.MM.DD');
        const toDate = moment(end).format('YYYY.MM.DD');
        setValue(JSON.stringify([fromDate, toDate]));
      }
    };

    const clearInput = () => {
      setStartDate(null);
      setEndDate(null);
      setValue(null);
    };

    return (
      <div className="input-wrapper" style={{ position: 'relative' }}>
        <DatePicker
          id={`${column}-input-id`}
          className={cx('form-control', {
            'filled-input': startDate || endDate,
          })}
          dateFormat="yyyy.MM.dd"
          autoComplete="off"
          onChangeRaw={(e) => e.preventDefault()}
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          showMonthDropdown
          showYearDropdown
          peekNextMonth
          selectsRange
          dropdownMode="select"
          placeholderText={placeholder}
        />
        <DatePickerWrapperStyles />
        {(startDate || endDate) && (
          <ClearButton onClick={clearInput}>
            <img src={cross} alt="clear input" />
          </ClearButton>
        )}
      </div>
    );
  };

  SelectorFilter.propTypes = {
    filters: PropTypes.object.isRequired,
    makeSetFilter: PropTypes.func.isRequired,
  };

  return SelectorFilter;
};

export default inpuFilter;
