import React from 'react';
import { Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from '../../helpers/formatter';

const FormContainer = styled.div`
  font-size: 14px;
  font-weight: 300;

  b {
    font-weight: 500;
  }
`;

const CustomCol = styled.div`
  width: fit-content;

  div {
    padding-top: 16px !important;
  }

  &.w-200 {
    width: 200px;
  }
`;

const BottomRow = styled(Row)`
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 24px;
`;

const SaveButton = styled(Button)`
  width: 82px;
  font-size: 15px;
  border-radius: 6px;
  height: 34px;
  padding: 0;
  font-weight: 400;
`;

const DetailsInfo = ({
  toggleModal,
  data: {
    withStatusNew,
    withStatusReady,
    withStatusDone,
    withStatusInProgress,
    withStatusInvalid,
    withStatusDuplicate,
    withStatusBilled,
    totalOffsetExceptInvalidAndDuplicate,
    totalOffsetRetired,
    countAllExceptInvalidAndDuplicate,
  },
}) => {
  return (
    <FormContainer>
      <Row>
        <b>General info</b>
      </Row>
      <Row className="flex-nowrap">
        <CustomCol className="w-200">
          <div>Total records</div>
        </CustomCol>
        <CustomCol>
          <div>{format(countAllExceptInvalidAndDuplicate)}</div>
        </CustomCol>
      </Row>
      <hr />
      <Row>
        <b>Status summary</b>
      </Row>
      <Row className="flex-nowrap">
        <CustomCol className="w-200">
          <div>&apos;New&apos; records</div>
          <div>&apos;In progress&apos; records</div>
          <div>&apos;Ready&apos; records</div>
          <div>&apos;Done&apos; records</div>
          <div>&apos;Invalid&apos; records</div>
          <div>&apos;Billed&apos; records</div>
          <div>&apos;Duplicate&apos; records</div>
        </CustomCol>
        <CustomCol>
          <div>{format(withStatusNew)}</div>
          <div>{format(withStatusInProgress)}</div>
          <div>{format(withStatusReady)}</div>
          <div>{format(withStatusDone)}</div>
          <div>{format(withStatusInvalid)}</div>
          <div>{format(withStatusBilled)}</div>
          <div>{format(withStatusDuplicate)}</div>
        </CustomCol>
      </Row>
      <hr />
      <Row>
        <b>Offset summary</b>
      </Row>
      <Row className="flex-nowrap">
        <CustomCol className="w-200">
          <div>Total offsets</div>
          <div>Retired offsets</div>
          <div>Total remaining</div>
        </CustomCol>
        <CustomCol>
          <div>{format(totalOffsetExceptInvalidAndDuplicate)}</div>
          <div>{format(totalOffsetRetired)}</div>
          <div>
            {format(totalOffsetExceptInvalidAndDuplicate - totalOffsetRetired)}
          </div>
        </CustomCol>
      </Row>
      <BottomRow>
        <SaveButton onClick={toggleModal} color="primary">
          Close
        </SaveButton>
      </BottomRow>
    </FormContainer>
  );
};

export default React.memo(DetailsInfo);

DetailsInfo.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    withStatusNew: PropTypes.number.isRequired,
    withStatusReady: PropTypes.number.isRequired,
    withStatusDone: PropTypes.number.isRequired,
    withStatusInProgress: PropTypes.number.isRequired,
    withStatusInvalid: PropTypes.number.isRequired,
    withStatusDuplicate: PropTypes.number.isRequired,
    withStatusBilled: PropTypes.number.isRequired,
    totalOffsetExceptInvalidAndDuplicate: PropTypes.number.isRequired,
    totalOffsetRetired: PropTypes.number.isRequired,
    countAllExceptInvalidAndDuplicate: PropTypes.number.isRequired,
  }),
};
