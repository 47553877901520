import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSvcpns } from '../store/svcpns';
import { useQuery } from './query';

export const useSvcpns = (
  initialParams = {
    page: 1,
    pageSize: 50,
    sortingColumn: 'createdAt',
    sortingDirection: 'desc',
  },
) => {
  const [params, updateParams] = useQuery(initialParams);
  const dispatch = useDispatch();

  const knownSvcpns = useSelector((state) => state.svcpns.svcpns.knownSvcpns);
  const isLoading = useSelector(
    (state) => state.svcpns.svcpns.knownSvcpns.isLoading,
  );
  const isOpen = useSelector((state) => state.svcpns.isOpen);

  useEffect(() => {
    dispatch(getSvcpns(params));
  }, [params]);

  const makeSetFilter = useCallback(
    (column) => (value) => {
      const newParams = {
        ...params,
        page: 1,
        [column]: value,
      };
      const keysToClear = [];
      if (!value) keysToClear.push(column);

      updateParams(newParams, keysToClear);
    },
    [params, updateParams],
  );

  return {
    params,
    updateParams,
    makeSetFilter,
    sortableColumns: useSelector(
      (state) => state.svcpns.svcpns.knownSvcpns.params.sortableColumns,
    ),
    filteredCount: useSelector(
      (state) => state.svcpns.svcpns.knownSvcpns.filteredCount,
    ),
    availableFilters: useSelector((state) =>
      state.svcpns.svcpns.knownSvcpns.params.availableFilters.reduce(
        (acc, filter) => {
          acc[filter.column] = filter;
          return acc;
        },
        {},
      ),
    ),
    filters: useSelector((state) =>
      state.svcpns.svcpns.knownSvcpns.params.filters.reduce((acc, filter) => {
        acc[filter.column] = filter;
        return acc;
      }, {}),
    ),
    knownSvcpns,
    isLoading,
    isOpen,
  };
};
