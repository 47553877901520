import React from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ThreeDotsMenu from '../../components/Common/ThreeDotsMenu';
import { useToggle } from '../../hooks';
import EditUnknownSvcpns from './EditUnknownSvcpns';
import { withUser } from '../../context/UserContext';
import SvcpnsOrders from './SvcpnsViewOrders';

const UnknownSvcpnsActionMenu = ({ row, user }) => {
  const [isDropdownOpen, toggleDropdown] = useToggle();
  const [isEditOpen, toggleEditModal] = useToggle();
  const [isModalOpen, toggleOpenModal] = useToggle();

  const enabled = (user && user.role === 'ADMIN') || user.role === 'SALES';

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          disabled={!enabled}
          color="link"
          className="shadow-none"
        >
          <ThreeDotsMenu />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <Button color="link" onClick={toggleEditModal}>
              Edit SVCPN
            </Button>
          </DropdownItem>
          <DropdownItem>
            <Button color="link" onClick={toggleOpenModal}>
              View Orders
            </Button>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <EditUnknownSvcpns
        row={row}
        isOpen={isEditOpen}
        toggleModal={toggleEditModal}
        onClick={toggleDropdown}
      />
      <SvcpnsOrders
        row={row}
        isOpen={isModalOpen}
        toggleModal={toggleOpenModal}
        onClick={toggleDropdown}
      />
    </div>
  );
};

UnknownSvcpnsActionMenu.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withUser(UnknownSvcpnsActionMenu);
