import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'reactstrap';
import AssignToProject from './AssignToProject';
import MoveToBilled from './MoveToBilled';
import OrderInfo from './OrderInfo';
import { withUser } from '../../context/UserContext';
import MultiProcessOrder from './MultiProcessOrder';

const OrderActionButtons = ({
  isOpenAssignModal,
  params,
  selectedOrders,
  user,
  error,
  isOpenBilledModal,
}) => {
  const firstOrder = selectedOrders[0];
  const isSameOrderStatus = selectedOrders.every(
    (order) => order.status === firstOrder.status,
  );
  const isSameOrderRegionName = selectedOrders.every(
    (order) => firstOrder.regionName === order.regionName,
  );

  if (!isSameOrderStatus)
    return (
      <Row className="mt-3">
        <Col className="mb-3" xs={12}>
          <OrderInfo selectedOrders={selectedOrders} />
        </Col>
      </Row>
    );

  return (
    <Row className="mt-3">
      {(user && user.role === 'ADMIN') || user.role === 'SALES' ? (
        <Form className="row row-cols-lg-auto">
          {firstOrder.status === 'READY' && (
            <Col className="mb-3" xs={12}>
              <MultiProcessOrder
                selectedOrders={selectedOrders}
                urlParams={params}
              />
            </Col>
          )}
          {firstOrder.status === 'UNPROCESSED' && (
            <Col className="mb-3" xs={12}>
              <AssignToProject
                params={params}
                isOpenAssignModal={isOpenAssignModal}
                selectedOrders={selectedOrders}
              />
            </Col>
          )}
          {firstOrder.status === 'PROCESSED' && isSameOrderRegionName && (
            <Col className="mb-3" xs={12}>
              <MoveToBilled
                isOpenBilledModal={isOpenBilledModal}
                selectedOrders={selectedOrders}
                error={error}
              />
            </Col>
          )}
          <Col className="mb-3" xs={12}>
            <OrderInfo selectedOrders={selectedOrders} />
          </Col>
        </Form>
      ) : null}
    </Row>
  );
};

OrderActionButtons.propTypes = {
  params: PropTypes.object.isRequired,
  isOpenAssignModal: PropTypes.bool.isRequired,
  isOpenBilledModal: PropTypes.bool.isRequired,
  selectedOrders: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.string,
};

export default withUser(OrderActionButtons);
