import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import styled from 'styled-components';
import cx from 'classnames';
import NoDataIndication from './NoDataIndication';
import Overlay from './Overlay';

const Wrapper = styled.div`
  &.table-word-break {
    tbody {
      word-break: break-all;
    }
  }
`;

export const BasicTable = ({
  columns,
  data,
  isLoading,
  headerWrapperClasses,
  wordBreak,
}) => {
  return (
    <Wrapper
      className={cx('mt-4', {
        'table-responsive': !wordBreak,
        'table-word-break': wordBreak,
      })}
    >
      {data && (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          loading={isLoading}
          bordered={false}
          overlay={Overlay}
          noDataIndication={NoDataIndication}
          headerWrapperClasses={headerWrapperClasses}
        />
      )}
    </Wrapper>
  );
};

BasicTable.propTypes = {
  data: PropTypes.array,
  headerWrapperClasses: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  wordBreak: PropTypes.bool,
};
