import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '../store/projects';

export const useProjects = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.projects.projects.projects);
  const isLoading = useSelector((state) => state.projects.isLoading);
  const isOpen = useSelector((state) => state.projects.isOpen);

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  return {
    data,
    isLoading,
    isOpen,
  };
};
