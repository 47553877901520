import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { useToggle } from '../../../hooks';
import { getOrder } from '../../../store/order';
import CloseIcon from '../../../components/Common/CloseIcon';
import DetailsInfo from './DetailsInfo';

const StyledButton = styled(Button)`
  display: inline-block;
  width: 122px;
  height: 29px;
  margin-right: 5px;
  padding: 4px 20px;
`;

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 450px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const ViewDetails = ({ orderData }) => {
  const [isOpen, toggleModal] = useToggle();

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrder({ id }));
  }, [isOpen]);

  return (
    <>
      <StyledButton color="primary" onClick={toggleModal}>
        <span>View Details</span>
      </StyledButton>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>View Details</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <DetailsInfo toggleModal={toggleModal} data={orderData} />
        </ModalBody>
      </StyledModal>
    </>
  );
};

ViewDetails.propTypes = {
  orderData: PropTypes.object,
};

export default React.memo(ViewDetails);
