import * as actions from './actions';

export const getOrders = actions.getOrders.run;
export const getLastOrders = actions.getLastOrders.run;
export const moveToBilled = actions.moveToBilled.run;
export const processOrder = actions.processOrder.run;
export const toggleAssignOrdersModal = actions.toggleAssignOrdersModal.run;
export const toggleBilledOrdersModal = actions.toggleBilledOrdersModal.run;
export const assignToProject = actions.assignToProject.run;
export const clearBilledError = actions.clearBilledError.run;
export const setRowDataArray = actions.setRowDataArray.run;
