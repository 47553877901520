import { asyncActionType } from '../common';

export const TOGGLE_ADD_PROJECT = asyncActionType('TOGGLE_ADD_PROJECT');
export const GET_PROJECTS = asyncActionType('GET_PROJECTS');
export const FIND_RELATED_RETIREMENT = asyncActionType(
  'FIND_RELATED_RETIREMENT',
);
export const CLEAR_RELATED_RETIREMENT = asyncActionType(
  'CLEAR_RELATED_RETIREMENT',
);
