import urls from './urls';
import client from '../utils/api';

const loginUser = (body) => client.post(urls.users.logIn(), body);
const sendSMSCode = (body) => client.post(urls.users.sendSMSCode(), body);
const verifySMSCode = (body) => client.post(urls.users.verifySMSCode(), body);
const registerOTPApp = (body) => client.post(urls.users.registerOTPApp(), body);
const verifyOTPApp = (body) => client.post(urls.users.verifyOTPApp(), body);
const verifyOTPCode = (body) => client.post(urls.users.verifyOTPCode(), body);
const logOutUser = () => client.post(urls.users.logOut());
const createUser = (params) => client.post(urls.users.createUser(), params);
const deleteUser = ({ id, user }) =>
  client.del(urls.users.deleteUser(id), { id, user });
const getUsers = () => client.get(urls.users.getUsers());
const updateUser = ({ id, email, role, phoneNumber }) =>
  client.put(urls.users.updateUser(id), { email, role, phoneNumber });
const updateUserPassword = ({ id, password }) =>
  client.put(urls.users.updateUserPassword(id), { password });
const sendResetEmail = ({ email }) =>
  client.post(urls.users.sendPasswordResetEmail(), { email });
const checkPassResetToken = ({ email, token }) =>
  client.get(urls.users.checkPassResetToken(), { email, token });
const updatePassword = ({ email, password }) =>
  client.post(urls.users.updatePassword(), { email, password });
const resetSecondFactor = ({ id }) =>
  client.post(urls.users.resetSecondFactor(), { id });
export default {
  loginUser,
  sendSMSCode,
  verifySMSCode,
  registerOTPApp,
  verifyOTPApp,
  verifyOTPCode,
  logOutUser,
  createUser,
  deleteUser,
  getUsers,
  updateUser,
  updateUserPassword,
  sendResetEmail,
  checkPassResetToken,
  updatePassword,
  resetSecondFactor,
};
