import React from 'react';
import PropTypes from 'prop-types';

const ButtonLoader = ({ isShown, ...props }) =>
  isShown && (
    <div className="spinner-chase button" {...props}>
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
    </div>
  );

ButtonLoader.defaultProps = {
  isShown: false,
};

ButtonLoader.propTypes = {
  isShown: PropTypes.bool.isRequired,
};

export default ButtonLoader;
