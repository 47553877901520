import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';

const StyledModal = styled(Modal)`
  width: 430px;
`;

const MainHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const TitleCol = styled(Col)`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  width: 113px;
  height: 36px;
  padding: 0px;
  margin-top: 7px;
  font-size: 18px;
  border-radius: 8px;
`;

const TotalRow = styled(Row)`
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
`;

const TotalsHeader = styled(ModalHeader)`
  padding: 16px 70px 0 32px;
  border-bottom: 1px #d3d3d3 solid;
  font-weight: bold;

  .modal-title {
    width: 100%;
    padding: 0 28px;
  }
`;
const Totals = styled.div`
  padding: 10px 0px 0 60px;
`;

const SvcpnsOrders = ({ row, isOpen, toggleModal }) => {
  return (
    <>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <MainHeader>
          <Row>
            <TitleCol>Orders with unknown SVCPN {row.code} </TitleCol>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </MainHeader>
        <TotalsHeader>
          <TotalRow>
            <Col xs={8}>
              <h5>Order date</h5>
            </Col>
            <Col xs={4}>
              <h5>Geography</h5>
            </Col>
          </TotalRow>
        </TotalsHeader>
        <Totals>
          {row?.orders?.map((order) => (
            <TotalRow key={order.orderId}>
              <Col xs={6}>
                {moment.utc(order.orderedAt).format('YYYY.MM.DD')}
              </Col>
              <Col xs={6}>{order.geography}</Col>
            </TotalRow>
          ))}
        </Totals>
        <ModalBody>
          <BottomRow>
            <StyledButton onClick={toggleModal} color="primary">
              Close
            </StyledButton>
          </BottomRow>
        </ModalBody>
      </StyledModal>
    </>
  );
};
export default SvcpnsOrders;

SvcpnsOrders.propTypes = {
  row: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
