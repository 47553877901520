import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import cx from 'classnames';
import styled from 'styled-components';
import cross from '../../../assets/images/cross.svg';

const ClearButton = styled.span`
  position: absolute;
  top: 8px;
  right: 17px;
  cursor: pointer;
`;

const inpuFilter = ({ column, placeholder }) => {
  const SelectorFilter = ({ filters, makeSetFilter }) => {
    const initialFilterValue = filters[column]?.value || '';
    const [value, setValue] = useState(initialFilterValue);

    const updateFilter = useCallback(makeSetFilter(column), [
      makeSetFilter,
      column,
    ]);

    const handleChange = (date) => {
      const newValue = moment(date).format('YYYY.MM.DD');
      setValue(newValue);
      updateFilter(newValue);
    };

    const clearInput = () => {
      setValue('');
      updateFilter('');
    };

    return (
      <div className="input-wrapper" style={{ position: 'relative' }}>
        <DatePicker
          selected={(value && new Date(value)) || null}
          id={`${column}-input-id`}
          value={value}
          onChange={handleChange}
          className={cx('form-control', { 'filled-input': value })}
          autoComplete="off"
          placeholderText={placeholder}
          peekNextMonth
          onChangeRaw={(e) => e.preventDefault()}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          style={{ paddingRight: '35px' }}
        />
        {value && (
          <ClearButton onClick={clearInput}>
            <img src={cross} alt="clear input" />
          </ClearButton>
        )}
      </div>
    );
  };

  SelectorFilter.propTypes = {
    filters: PropTypes.object.isRequired,
    makeSetFilter: PropTypes.func.isRequired,
  };

  return SelectorFilter;
};

export default inpuFilter;
