import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert } from 'reactstrap';
import { useToggle } from '../../../hooks';

const WarningAlert = styled(Alert)`
  width: 690px;
  max-width: 690px;
  height: 37px;
  margin: 10px 0 0 12px;
  padding: 0 0 0 10px;
  line-height: 37px;

  .btn-close {
    padding: 0.75rem 0.75rem;
  }
`;

const linkToSvcpns = () => (
  <a className="alert-link" href="/svcpns">
    Click here
  </a>
);

const UnknownSvcpnWarning = ({ warning }) => {
  const [isOpen, toggleModal] = useToggle(warning);

  useEffect(() => {
    if (warning !== isOpen) toggleModal();
  }, [warning]);

  return (
    <WarningAlert
      id="warning-message"
      color="danger"
      isOpen={isOpen}
      toggle={toggleModal}
    >
      Warning about Unknown SVC PN in current Order. {linkToSvcpns()} to proceed
      to adding new SVCPN.
    </WarningAlert>
  );
};

UnknownSvcpnWarning.defaultProps = {
  warning: false,
};

UnknownSvcpnWarning.propTypes = {
  warning: PropTypes.bool,
};

export default React.memo(UnknownSvcpnWarning);
