import React from 'react';
import styled from 'styled-components';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CloseIcon from '../../components/Common/CloseIcon';
import NewSvcpnForm from './NewSvcpnForm';
import { toggleAddSvcpnModal } from '../../store/svcpns/index';

const StyledButton = styled(Button)`
  display: inline-block;
  width: 150px;
`;

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const AddNewSvcpn = ({ isOpen }) => {
  const dispatch = useDispatch();
  const toggleModal = () => dispatch(toggleAddSvcpnModal(!isOpen));

  return (
    <>
      <StyledButton color="primary" onClick={toggleModal}>
        <span>+ Add New SVCPN</span>
      </StyledButton>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>Add New SVCPN</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <NewSvcpnForm toggleModal={toggleModal} />
        </ModalBody>
      </StyledModal>
    </>
  );
};

AddNewSvcpn.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default AddNewSvcpn;
