import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from '../../components/Common/CloseIcon';

import { clearBillingsError, getBillingSummary } from '../../store/billings';
import { withUser } from '../../context/UserContext';
import EditBillngSummary from './EditBillngSummaryForm';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const EditBillngSummaryModal = ({ row, isOpen, toggleModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearBillingsError());
  }, [isOpen]);

  const onClose = () => {
    toggleModal();
    dispatch(
      getBillingSummary({
        page: 1,
        pageSize: 50,
        sortingColumn: 'createdAt',
        sortingDirection: 'desc',
      }),
    );
  };

  return (
    <>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>Edit Billing Summary</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={onClose}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <EditBillngSummary row={row} toggleModal={toggleModal} />
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default withUser(EditBillngSummaryModal);

EditBillngSummaryModal.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
