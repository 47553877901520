import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from './query';
import { getBillingSummary } from '../store/billings';

export const useBillings = (
  initialParams = {
    page: 1,
    pageSize: 50,
    sortingColumn: 'createdAt',
    sortingDirection: 'desc',
  },
) => {
  const [params, updateParams] = useQuery(initialParams);
  const dispatch = useDispatch();

  const billingsData = useSelector((state) => state.billings.billings.results);
  const count = useSelector((state) => state.billings.billings.count);
  const isLoading = useSelector((state) => state.billings.isLoading);

  useEffect(() => {
    dispatch(getBillingSummary(params));
  }, [params]);

  return {
    sortableColumns: useSelector(
      (state) => state.billings.billings.params.sortableColumns,
    ),
    filteredCount: useSelector(
      (state) => state.billings.billings.filteredCount,
    ),
    billingsData,
    count,
    updateParams,
    params,
    isLoading,
  };
};
