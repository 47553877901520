import { takeEvery } from 'redux-saga/effects';
import { getTransmissionLogs } from './actions';
import { GET_TRANSMISSION_LOGS } from './actionTypes';
import { transmissionLogs } from '../../api';
import { asyncSaga } from '../common';

export default function* transmissionLogsSaga() {
  yield takeEvery(
    GET_TRANSMISSION_LOGS.RUN,
    asyncSaga(
      (action) => transmissionLogs.getTransmissionLogs(action),
      getTransmissionLogs,
    ),
  );
}
