import urls from './urls';
import client from '../utils/api';

const getOrders = (query = {}) => client.get(urls.orders.getOrders(), query);
const getOrder = ({ id }) => client.get(urls.orders.getOrder(id));
const moveToBilled = (body) => client.post(urls.orders.moveToBilled(), body);
const processOrder = (body) => client.post(urls.orders.processOrder(), body);
const assignToProject = ({ id, params, queryParams = {} }) =>
  client.post(urls.projects.assignToProject(id), params, queryParams);
const getLastOrders = () => client.post(urls.orders.lastOrders());

export default {
  getOrders,
  getOrder,
  moveToBilled,
  processOrder,
  assignToProject,
  getLastOrders,
};
