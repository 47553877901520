import { asyncAction } from '../common';
import {
  CREATE_USER,
  DELETE_USER,
  GET_USERS,
  LOGIN_USER,
  SEND_SMS_CODE,
  VERIFY_SMS_CODE,
  REGISTER_OTP_APP,
  VERIFY_OTP_APP,
  VERIFY_OTP_CODE,
  LOGOUT_USER,
  UPDATE_USER_DATA,
  UPDATE_USER_PASSWORD,
  UPDATE_PASSWORD,
  TOGGLE_USER_MODAL,
  CHECK_TOKEN,
  SEND_PASSWORD_RESET_EMAIL,
  STORE_REMEMBER_ME,
  CLEAR_USER_ERROR,
  RESET_SECOND_FACTOR,
} from './actionTypes';

export const loginUser = asyncAction(LOGIN_USER);
export const logOutUser = asyncAction(LOGOUT_USER);
export const getUsers = asyncAction(GET_USERS);
export const createUser = asyncAction(CREATE_USER);
export const deleteUser = asyncAction(DELETE_USER);
export const updateUser = asyncAction(UPDATE_USER_DATA);
export const updateUserPassword = asyncAction(UPDATE_USER_PASSWORD);
export const updatePassword = asyncAction(UPDATE_PASSWORD);
export const toggleUserModal = asyncAction(TOGGLE_USER_MODAL);
export const sendPasswordResetEmail = asyncAction(SEND_PASSWORD_RESET_EMAIL);
export const checkToken = asyncAction(CHECK_TOKEN);
export const sendSMSCode = asyncAction(SEND_SMS_CODE);
export const verifySMSCode = asyncAction(VERIFY_SMS_CODE);
export const registerOTPApp = asyncAction(REGISTER_OTP_APP);
export const verifyOTPApp = asyncAction(VERIFY_OTP_APP);
export const verifyOTPCode = asyncAction(VERIFY_OTP_CODE);
export const storeRememberMe = asyncAction(STORE_REMEMBER_ME);
export const clearUserError = asyncAction(CLEAR_USER_ERROR);
export const resetSecondFactor = asyncAction(RESET_SECOND_FACTOR);
