import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ isShown, local = false }) =>
  isShown && (
    <div id="preloader" className={local ? 'local' : ''}>
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
        </div>
      </div>
    </div>
  );

Loader.defaultProps = {
  isShown: false,
};

Loader.propTypes = {
  isShown: PropTypes.bool.isRequired,
  local: PropTypes.bool,
};

export default Loader;
