import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CustomSelect } from './CustomSelect';
import { editSvcpn } from '../../store/svcpns';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  display: grid;
`;

const SaveButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const statusField = [
  { value: false, label: 'Actual' },
  { value: true, label: 'Outdated' },
];

const EditSvcpnForm = ({
  svcpn: { id, code, description, offset, isOutdated, editable, product },
  toggleModal,
}) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.svcpns.error);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code,
      description,
      offset,
      isOutdated,
      product,
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .max(255)
        .required('Please Enter SVCPN')
        .matches(
          /^[- .+()]*[A-Za-z0-9][- .+()A-Za-z0-9]*$/,
          'Field must contain letters and numbers',
        ),
      product: Yup.string()
        .nullable()
        .matches(
          /^[- .+()]*[A-Za-z0-9][- .+()A-Za-z0-9]*$/,
          'Field must contain letters and numbers',
        ),
      description: Yup.string()
        .required('Please Enter SVCPN Discription')
        .matches(
          /^[^\s].+[^\s]*[- .+()]*[A-Za-z0-9][- .+()A-Za-z0-9]*$/,
          'Field must contain letters and numbers',
        ),
      offset: Yup.string()
        .transform((field) => (field === null ? '' : field))
        .required('Please Enter Offset')
        .max(9)
        .matches(/^\d+(\.\d+)*$/, 'Field must contain numbers'),
    }),
    onSubmit: (params) => {
      if (params.product === null || params.product === '') {
        dispatch(
          editSvcpn({
            params: {
              code: params.code,
              description: params.description,
              offset: params.offset,
              isOutdated: params.isOutdated,
              product: null,
            },
            id,
          }),
        );
      } else {
        dispatch(editSvcpn({ params, id }));
      }
      toggleModal();
    },
  });

  return (
    <FormContainer>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {error ? <Alert color="danger">{error}</Alert> : null}
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-label">
                SVCPN
                <Asterix>*</Asterix>
              </Label>
              <Input
                disabled
                name="code"
                className="form-control"
                placeholder="SVCPN No"
                type="code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.code || ''}
                invalid={!!(validation.touched.code && validation.errors.code)}
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">Product</Label>
              <div className="input-group">
                <Input
                  name="product"
                  value={validation.values.product}
                  type="product"
                  placeholder="Product"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(validation.touched.product && validation.errors.product)
                  }
                />
                {validation.touched.product && validation.errors.product ? (
                  <FormFeedback type="invalid">
                    {validation.errors.product}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                SVCPN Description
                <Asterix>*</Asterix>
              </Label>
              <div className="input-group">
                <Input
                  name="description"
                  value={validation.values.description || ''}
                  type="description"
                  placeholder="SVCPN Description"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(
                      validation.touched.description &&
                      validation.errors.description
                    )
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                Offset (tonne)
                <Asterix>*</Asterix>
              </Label>
              <div className="input-group">
                {editable ? (
                  <Input
                    name="offset"
                    value={validation.values.offset || ''}
                    type="offset"
                    placeholder="Offset"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      !!(validation.touched.offset && validation.errors.offset)
                    }
                  />
                ) : (
                  <Input
                    disabled
                    name="offset"
                    value={validation.values.offset || ''}
                    type="offset"
                    placeholder="Offset"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      !!(validation.touched.offset && validation.errors.offset)
                    }
                  />
                )}
                {validation.touched.offset && validation.errors.offset ? (
                  <FormFeedback type="invalid">
                    {validation.errors.offset}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Status</Label>
              <CustomSelect
                onChange={(value) =>
                  validation.setFieldValue('isOutdated', value.value)
                }
                value={validation.values.isOutdated}
                options={statusField}
              />
            </FormGroup>
          </Col>
        </Row>

        <BottomRow>
          <Col>
            <SaveButton type="submit" color="primary">
              Save
            </SaveButton>
          </Col>
        </BottomRow>
      </Form>
    </FormContainer>
  );
};

export default EditSvcpnForm;

EditSvcpnForm.propTypes = {
  svcpn: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    offset: PropTypes.number.isRequired,
    isOutdated: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    product: PropTypes.string.isRequired,
  }).isRequired,
  toggleModal: PropTypes.func.isRequired,
};
