import React from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ThreeDotsMenu from '../../components/Common/ThreeDotsMenu';
import { useToggle } from '../../hooks';
import EditProject from './EditProject';
import DeleteProject from './DeleteProject';
import { withUser } from '../../context/UserContext';

const ActionMenu = ({ row, user }) => {
  const [isDropdownOpen, toggleDropdown] = useToggle();
  const [isEditOpen, toggleEditModal] = useToggle();
  const [isDeleteOpen, toggleDeleteModal] = useToggle();

  const enabled =
    (user && user.role === 'ADMIN' && row.deletedAt === null) ||
    (user.role === 'SALES' && row.deletedAt === null);

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          disabled={!enabled}
          color="link"
          className="shadow-none"
        >
          <ThreeDotsMenu />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <Button color="link" onClick={toggleEditModal}>
              Edit Project
            </Button>
          </DropdownItem>
          <DropdownItem>
            <Button color="link" onClick={toggleDeleteModal}>
              <span>Delete Project</span>
            </Button>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <EditProject
        isOpen={isEditOpen}
        toggleModal={toggleEditModal}
        onClick={toggleDropdown}
        project={row}
      />
      <DeleteProject
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        onClick={toggleDropdown}
        project={row}
      />
    </div>
  );
};

ActionMenu.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withUser(ActionMenu);
