import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';
import EditProjectForm from './EditProjectForm';
import { clearProjectError } from '../../store/allProjects';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const EditProject = ({ project, isOpen, toggleModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearProjectError());
  }, [isOpen]);

  return (
    <StyledModal toggle={toggleModal} isOpen={isOpen}>
      <ModalBody>
        <HeaderRow>
          <Col xs={10}>
            <h4>Edit Project</h4>
          </Col>
          <Col xs={2}>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Col>
        </HeaderRow>
        <EditProjectForm project={project} />
      </ModalBody>
    </StyledModal>
  );
};

EditProject.propTypes = {
  project: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default EditProject;
