import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useUsers } from '../../hooks';
import { USER_ROLES } from '../../constants/users';
import { CustomSelect } from './CustomSelect';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  display: grid;
  width: 100%;
`;

const SaveButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const EditUserForm = ({
  toggleModal,
  user: { id, email, role, phoneNumber },
}) => {
  const { data, editUser, error } = useUsers();
  const userEmails = data
    .map((user) => user.email)
    .filter((userEmail) => userEmail !== email);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email,
      phoneNumber: phoneNumber || '',
      role,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required('Please Enter User Email')
        .notOneOf(userEmails, 'Email Has Already Been Taken'),
      phoneNumber: Yup.string()
        .matches(
          /^\+?\d{10,15}$/,
          'Enter a valid phone number with country code',
        )
        .required('Phone Number is required'),
      role: Yup.string().required('Please Enter User Role'),
    }),
    onSubmit: (params) => {
      const {
        email: newEmail,
        phoneNumber: newPhoneNumber,
        role: newRole,
      } = params;
      editUser({
        email: newEmail,
        id,
        role: newRole || role,
        phoneNumber: newPhoneNumber,
      });
      toggleModal();
    },
  });

  return (
    <FormContainer>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {error ? <Alert color="danger">{error}</Alert> : null}
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-label">
                Email
                <Asterix>*</Asterix>
              </Label>
              <Input
                name="email"
                className="form-control"
                placeholder="Enter Email"
                type="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ''}
                invalid={
                  !!(validation.touched.email && validation.errors.email)
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-label">
                Phone Number
                <Asterix>*</Asterix>
              </Label>
              <Input
                name="phoneNumber"
                className="form-control"
                placeholder="Enter Phone Number"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.phoneNumber || ''}
                invalid={
                  !!(
                    validation.touched.phoneNumber &&
                    validation.errors.phoneNumber
                  )
                }
              />
              {validation.touched.phoneNumber &&
              validation.errors.phoneNumber ? (
                <FormFeedback type="invalid">
                  {validation.errors.phoneNumber}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                Role
                <Asterix>*</Asterix>
              </Label>
              <CustomSelect
                onChange={(value) =>
                  validation.setFieldValue('role', value.value)
                }
                onBlur={validation.handleBlur}
                value={validation.values.role}
                options={USER_ROLES}
                invalid={!!(validation.touched.role && validation.errors.role)}
              />
            </FormGroup>
          </Col>
        </Row>
        <BottomRow>
          <Col>
            <SaveButton type="submit" color="primary">
              Save
            </SaveButton>
          </Col>
        </BottomRow>
      </Form>
    </FormContainer>
  );
};

EditUserForm.propTypes = {
  user: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default EditUserForm;
