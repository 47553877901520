import { makeReducer } from '../common';
import {
  GET_ORDERS,
  MOVE_TO_BILLED,
  PROCESS_ORDER,
  TOGGLE_ASSIGN_ORDERS_MODAL,
  ASSIGN_TO_PROJECT,
  GET_LAST_ORDERS,
  CLEAR_BILLED_ERROR,
  TOGGLE_BILLED_ORDERS_MODAL,
  SET_ROW_DATA_ARRAY,
} from './actionTypes';

export default makeReducer(
  {
    [GET_ORDERS.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [GET_ORDERS.SUCCESS]: (state, { payload }) => ({
      ...state,
      orders: payload,
      isLoading: false,
    }),
    [GET_ORDERS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [GET_LAST_ORDERS.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [GET_LAST_ORDERS.SUCCESS]: (state, { payload }) => ({
      ...state,
      orders: payload,
      isLoading: false,
    }),
    [GET_LAST_ORDERS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [MOVE_TO_BILLED.RUN]: (state) => ({
      ...state,
    }),
    [MOVE_TO_BILLED.SUCCESS]: (state, { payload: { data } }) => {
      const updatedOrders = state.orders.results.map((el) => {
        const updatedOrder = data.data.orders.find(
          (order) => order.id === el.id,
        );
        return el.id === updatedOrder?.id
          ? { ...el, status: updatedOrder.status }
          : el;
      });

      return {
        ...state,
        orders: { ...state.orders, results: updatedOrders },
        error: null,
      };
    },
    [MOVE_TO_BILLED.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [PROCESS_ORDER.RUN]: (state) => ({
      ...state,
    }),
    [PROCESS_ORDER.SUCCESS]: (state, { payload: { params } }) => {
      const updatedOrders = state.orders.results.map((el) => {
        const updatedOrder = params.orderIds.find((order) => order === el.id);
        return el.id === updatedOrder ? { ...el, status: 'PROCESSED' } : el;
      });

      return {
        ...state,
        orders: { ...state.orders, results: updatedOrders },
        error: null,
        rowDataArray: [],
      };
    },
    [PROCESS_ORDER.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [TOGGLE_ASSIGN_ORDERS_MODAL.RUN]: (state) => ({
      ...state,
      isOpenAssignModal: !state.isOpenAssignModal,
      error: null,
    }),
    [TOGGLE_ASSIGN_ORDERS_MODAL.SUCCESS]: (state) => ({
      ...state,
      isOpenAssignModal: false,
      isLoading: false,
    }),
    [TOGGLE_ASSIGN_ORDERS_MODAL.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpenAssignModal: false,
      error,
    }),
    [TOGGLE_BILLED_ORDERS_MODAL.RUN]: (state) => ({
      ...state,
      isOpenBilledModal: !state.isOpenBilledModal,
      error: null,
    }),
    [TOGGLE_BILLED_ORDERS_MODAL.SUCCESS]: (state) => ({
      ...state,
      isOpenBilledModal: false,
      isLoading: false,
    }),
    [TOGGLE_BILLED_ORDERS_MODAL.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpenBilledModal: false,
      error,
    }),
    [ASSIGN_TO_PROJECT.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [ASSIGN_TO_PROJECT.SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      },
    ) => ({
      ...state,
      orders: data,
      error: null,
      isLoading: false,
    }),
    [ASSIGN_TO_PROJECT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
      isLoading: false,
    }),
    [CLEAR_BILLED_ERROR.RUN]: (state) => ({
      ...state,
      error: null,
    }),
    [SET_ROW_DATA_ARRAY.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [SET_ROW_DATA_ARRAY.SUCCESS]: (state, payload) => ({
      ...state,
      isLoading: false,
      rowDataArray: payload.payload,
    }),
    [SET_ROW_DATA_ARRAY.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
  },
  {
    orders: {
      results: [],
      filteredCount: null,
      count: null,
      params: {
        sortableColumns: [],
        availableFilters: [],
        filters: [],
      },
    },
    error: null,
    isLoading: false,
    isOpenAssignModal: false,
    isOpenBilledModal: false,
    rowDataArray: [],
  },
);
