import { takeEvery, call, put } from 'redux-saga/effects';
import { getProjects, findRelatedRetirement } from './actions';
import { GET_PROJECTS, FIND_RELATED_RETIREMENT } from './actionTypes';
import { projects } from '../../api';

function* getProjectsSaga({ payload: params }) {
  try {
    const response = yield call(projects.getProjects, params);
    yield put(getProjects.success(response.data.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

function* findRelatedRetirementSaga({ payload: query }) {
  try {
    const response = yield call(projects.findRelatedRetirement, query);
    const { data } = response.data;
    yield put(findRelatedRetirement.success({ data }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default function* projectSaga() {
  yield takeEvery(GET_PROJECTS.RUN, getProjectsSaga);
  yield takeEvery(FIND_RELATED_RETIREMENT.RUN, findRelatedRetirementSaga);
}
