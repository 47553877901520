import { makeReducer } from '../common';
import {
  GET_SVCPNS,
  GET_UNKNOWN_SVCPNS,
  ADD_SVCPN,
  TOGGLE_ADD_SVCPN,
  EDIT_SVCPN,
  CLEAR_EDIT_SVCPN_ERROR,
  EXPORT_SVCPN_CSV,
} from './actionTypes';

export default makeReducer(
  {
    [GET_SVCPNS.RUN]: (state) => ({
      ...state,
      svcpns: {
        ...state.svcpns,
        knownSvcpns: {
          ...state.svcpns.knownSvcpns,
          isLoading: true,
        },
      },
      error: null,
    }),
    [GET_SVCPNS.SUCCESS]: (state, { payload }) => ({
      ...state,
      svcpns: {
        ...state.svcpns,
        knownSvcpns: {
          ...payload,
          isLoading: false,
        },
      },
    }),
    [GET_SVCPNS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [GET_UNKNOWN_SVCPNS.RUN]: (state) => ({
      ...state,
      svcpns: {
        ...state.svcpns,
        unknownSvcpns: {
          ...state.svcpns.unknownSvcpns,
          isLoading: true,
        },
      },
      error: null,
    }),
    [GET_UNKNOWN_SVCPNS.SUCCESS]: (state, { payload }) => ({
      ...state,
      svcpns: {
        ...state.svcpns,
        unknownSvcpns: {
          ...payload,
          isLoading: false,
        },
      },
    }),
    [GET_UNKNOWN_SVCPNS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      svcpns: {
        ...state.svcpns,
        unknownSvcpns: {
          ...state.svcpns.unknownSvcpns,
          isLoading: false,
        },
      },
      error,
    }),
    [TOGGLE_ADD_SVCPN.RUN]: (state) => ({
      ...state,
      isOpen: !state.isOpen,
      error: null,
    }),
    [TOGGLE_ADD_SVCPN.SUCCESS]: (state) => ({
      ...state,
      isOpen: false,
      isLoading: false,
    }),
    [TOGGLE_ADD_SVCPN.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpen: false,
      error,
    }),
    [ADD_SVCPN.RUN]: (state) => ({
      ...state,
      error: null,
    }),
    [ADD_SVCPN.SUCCESS]: (state, { payload: { svcpn } }) => ({
      ...state,
      svcpns: {
        ...state.svcpns,
        knownSvcpns: {
          ...state.svcpns.knownSvcpns,
          results: [svcpn, ...state.svcpns.knownSvcpns.results],
        },
      },
      error: null,
    }),
    [ADD_SVCPN.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [EDIT_SVCPN.RUN]: (state) => ({
      ...state,
      error: null,
    }),
    [EDIT_SVCPN.SUCCESS]: (state, { payload: { svcpn } }) => {
      const updatedUnknownSvcpns = state.svcpns.unknownSvcpns.results.filter(
        (el) => el.id !== svcpn.id,
      );

      const isUnknownUpdated =
        updatedUnknownSvcpns.length < state.svcpns.unknownSvcpns.results.length;

      return {
        ...state,
        svcpns: {
          ...state.svcpns,
          knownSvcpns: {
            ...state.svcpns.knownSvcpns,
            results: isUnknownUpdated
              ? [svcpn, ...state.svcpns.knownSvcpns.results]
              : state.svcpns.knownSvcpns.results.map((el) =>
                  el.id === svcpn.id ? svcpn : el,
                ),
          },
          unknownSvcpns: {
            ...state.svcpns.unknownSvcpns,
            results: isUnknownUpdated
              ? updatedUnknownSvcpns
              : state.svcpns.unknownSvcpns.results.map((el) =>
                  el.id === svcpn.id ? svcpn : el,
                ),
          },
        },
        error: null,
      };
    },
    [EDIT_SVCPN.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [CLEAR_EDIT_SVCPN_ERROR.RUN]: (state) => ({
      ...state,
      error: null,
    }),
    [EXPORT_SVCPN_CSV.RUN]: (state) => ({
      ...state,
    }),
    [EXPORT_SVCPN_CSV.SUCCESS]: (state) => ({
      ...state,
    }),
    [EXPORT_SVCPN_CSV.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
  },

  {
    svcpns: {
      knownSvcpns: {
        results: [],
        params: {
          sortableColumns: [],
          availableFilters: [],
          filters: [],
        },
        filteredCount: null,
        count: null,
        isLoading: false,
      },
      unknownSvcpns: {
        results: [],
        params: {
          sortableColumns: [],
          availableFilters: [],
          filters: [],
        },
        filteredCount: null,
        count: null,
        isLoading: false,
      },
    },
    isOpen: false,
    error: null,
  },
);
