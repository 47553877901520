import { makeReducer } from '../common';
import {
  GET_ALL_PROJECTS,
  EDIT_PROJECT,
  DELETE_PROJECT,
  TOGGLE_ADD_PROJECT,
  ADD_PROJECT,
  EXPORT_PROJECT_CSV,
  CLEAR_PROJECT_ERROR,
} from './actionTypes';

export default makeReducer(
  {
    [GET_ALL_PROJECTS.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [GET_ALL_PROJECTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      allProjects: payload,
      isLoading: false,
    }),
    [GET_ALL_PROJECTS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [ADD_PROJECT.RUN]: (state) => ({
      ...state,
    }),
    [ADD_PROJECT.SUCCESS]: (state, { payload: project }) => ({
      ...state,
      allProjects: {
        ...state.allProjects,
        results: [project, ...state.allProjects.results],
      },
      error: null,
    }),
    [ADD_PROJECT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [EDIT_PROJECT.RUN]: (state) => ({
      ...state,
    }),
    [EDIT_PROJECT.SUCCESS]: (state, { payload: { project } }) => ({
      ...state,
      allProjects: {
        ...state.allProjects,
        results: state.allProjects.results.map((row) =>
          row.id === project.id
            ? { ...row, ...project, date: new Date() }
            : row,
        ),
      },
      error: null,
    }),
    [EDIT_PROJECT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [DELETE_PROJECT.RUN]: (state) => ({
      ...state,
    }),
    [DELETE_PROJECT.SUCCESS]: (state, { payload: { id } }) => ({
      ...state,
      allProjects: {
        ...state.allProjects,
        results: state.allProjects.results.filter((row) => row.id !== id),
      },
    }),
    [DELETE_PROJECT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [TOGGLE_ADD_PROJECT.RUN]: (state) => ({
      ...state,
      isOpen: !state.isOpen,
      error: null,
    }),
    [TOGGLE_ADD_PROJECT.SUCCESS]: (state) => ({
      ...state,
      isOpen: false,
      isLoading: false,
    }),
    [TOGGLE_ADD_PROJECT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpen: false,
      error,
    }),
    [EXPORT_PROJECT_CSV.RUN]: (state) => ({
      ...state,
    }),
    [EXPORT_PROJECT_CSV.SUCCESS]: (state) => ({
      ...state,
    }),
    [EXPORT_PROJECT_CSV.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [CLEAR_PROJECT_ERROR.RUN]: (state) => ({
      ...state,
      error: null,
    }),
  },
  {
    allProjects: {
      results: [],
      filteredCount: null,
      count: null,
      params: {
        sortableColumns: [],
        availableFilters: [],
        filters: [],
      },
    },
    totalRemaining: null,
    isOpen: false,
    isLoading: false,
    error: null,
  },
);
