import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { NotFound } from '../errors';
import PageTitle from '../../components/Common/PageTitle';
import { withUser } from '../../context/UserContext';
import { useEmailRecipients } from '../../hooks';
import {
  deleteEmailRecipient,
  updateEmailRecipient,
  createEmailRecipient,
} from '../../store/emailRecipients';
import RecipientsList from './RecipientsList';
import leftChevron from '../../assets/images/left-chevron.svg';

const emailValidationSchema = Yup.string()
  .email('Invalid email address')
  .required('Please Enter User Email');

const EmailRecipients = ({ user, type }) => {
  if (!user || user.role !== 'ADMIN') {
    return <NotFound />;
  }

  const title =
    type === 'PROCESSING' ? 'Outgoing Transmission' : 'Incoming Transmission';

  const { data, isLoading } = useEmailRecipients();
  const [newRecipient, setNewRecipient] = useState(false);
  const [emailError, setEmailError] = useState({});
  const dispatch = useDispatch();

  const filteredRecipients = data.filter(
    (recipient) => recipient.type === type,
  );

  const handleDeleteEmailRecipient = async (id) => {
    dispatch(deleteEmailRecipient({ id }));
  };

  const handleUpdateEmailRecipient = async (id, email) => {
    try {
      await emailValidationSchema.validate(email);
      dispatch(updateEmailRecipient({ id, email }));
      setEmailError((prev) => ({ ...prev, [id]: '' }));
    } catch (err) {
      setEmailError((prev) => ({ ...prev, [id]: err.message }));
    }
  };

  const handleCreateEmailRecipient = async (email) => {
    try {
      await emailValidationSchema.validate(email);
      if (email) {
        dispatch(createEmailRecipient({ email, type }));
        setNewRecipient(false);
        setEmailError((prev) => ({ ...prev, new: '' }));
      }
    } catch (err) {
      setEmailError((prev) => ({ ...prev, new: err.message }));
    }
  };

  const handleKeyDown = (e, typ, id) => {
    if (e.key === 'Enter') {
      if (typ === 'update') {
        handleUpdateEmailRecipient(id, e.target.value);
      } else {
        handleCreateEmailRecipient(e.target.value);
      }
    }
  };

  return (
    <div className="page-content">
      <PageTitle title="Settings" />
      <Container fluid>
        <Row className="mb-5">
          <div className="d-flex align-items-center">
            <Link to="/settings">
              <img src={leftChevron} alt="back" />
            </Link>
            <h3 className="ms-5 mb-0">{title} Emails</h3>
          </div>
        </Row>
        <RecipientsList
          recipients={filteredRecipients}
          isLoading={isLoading}
          handleKeyDown={handleKeyDown}
          handleDeleteEmailRecipient={handleDeleteEmailRecipient}
          handleUpdateEmailRecipient={handleUpdateEmailRecipient}
          handleCreateEmailRecipient={handleCreateEmailRecipient}
          emailError={emailError}
          setNewRecipient={setNewRecipient}
          newRecipient={newRecipient}
          type={type}
        />
      </Container>
    </div>
  );
};

EmailRecipients.propTypes = {
  user: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired, // Ensure that type is passed (PROCESSING or FAILED_TRANSMISSION)
};

export default withUser(EmailRecipients);
