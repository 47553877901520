import { makeReducer } from '../common';
import {
  GET_RETIREMENTS,
  EDIT_RETIREMENT,
  CHECK_RETIREMENT,
  DELETE_RETIREMENT,
  CLEAR_RETIREMENT_ERROR,
  CLEAR_INDULGENCES_TO_REPROCESS,
  EXPORT_RETIREMENT_CSV,
  ROUND_UP_RETIREMENT,
  COUNT_SAME_REFERENCE,
  CLEAR_SAME_REFERENCE_COUNT,
} from './actionTypes';

export default makeReducer(
  {
    [GET_RETIREMENTS.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [GET_RETIREMENTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      retirements: {
        ...payload,
        indulgencesToReprocess: {
          indulgenceIds: [],
          retirementId: null,
        },
      },
      isLoading: false,
    }),
    [GET_RETIREMENTS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [EDIT_RETIREMENT.RUN]: (state) => ({
      ...state,
    }),
    [EDIT_RETIREMENT.SUCCESS]: (state, { payload: { retirement } }) => ({
      ...state,
      retirements: {
        ...state.retirements,
        results: state.retirements.results.map((row) =>
          row.id === retirement.id ? { ...row, ...retirement } : row,
        ),
      },
    }),
    [EDIT_RETIREMENT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [CHECK_RETIREMENT.RUN]: (state) => ({
      ...state,
    }),
    [CHECK_RETIREMENT.SUCCESS]: (
      state,
      { payload: { retirementId, nonReadyIndulgences } },
    ) => ({
      ...state,
      retirements: {
        ...state.retirements,
        indulgencesToReprocess: {
          indulgenceIds: nonReadyIndulgences || [],
          retirementId,
        },
      },
    }),
    [CHECK_RETIREMENT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [DELETE_RETIREMENT.RUN]: (state) => ({
      ...state,
    }),
    [DELETE_RETIREMENT.SUCCESS]: (state, { payload: { id } }) => ({
      ...state,
      retirements: {
        results: state.retirements.results.filter((row) => row.id !== id),
      },
    }),
    [DELETE_RETIREMENT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [CLEAR_RETIREMENT_ERROR.RUN]: (state) => ({
      ...state,
      error: null,
    }),
    [CLEAR_INDULGENCES_TO_REPROCESS.RUN]: (state) => ({
      ...state,
      retirements: {
        ...state.retirements,
        indulgencesToReprocess: {
          indulgenceIds: [],
          retirementId: null,
        },
      },
    }),
    [EXPORT_RETIREMENT_CSV.RUN]: (state) => ({
      ...state,
    }),
    [EXPORT_RETIREMENT_CSV.SUCCESS]: (state) => ({
      ...state,
    }),
    [EXPORT_RETIREMENT_CSV.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [ROUND_UP_RETIREMENT.RUN]: (state) => ({
      ...state,
    }),
    [ROUND_UP_RETIREMENT.SUCCESS]: (state, { payload: { retirement } }) => ({
      ...state,
      retirements: {
        ...state.retirements,
        results: state.retirements.results.map((row) =>
          row.id === retirement.id ? { ...row, ...retirement } : row,
        ),
      },
    }),
    [ROUND_UP_RETIREMENT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [COUNT_SAME_REFERENCE.RUN]: (state) => ({
      ...state,
    }),
    [COUNT_SAME_REFERENCE.SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      retirements: {
        ...state.retirements,
        sameReferenceCount: data.results,
      },
    }),
    [COUNT_SAME_REFERENCE.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [CLEAR_SAME_REFERENCE_COUNT.RUN]: (state) => ({
      ...state,
      retirements: {
        ...state.retirements,
        sameReferenceCount: null,
      },
    }),
  },
  {
    retirements: {
      results: [],
      count: null,
      indulgencesToReprocess: {
        indulgenceIds: [],
        retirementId: null,
      },
      sameReferenceCount: null,
    },
    isLoading: false,
    error: null,
  },
);
