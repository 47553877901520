import { asyncAction } from '../common';
import {
  GET_ORDERS,
  MOVE_TO_BILLED,
  PROCESS_ORDER,
  TOGGLE_ASSIGN_ORDERS_MODAL,
  ASSIGN_TO_PROJECT,
  GET_LAST_ORDERS,
  CLEAR_BILLED_ERROR,
  TOGGLE_BILLED_ORDERS_MODAL,
  SET_ROW_DATA_ARRAY,
} from './actionTypes';

export const getOrders = asyncAction(GET_ORDERS);
export const getLastOrders = asyncAction(GET_LAST_ORDERS);
export const moveToBilled = asyncAction(MOVE_TO_BILLED);
export const processOrder = asyncAction(PROCESS_ORDER);
export const toggleAssignOrdersModal = asyncAction(TOGGLE_ASSIGN_ORDERS_MODAL);
export const toggleBilledOrdersModal = asyncAction(TOGGLE_BILLED_ORDERS_MODAL);
export const assignToProject = asyncAction(ASSIGN_TO_PROJECT);
export const clearBilledError = asyncAction(CLEAR_BILLED_ERROR);
export const setRowDataArray = asyncAction(SET_ROW_DATA_ARRAY);
