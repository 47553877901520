import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUnknownSvcpns } from '../store/svcpns';
import { useQuery } from './query';

export const useUnknownSvcpns = (
  initialParams = {
    page: 1,
    pageSize: 50,
    sortingColumn: 'createdAt',
    sortingDirection: 'desc',
  },
) => {
  const [params, updateParams] = useQuery(initialParams);
  const dispatch = useDispatch();
  const location = useLocation();

  const unknownSvcpns = useSelector(
    (state) => state.svcpns.svcpns.unknownSvcpns,
  );
  const isLoading = useSelector(
    (state) => state.svcpns.svcpns.unknownSvcpns.isLoading,
  );

  useEffect(() => {
    if (location.pathname === '/svcpns') {
      dispatch(getUnknownSvcpns(params));
    } else {
      dispatch(getUnknownSvcpns(initialParams));
    }
  }, [params, location.pathname]);

  return {
    params,
    updateParams,
    sortableColumns: useSelector(
      (state) => state.svcpns.svcpns.unknownSvcpns.params.sortableColumns,
    ),
    filteredCount: useSelector(
      (state) => state.svcpns.svcpns.unknownSvcpns.filteredCount,
    ),
    unknownSvcpns,
    isLoading,
  };
};
