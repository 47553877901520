import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Input,
  Button,
  FormFeedback,
} from 'reactstrap';
import styled from 'styled-components';
import bin from '../../assets/images/bin.svg';

const StyledButton = styled(Button)`
  background-color: white !important;
  border: none;
`;

const AddButton = styled(Button)`
  color: blue !important;
  background-color: white !important;
`;

const StyledInput = styled(Input)`
  background-color: white !important;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
`;

const RecipientsList = ({
  recipients,
  isLoading,
  handleKeyDown,
  handleDeleteEmailRecipient,
  handleUpdateEmailRecipient,
  handleCreateEmailRecipient,
  emailError,
  setNewRecipient,
  newRecipient,
  type,
}) => {
  return (
    <>
      <Row>
        <Col xs={4}>
          <h4 style={{ marginLeft: '70px' }}>
            Recipients {type === 'FAILED_TRANSMISSION' ? '' : '(CC)'}
          </h4>
        </Col>
        <Col xs={8}>
          {!isLoading && (
            <Card>
              <CardBody
                style={{
                  border: '1px solid #E1E3E5',
                  borderRadius: '4px',
                  padding: '20px',
                }}
              >
                <Table className="email-recipients-table mb-0">
                  <Row className="mb-2">
                    <Col xs={1}>№</Col>
                    <Col xs={10}>Email</Col>
                    <Col xs={1}></Col>
                  </Row>
                  <Divider />
                  {recipients.map((recipient, index) => (
                    <React.Fragment key={recipient.id}>
                      <Row className="mb-2">
                        <Col
                          xs={1}
                          className="text-left vertical-align-middle pt-2"
                          style={{ fontSize: '14px' }}
                        >
                          {index + 1}
                        </Col>
                        <Col xs={10}>
                          <StyledInput
                            type="email"
                            defaultValue={recipient.email}
                            onKeyDown={(e) =>
                              handleKeyDown(e, 'update', recipient.id)
                            }
                            onBlur={(e) =>
                              handleUpdateEmailRecipient(
                                recipient.id,
                                e.target.value,
                              )
                            }
                            invalid={!!emailError[recipient.id]}
                          />
                          {emailError[recipient.id] && (
                            <FormFeedback
                              type="invalid"
                              className="text-danger bg-white p-0"
                            >
                              {emailError[recipient.id]}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col xs={1}>
                          <StyledButton
                            outline
                            onClick={() =>
                              handleDeleteEmailRecipient(recipient.id)
                            }
                          >
                            <img
                              src={bin}
                              alt="delete"
                              className="bg-white rounded-circle"
                            />
                          </StyledButton>
                        </Col>
                      </Row>
                      <Divider />
                    </React.Fragment>
                  ))}
                  {(newRecipient || recipients.length === 0) && (
                    <>
                      <Row className="mb-2">
                        <Col xs={1}>{recipients.length + 1}</Col>
                        <Col xs={10}>
                          <StyledInput
                            placeholder="Text"
                            type="email"
                            onKeyDown={(e) => handleKeyDown(e, 'create')}
                            onBlur={(e) =>
                              handleCreateEmailRecipient(e.target.value)
                            }
                            autoFocus
                            invalid={!!emailError.new}
                          />
                          {emailError.new && (
                            <FormFeedback
                              type="invalid"
                              className="text-danger bg-white p-0"
                            >
                              {emailError.new}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                      <Divider />
                    </>
                  )}
                  <Row>
                    <Col xs={1}></Col>
                    <Col xs={11}>
                      <AddButton
                        color="primary"
                        outline
                        onClick={() => setNewRecipient(true)}
                      >
                        + Add
                      </AddButton>
                    </Col>
                  </Row>
                </Table>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

RecipientsList.propTypes = {
  recipients: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  handleDeleteEmailRecipient: PropTypes.func.isRequired,
  handleUpdateEmailRecipient: PropTypes.func.isRequired,
  handleCreateEmailRecipient: PropTypes.func.isRequired,
  emailError: PropTypes.object.isRequired,
  setNewRecipient: PropTypes.func.isRequired,
  newRecipient: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default RecipientsList;
