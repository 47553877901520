import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import IconButton from '../../../components/Tables/IconButton';
import CloseIcon from '../../../components/Common/CloseIcon';
import AssignToProjectForm from './AssignToProjectForm';
import { toggleAssignRecordsModal } from '../../../store/records';

const Header = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const TotalsHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;

  .modal-title {
    width: 100%;
    padding: 0 28px;
  }
`;

const TitleCol = styled(Col)`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
`;

const TotalRow = styled(Row)`
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 400;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const AssignToProject = ({ params, isOpen, selectedRecords }) => {
  const dispatch = useDispatch();
  const toggleModal = () => dispatch(toggleAssignRecordsModal());

  return (
    <>
      <IconButton
        color="success"
        icon="bx-briefcase-alt-2"
        text="Assign to project"
        onClick={toggleModal}
      />
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <Header>
          <Row>
            <TitleCol>Assign to project</TitleCol>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </Header>
        <TotalsHeader>
          <TotalRow>
            <Col xs={8}>Total selected</Col>
            <Col xs={4}>{selectedRecords.length}</Col>
          </TotalRow>
          <TotalRow>
            <Col xs={8}>Total remaining carbon</Col>
            <Col xs={4}>
              {selectedRecords.reduce((sum, o) => sum + +o.offset, 0)} tonne
            </Col>
          </TotalRow>
        </TotalsHeader>
        <ModalBody>
          <AssignToProjectForm
            params={params}
            selectedRecords={selectedRecords}
          />
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default AssignToProject;

AssignToProject.propTypes = {
  params: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  selectedRecords: PropTypes.array.isRequired,
};
