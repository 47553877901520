import { takeEvery, call, put } from 'redux-saga/effects';
import { asyncSaga } from '../common';
import {
  getOrders,
  moveToBilled,
  processOrder,
  toggleAssignOrdersModal,
  assignToProject,
  getLastOrders,
  toggleBilledOrdersModal,
  setRowDataArray,
} from './actions';
import {
  GET_ORDERS,
  MOVE_TO_BILLED,
  PROCESS_ORDER,
  ASSIGN_TO_PROJECT,
  GET_LAST_ORDERS,
} from './actionTypes';
import { orders } from '../../api';

function* moveToBilledSaga({ payload: { params } }) {
  try {
    const response = yield call(orders.moveToBilled, params);
    yield put(moveToBilled.success(response));
    yield put(toggleBilledOrdersModal.success());
    yield put(setRowDataArray.success([]));
  } catch (error) {
    switch (error.response?.status) {
      case 422:
        return yield put(moveToBilled.fail(error.response.data.message));
      case 409:
        return yield put(
          moveToBilled.fail('This name already exists, try another one'),
        );
      default:
        return yield put(moveToBilled.fail('Something went wrong'));
    }
  }
}

function* processOrderSaga({ payload: { params } }) {
  try {
    yield call(orders.processOrder, params);
    yield put(processOrder.success({ params }));
  } catch (error) {
    switch (error.response?.status) {
      case 422:
        return yield put(processOrder.fail(error.message));
      default:
        return yield put(processOrder.fail('Something went wrong'));
    }
  }
}

function* assignToProjectSaga({ payload: { id, params, queryParams } }) {
  try {
    const response = yield call(orders.assignToProject, {
      id,
      params,
      queryParams,
    });
    yield put(assignToProject.success(response));
    yield put(toggleAssignOrdersModal.success());
    yield put(setRowDataArray.success([]));
  } catch (error) {
    switch (error.response?.status) {
      case 422:
        return yield put(assignToProject.fail('Invalid request params'));
      default:
        return yield put(assignToProject.fail('Something went wrong'));
    }
  }
}

function* getLastOrdersSaga() {
  try {
    const response = yield call(orders.getLastOrders);
    yield put(getLastOrders.success(response.data.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* ordersSaga() {
  yield takeEvery(
    GET_ORDERS.RUN,
    asyncSaga((action) => orders.getOrders(action), getOrders),
  );
  yield takeEvery(GET_LAST_ORDERS.RUN, getLastOrdersSaga);
  yield takeEvery(MOVE_TO_BILLED.RUN, moveToBilledSaga);
  yield takeEvery(PROCESS_ORDER.RUN, processOrderSaga);
  yield takeEvery(ASSIGN_TO_PROJECT.RUN, assignToProjectSaga);
}
