import React from 'react';
import overlayFactory from 'react-bootstrap-table2-overlay';

import Loader from '../Common/Loader';

const Overlay = overlayFactory({
  spinner: <Loader isShown />,
  styles: {
    overlay: (base) => ({
      ...base,
      background: 'rgba(255, 255, 255, 0.5)',
    }),
  },
});

export default Overlay;
