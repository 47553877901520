import { makeReducer } from '../common';
import { GET_TRANSMISSION_LOGS } from './actionTypes';

export default makeReducer(
  {
    [GET_TRANSMISSION_LOGS.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [GET_TRANSMISSION_LOGS.SUCCESS]: (state, { payload }) => ({
      ...state,
      transmissionLogs: {
        ...payload,
      },
      isLoading: false,
    }),
    [GET_TRANSMISSION_LOGS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
  },

  {
    transmissionLogs: {
      results: [],
      params: {
        sortableColumns: [],
      },
      filteredCount: null,
      count: null,
      isLoading: false,
      error: null,
    },
  },
);
