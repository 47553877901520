/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';

export const CustomSelect = ({
  onChange,
  options,
  value,
  invalid,
  ...props
}) => {
  const defaultValue = (opt, val) => {
    return opt ? opt.filter((option) => option.value === val) : '';
  };

  return (
    <Select
      value={defaultValue(options, value)}
      onChange={(val) => {
        onChange(val);
      }}
      options={options}
      styles={{
        container: (styles) => ({
          ...styles,
          width: '100%',
        }),
        menu: (styles) => ({
          ...styles,
          zIndex: 3,
        }),
        control: (styles) => ({
          ...styles,
          borderColor: invalid ? '#f46a6a' : styles.borderColor,
          boxShadow: 'none',
          '&:hover': {
            borderColor: invalid ? '#f46a6a' : styles['&:hover'].borderColor,
          },
        }),
      }}
      {...props}
    />
  );
};
