import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import moment from 'moment';
import { clearEditRecordError } from '../../store/records';
import CloseIcon from '../../components/Common/CloseIcon';
import EditOrderForm from './EditOrderForm';
import { withUser } from '../../context/UserContext';

const StyledButton = styled(Button)`
  width: 80px;
  height: 20px;
  border-radius: 8px;
  padding: 0;
`;

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const EditOrder = ({ row, user }) => {
  const { orderedAt, salesOrderItemShipAt } = row;
  const orderDate = orderedAt && moment.utc(orderedAt).format('YYYY.MM.DD');
  const shipDate =
    salesOrderItemShipAt &&
    moment.utc(salesOrderItemShipAt).format('YYYY.MM.DD');
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearEditRecordError());
  }, [isOpen]);

  return (
    <>
      {(user && user.role === 'ADMIN') || user.role === 'SALES' ? (
        <StyledButton color="primary" onClick={toggleModal}>
          <span>Edit</span>
        </StyledButton>
      ) : (
        <StyledButton color="primary" disabled onClick={toggleModal}>
          <span>Edit</span>
        </StyledButton>
      )}

      <Modal size="lg" toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={11}>
              <h4>Edit</h4>
            </Col>
            <Col>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <EditOrderForm
            records={row}
            orderedAt={orderDate}
            salesOrderItemShipAt={shipDate}
            toggleModal={toggleModal}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default withUser(EditOrder);

EditOrder.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
