/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { Row, Col, CardTitle, Container, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import PageTitle from '../../components/Common/PageTitle';
import { useSvcpns, useUnknownSvcpns } from '../../hooks';
import Export from './Export';
import SvcpnFilters from '../../components/Tables/SvcpnFilters';
import SortCaret from '../../components/Tables/SortCaret';
import SvcpnKnownTable from '../../components/Tables/SvcpnKnownTable';
import SvcpnUnknownTable from '../../components/Tables/SvcpnUnknownTable';
import AddNewSvcpn from './AddNewSvcpn';
import EditSvcpns from './EditSvcpn';
import { withUser } from '../../context/UserContext';
import UnknownSvcpnsActionMenu from './UnknownSvcpnsActionMenu';
import { format } from '../../helpers/formatter';

const statusFormatter = (cell, row) => {
  if (!row.isOutdated) {
    return (
      <span
        style={{
          color: '#34C38F',
          background: '#CCF0E3',
          borderRadius: '10px',
          textAlign: 'center',
          padding: '0px 10px',
        }}
      >
        {cell}Actual
      </span>
    );
  }
  return (
    <span
      style={{
        color: '#F46A6A',
        background: '#FCDADA',
        borderRadius: '10px',
        textAlign: 'center',
        padding: '0px 10px',
      }}
    >
      {cell}Outdated
    </span>
  );
};

const columns = [
  {
    dataField: 'code',
    text: 'SVCPN',
  },
  {
    dataField: 'product',
    text: 'Product',
  },
  {
    dataField: 'description',
    text: 'SVCPN Description',
  },
  {
    dataField: 'offset',
    text: 'Offset',
    formatter: format,
  },
  {
    // TODO: make outdatedAt and isOutdated consistent
    dataField: 'outdatedAt',
    text: 'Status',
    formatter: statusFormatter,
    sort: true,
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: (_, row) => <EditSvcpns row={row} />,
  },
];

export const unknownColumns = [
  {
    dataField: 'code',
    text: 'SVCPN',
  },
  {
    dataField: 'description',
    text: 'SVCPN Description',
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: (_, row) => <UnknownSvcpnsActionMenu row={row} />,
  },
];

const SVCPNs = ({ user }) => {
  const {
    params: knownParams,
    updateParams: updateKnownParams,
    sortableColumns,
    knownSvcpns,
    isOpen,
    filteredCount: knownFilteredCount,
    makeSetFilter,
    availableFilters: knownAvailableFilters,
    filters: knownFilters,
    isLoading: isKnownSvcpnsLoading,
  } = useSvcpns();

  const {
    params: unknownParams,
    updateParams: updateUnknownParams,
    sortableColumns: unknownSortableColumns,
    unknownSvcpns,
    isLoading: isUnknownSvcpnsLoading,
    filteredCount: unknownFilteredCount,
  } = useUnknownSvcpns();

  const knownColumnsWithSorting = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        sort: sortableColumns.includes(column.dataField),
        sortCaret: (order) => <SortCaret order={order} />,
      })),
    [sortableColumns.join('')],
  );

  const unknownColumnsWithSorting = useMemo(
    () =>
      unknownColumns.map((column) => ({
        ...column,
        sort: unknownSortableColumns.includes(column.dataField),
        sortCaret: (order) => <SortCaret order={order} />,
      })),
    [unknownSortableColumns.join('')],
  );

  const onKnownSvcpnsTableChange = (type, newState) => {
    if (type === 'sort') {
      updateKnownParams({
        ...knownParams,
        sortingColumn: newState.sortField,
        sortingDirection: newState.sortOrder,
      });
    }
    if (type === 'pagination') {
      updateKnownParams({
        ...knownParams,
        page: newState.page,
        pageSize: newState.sizePerPage,
      });
    }
  };

  const onUnknownSvcpnsTableChange = (type, newState) => {
    if (type === 'sort') {
      updateUnknownParams({
        ...unknownParams,
        sortingColumn: newState.sortField,
        sortingDirection: newState.sortOrder,
      });
    }
    if (type === 'pagination') {
      updateUnknownParams({
        ...unknownParams,
        page: newState.page,
        pageSize: newState.sizePerPage,
      });
    }
  };

  return (
    <div className="page-content">
      <PageTitle title="SVCPNs" />
      <Container fluid>
        <Row>
          <div className="mb-2">
            <div className="d-flex justify-content-between">
              <h3>Service Part Numbers</h3>
              <span>
                {(user && user.role === 'ADMIN') || user.role === 'SALES' ? (
                  <AddNewSvcpn isOpen={isOpen} />
                ) : null}
              </span>
            </div>
          </div>
          <Col xl={7}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <SvcpnFilters
                    availableFilters={knownAvailableFilters}
                    filters={knownFilters}
                    makeSetFilter={makeSetFilter}
                  />
                  <Export params={knownParams} />
                </div>
                <SvcpnKnownTable
                  columns={knownColumnsWithSorting}
                  data={knownSvcpns.results}
                  sizePerPage={knownParams.pageSize}
                  totalSize={knownFilteredCount || 0}
                  page={knownParams.page}
                  sort={{
                    dataField: knownParams.sortingColumn,
                    order: knownParams.sortingDirection,
                  }}
                  isLoading={isKnownSvcpnsLoading}
                  onTableChange={onKnownSvcpnsTableChange}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xl={5}>
            <Card>
              <CardBody>
                <div className="d-flex mt-2">
                  <CardTitle tag="span" className="h4">
                    New SVCPNs
                  </CardTitle>
                </div>
                <SvcpnUnknownTable
                  columns={unknownColumnsWithSorting}
                  data={unknownSvcpns.results}
                  sizePerPage={unknownParams.pageSize}
                  totalSize={unknownFilteredCount || 0}
                  page={unknownParams.page}
                  sort={{
                    dataField: unknownParams.sortingColumn,
                    order: unknownParams.sortingDirection,
                  }}
                  isLoading={isUnknownSvcpnsLoading}
                  onTableChange={onUnknownSvcpnsTableChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

SVCPNs.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withUser(SVCPNs);
