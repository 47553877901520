import * as actions from './actions';

export const getRecords = actions.getRecords.run;
export const fetchFilters = actions.fetchFilters.run;
export const toggleAssignRecordsModal = actions.toggleAssignRecordsModal.run;
export const toggleBilledRecordsModal = actions.toggleBilledRecordsModal.run;
export const assignToProject = actions.assignToProject.run;
export const processRecord = actions.processRecord.run;
export const moveRecordsToBilled = actions.moveRecordsToBilled.run;
export const toggleAssignAllModal = actions.toggleAssignAllModal.run;
export const assignAllToProject = actions.assignAllToProject.run;
export const editRecord = actions.editRecord.run;
export const clearEditRecordError = actions.clearEditRecordError.run;
export const exportCsv = actions.exportCsv.run;
export const bulkAction = actions.bulkAction.run;
