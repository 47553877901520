import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch, batch } from 'react-redux';
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from '../../store/actions';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const {
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    leftSideBarTheme,
  } = useSelector((state) => ({
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }));

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    () => window.screen.width <= 998,
  );
  const toggleSidebarCollapsion = useCallback(() => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  }, [isSidebarCollapsed]);

  useEffect(() => {
    window.scrollTo(0, 0);
    batch(() => {
      dispatch(changeLayout('vertical'));
      dispatch(changeSidebarTheme(leftSideBarTheme));
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
      dispatch(changeLayoutWidth(layoutWidth));
      dispatch(changeSidebarType(leftSideBarType));
      dispatch(changeTopbarTheme(topbarTheme));
    });
  }, []);

  return (
    <div id="layout-wrapper">
      <Header toggleSidebarCollapsion={toggleSidebarCollapsion} />
      <Sidebar isCollapsed={isSidebarCollapsed} />
      <div className="main-content">{children}</div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default Layout;
