import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import IconButton from '../../components/Tables/IconButton';
import CloseIcon from '../../components/Common/CloseIcon';
import { processRecord } from '../../store/records';
import { useToggle } from '../../hooks';

const BorderedHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const BottomRow = styled(Row)`
  margin-top: 15px;
`;

const ModalButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const MultiProcessRecord = ({ selectedRecords }) => {
  const [isOpen, toggleModal] = useToggle();
  const { hasUnfinishedTask } = useSelector((state) => ({
    hasUnfinishedTask: state.records.records.hasUnfinishedTask,
  }));
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();
  const handleProcessRecord = () => {
    const params = { recordIds: selectedRecords.map((el) => el.id) };
    dispatch(processRecord({ params }));
    toggleModal();
    setDisable(true);
  };
  return (
    <>
      <span
        title={hasUnfinishedTask || disable ? 'Processing is in progress' : ''}
      >
        <IconButton
          color="primary"
          icon="bx-notepad"
          text="Process Records"
          onClick={toggleModal}
          disabled={hasUnfinishedTask || disable}
        />
      </span>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <BorderedHeader>
          <Row>
            <Col>
              <h4>Process record(s)</h4>
            </Col>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </BorderedHeader>
        <ModalBody>
          <Row>
            <Col>Are you sure you want to process the record(s)?</Col>
          </Row>
          <BottomRow>
            <Col>
              <ModalButton onClick={toggleModal} color="secondary">
                Cancel
              </ModalButton>
            </Col>
            <Col className="text-end">
              <ModalButton onClick={handleProcessRecord} color="primary">
                Ok
              </ModalButton>
            </Col>
          </BottomRow>
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default MultiProcessRecord;

MultiProcessRecord.propTypes = {
  selectedRecords: PropTypes.array.isRequired,
};
