import { takeEvery, call, put } from 'redux-saga/effects';
import { getOrder } from './actions';
import { GET_ORDER } from './actionTypes';
import { orders } from '../../api';

function* getOrderSaga({ payload: { id } }) {
  try {
    const response = yield call(orders.getOrder, { id });
    yield put(getOrder.success(response.data.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* orderSaga() {
  yield takeEvery(GET_ORDER.RUN, getOrderSaga);
}
