import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import PropTypes from 'prop-types';

const Notification = (type, message, desc) => {
  const positionClass = 'toast-top-right';
  toastr.options = {
    positionClass,
  };

  return toastr[type](message, desc);
};

Notification.defaultProps = {
  isShown: false,
};

Notification.propTypes = {
  isShown: PropTypes.bool.isRequired,
};

export default Notification;
