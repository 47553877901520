import { makeReducer } from '../common';
import {
  GET_EMAIL_RECIPIENTS,
  CREATE_EMAIL_RECIPIENT,
  DELETE_EMAIL_RECIPIENT,
  UPDATE_EMAIL_RECIPIENT,
} from './actionTypes';

export default makeReducer(
  {
    [GET_EMAIL_RECIPIENTS.RUN]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [GET_EMAIL_RECIPIENTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      emailRecipients: payload.results,
      isLoading: false,
      error: null,
    }),
    [GET_EMAIL_RECIPIENTS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [CREATE_EMAIL_RECIPIENT.RUN]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [CREATE_EMAIL_RECIPIENT.SUCCESS]: (state, { payload }) => ({
      ...state,
      emailRecipients: [...state.emailRecipients, payload],
      isLoading: false,
      error: null,
    }),
    [CREATE_EMAIL_RECIPIENT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [UPDATE_EMAIL_RECIPIENT.RUN]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [UPDATE_EMAIL_RECIPIENT.SUCCESS]: (state, { payload }) => ({
      ...state,
      emailRecipients: state.emailRecipients.map((recipient) =>
        recipient.id === payload.id ? payload : recipient,
      ),
      isLoading: false,
      error: null,
    }),
    [UPDATE_EMAIL_RECIPIENT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [DELETE_EMAIL_RECIPIENT.RUN]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [DELETE_EMAIL_RECIPIENT.SUCCESS]: (state, { payload: { id } }) => ({
      ...state,
      emailRecipients: state.emailRecipients.filter(
        (recipient) => recipient.id !== id,
      ),
      isLoading: false,
      error: null,
    }),
    [DELETE_EMAIL_RECIPIENT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
  },
  {
    emailRecipients: [],
    error: null,
    isLoading: false,
  },
);
