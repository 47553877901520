import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const StyledButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const CannotDeleteRetirement = ({ isOpen, toggleModal, billings }) => {
  return (
    <StyledModal toggle={toggleModal} isOpen={isOpen}>
      <ModalBody>
        <HeaderRow>
          <Col xs={10}>
            <h4>Cannot delete retirement</h4>
          </Col>
          <Col xs={2}>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Col>
        </HeaderRow>
        <Row>
          <Col>
            The retirement you are about to delete is associated with the
            following Billing summaries: <p></p>{' '}
            {billings?.map((billing) => (
              <p key={billing.name}>{billing.name}</p>
            ))}
            Delete the associated Billing summaries first, then delete the
            Retirement.
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col sm={4}>
            <StyledButton onClick={toggleModal} color="primary">
              Ok
            </StyledButton>
          </Col>
        </Row>
      </ModalBody>
    </StyledModal>
  );
};

CannotDeleteRetirement.propTypes = {
  retirement: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  billings: PropTypes.array,
};

export default CannotDeleteRetirement;
