import { asyncActionType } from '../common';

export const GET_ORDERS = asyncActionType('GET_ORDERS');
export const GET_LAST_ORDERS = asyncActionType('GET_LAST_ORDERS');
export const MOVE_TO_BILLED = asyncActionType('MOVE_TO_BILLED');
export const PROCESS_ORDER = asyncActionType('PROCESS_ORDER');
export const TOGGLE_ASSIGN_ORDERS_MODAL = asyncActionType(
  'TOGGLE_ASSIGN_ORDERS_MODAL',
);
export const TOGGLE_BILLED_ORDERS_MODAL = asyncActionType(
  'TOGGLE_BILLED_ORDERS_MODAL',
);
export const ASSIGN_TO_PROJECT = asyncActionType('ASSIGN_TO_PROJECT');
export const CLEAR_BILLED_ERROR = asyncActionType('CLEAR_BILLED_ERROR');
export const SET_ROW_DATA_ARRAY = asyncActionType('SET_ROW_DATA_ARRAY');
