import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../../components/Common/CloseIcon';
import AssignAllToProjectForm from './AssignAllToProjectForm';
import { toggleAssignAllModal } from '../../../store/records';
import { withUser } from '../../../context/UserContext';

const Header = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const TotalsHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;

  .modal-title {
    width: 100%;
    padding: 0 28px;
  }
`;

const TitleCol = styled(Col)`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
`;

const TotalRow = styled(Row)`
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 400;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const StyledButton = styled(Button)`
  display: inline-block;
  width: 160px;
  height: 29px;
  padding: 4px 20px;
`;

const AssignAllToProject = ({
  params,
  isOpenAssignAll,
  withStatusNew,
  totalOffsetOfNew,
  user,
}) => {
  const dispatch = useDispatch();
  const toggleModal = () => dispatch(toggleAssignAllModal());

  return (
    <>
      {(user && user.role === 'ADMIN') || user.role === 'SALES' ? (
        <StyledButton
          disabled={!withStatusNew}
          color="primary"
          onClick={toggleModal}
        >
          <span>Assign all records</span>
        </StyledButton>
      ) : null}

      <StyledModal toggle={toggleModal} isOpen={isOpenAssignAll}>
        <Header>
          <Row>
            <TitleCol>Assign all records to project</TitleCol>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </Header>
        <TotalsHeader>
          <TotalRow>
            <Col xs={9}>Total filtered records (status New)</Col>
            <Col xs={3}>{withStatusNew}</Col>
          </TotalRow>
          <TotalRow>
            <Col xs={9}>Total remaining carbon</Col>
            <Col xs={3}>{totalOffsetOfNew} tonne</Col>
          </TotalRow>
        </TotalsHeader>
        <ModalBody>
          <AssignAllToProjectForm
            params={params}
            totalOffsetOfNew={totalOffsetOfNew}
          />
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default withUser(AssignAllToProject);

AssignAllToProject.propTypes = {
  params: PropTypes.object,
  isOpenAssignAll: PropTypes.bool.isRequired,
  withStatusNew: PropTypes.number,
  totalOffsetOfNew: PropTypes.number,
  user: PropTypes.object.isRequired,
};
