import urls from './urls';
import client from '../utils/api';

const getBillings = (query = {}) =>
  client.get(urls.billings.getBillings(), query);
const editBillings = ({ id, params }) =>
  client.put(urls.billings.editBillings(id), params);
const getBilling = ({ id }) => client.get(urls.billings.getBilling(id));
const sentBillings = ({ id, params }) =>
  client.put(urls.billings.editBillings(id), params);
const downloadBillings = ({ id }) =>
  client.gateway({
    method: 'GET',
    url: urls.billings.downloadBillings(id),
    responseType: 'blob',
  });
const deleteBilling = ({ id }) =>
  client.del(urls.billings.deleteBilling(id), { id });

export default {
  getBillings,
  editBillings,
  downloadBillings,
  deleteBilling,
  sentBillings,
  getBilling,
};
