import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Modal, ModalBody, Row } from 'reactstrap';
import { useToggle } from '../../hooks';
import CloseIcon from '../../components/Common/CloseIcon';
import DetailsInfo from './DetailsInfo';

const StyledButton = styled(Button)`
  display: inline-block;
  width: 122px;
  height: 37px;
  padding: 4px 20px;
  margin-left: 10px;
`;

const HeaderRow = styled(Row)`
  font-size: 18px;
  font-weight: 300;
  line-height: 16px;
`;

const StyledModal = styled(Modal)`
  max-width: fit-content;
  min-width: 330px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const ViewDetails = () => {
  const [isOpen, toggleModal] = useToggle();

  const {
    records,
    withStatusNew,
    withStatusReady,
    withStatusDone,
    withStatusInProgress,
    withStatusInvalid,
    withStatusDuplicate,
    withStatusBilled,
    totalOffsetExceptInvalidAndDuplicate,
    totalOffsetRetired,
    countAllExceptInvalidAndDuplicate,
  } = useSelector((state) => ({
    records: state.records.records.results,
    withStatusNew: state.records.records.withStatusNew,
    withStatusReady: state.records.records.withStatusReady,
    withStatusDone: state.records.records.withStatusDone,
    withStatusInProgress: state.records.records.withStatusInProgress,
    withStatusInvalid: state.records.records.withStatusInvalid,
    withStatusDuplicate: state.records.records.withStatusDuplicate,
    withStatusBilled: state.records.records.withStatusBilled,
    totalOffsetExceptInvalidAndDuplicate:
      state.records.records.totalOffsetExceptInvalidAndDuplicate,
    totalOffsetRetired: state.records.records.totalOffsetRetired,
    countAllExceptInvalidAndDuplicate:
      state.records.records.countAllExceptInvalidAndDuplicate,
  }));

  const data = {
    withStatusNew,
    withStatusReady,
    withStatusDone,
    withStatusInProgress,
    withStatusInvalid,
    withStatusDuplicate,
    withStatusBilled,
    totalOffsetExceptInvalidAndDuplicate,
    totalOffsetRetired,
    countAllExceptInvalidAndDuplicate,
  };

  return (
    <>
      <StyledButton
        color="primary"
        onClick={toggleModal}
        disabled={records.length === 0}
      >
        <span>View Details</span>
      </StyledButton>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <div>View details</div>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </HeaderRow>
          <hr />
          <DetailsInfo toggleModal={toggleModal} data={data} />
        </ModalBody>
      </StyledModal>
    </>
  );
};

ViewDetails.propTypes = {
  orderData: PropTypes.object,
};

export default React.memo(ViewDetails);
