import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

const FormContainer = styled.div`
  display: grid;
  padding-left: 35px;
`;
const CustomCol = styled(Col)`
  font-size: 16px;
`;
const BottomRow = styled(Row)`
  display: grid;
  padding-left: 120px;
  margin-top: 20px;
`;

const SaveButton = styled(Button)`
  width: 100px;
  font-size: 16px;
  border-radius: 8px;
`;

const DetailsInfo = ({
  toggleModal,
  data: {
    orderedAt,
    regionName,
    status,
    totalOffset,
    totalRecords,
    totalRemaning,
    totalRetirement,
  },
}) => {
  const statusFormatter = () => {
    switch (status) {
      case 'BILLED':
        return (
          <div
            style={{
              color: '#343A40',
              background: '#CCCECF',
              borderRadius: '10px',
              textAlign: 'center',
              padding: '0px 10px',
              width: '100px',
            }}
          >
            Billed
          </div>
        );
      case 'UNPROCESSED':
        return (
          <div
            style={{
              color: '#F1B44C',
              background: '#FBECD2',
              borderRadius: '10px',
              textAlign: 'center',
              padding: '0px 10px',
              width: '130px',
            }}
          >
            Unprocessed
          </div>
        );
      case 'READY':
        return (
          <span
            style={{
              color: '#F1B44C',
              background: '#FBECD2',
              borderRadius: '10px',
              textAlign: 'center',
              padding: '0px 10px',
            }}
          >
            Ready
          </span>
        );
      case 'PROCESSED':
        return (
          <div
            style={{
              color: '#34C38F',
              background: '#CCF0E3',
              borderRadius: '10px',
              textAlign: 'center',
              padding: '0px 10px',
              width: '110px',
            }}
          >
            Processed
          </div>
        );
      default:
        return (
          <div
            style={{
              color: '#F46A6A',
              background: '#FCDADA',
              borderRadius: '10px',
              textAlign: 'center',
              padding: '0px 10px',
              width: '100px',
            }}
          >
            Unknown
          </div>
        );
    }
  };

  const formatingDate = moment.utc(orderedAt).format('YYYY.MM.DD');

  return (
    <FormContainer>
      <Row>
        <h4>
          <b>General Info</b>
        </h4>
        <CustomCol xs={7}>
          <div>Status</div>
          <div className="py-2">Date</div>
          <div>Geography</div>
          <div className="py-2">Total records</div>
        </CustomCol>
        <CustomCol xs={5}>
          <div>{statusFormatter()}</div>
          <div className="py-2">{formatingDate}</div>
          <div className="text-truncate">{regionName}</div>
          <div className="py-2">{totalRecords}</div>
        </CustomCol>
      </Row>
      <Row className="pt-4">
        <h4>
          <b>Offset summary</b>
        </h4>
        <CustomCol xs={7}>
          <div>Total offsets</div>
          <div className="py-2">Retired offsets</div>
          <div>Total Remaining</div>
        </CustomCol>
        <CustomCol xs={5}>
          <div>{totalOffset}</div>
          <div className="py-2 text-truncate">{totalRetirement}</div>
          <div>{totalRemaning}</div>
        </CustomCol>
      </Row>
      <BottomRow>
        <Col>
          <SaveButton onClick={toggleModal} color="primary">
            Close
          </SaveButton>
        </Col>
      </BottomRow>
    </FormContainer>
  );
};

export default React.memo(DetailsInfo);

DetailsInfo.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    orderedAt: PropTypes.string.isRequired,
    regionName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    totalOffset: PropTypes.number.isRequired,
    totalRecords: PropTypes.number.isRequired,
    totalRemaning: PropTypes.number.isRequired,
    totalRetirement: PropTypes.number.isRequired,
  }),
};
