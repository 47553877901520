import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ className, fill }) => (
  <svg
    className={className}
    width={15}
    height={15}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="m10.000592,8.334023l-8.334023,-8.334023l-1.666569,1.666569l8.334023,8.334023l-8.334023,8.334023l1.666569,1.666569l
    8.334023,-8.334023l8.334023,8.334023l1.666569,-1.666569l-8.334023,-8.334023l8.334023,-8.334023l-1.666569,-1.666569l-8.334023,8.334023z"
    />
  </svg>
);

CloseIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

CloseIcon.defaultProps = {
  className: null,
  fill: '#808080',
};

export default CloseIcon;
