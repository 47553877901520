import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { ProjectStatusFilter } from './Filters';

const ProjectsFilter = ({ filters, availableFilters, makeSetFilter }) => {
  return (
    <>
      <Form>
        <ProjectStatusFilter
          availableFilters={availableFilters}
          filters={filters}
          makeSetFilter={makeSetFilter}
        />
      </Form>
    </>
  );
};

ProjectsFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  availableFilters: PropTypes.object.isRequired,
  makeSetFilter: PropTypes.func.isRequired,
};

export default ProjectsFilter;
