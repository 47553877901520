import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Alert,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { moveToBilled } from '../../store/orders';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: center;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  display: grid;
`;

const SaveButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const MoveToBilledForm = ({ selectedOrders, error }) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      orderIds: selectedOrders.map((order) => order.id),
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(250)
        .required('Please Enter name')
        .matches(
          /^[^\s].+[^\s]*[- .+()]*[A-Za-z0-9][- .+()A-Za-z0-9]*$/,
          'Field must contain letters and numbers',
        ),
    }),
    onSubmit: (params) => {
      dispatch(moveToBilled({ params }));
    },
  });

  return (
    <FormContainer>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {error ? <Alert color="danger">{error}</Alert> : null}
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-label">
                Billing Summary Name
                <Asterix>*</Asterix>
              </Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Billing Summary Name"
                type="name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ''}
                invalid={!!(validation.touched.name && validation.errors.name)}
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        <BottomRow>
          <Col>
            <SaveButton type="submit" color="primary">
              Create
            </SaveButton>
          </Col>
        </BottomRow>
      </Form>
    </FormContainer>
  );
};

MoveToBilledForm.propTypes = {
  selectedOrders: PropTypes.array.isRequired,
  error: PropTypes.string,
};

export default MoveToBilledForm;
