import React from 'react';
import PropTypes from 'prop-types';

const ThreeDotsMenu = ({ className, fill }) => (
  <svg
    className={className}
    width={20}
    height={20}
    viewBox="0 5 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
    />
  </svg>
);

ThreeDotsMenu.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

ThreeDotsMenu.defaultProps = {
  className: null,
  fill: 'currentColor',
};

export default ThreeDotsMenu;
