import React, { useMemo, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Export from './Export';
import RetirementsTable from '../../components/Tables/RetirementsTable';
import ProjectFilter from '../../components/Tables/AllProjectFilter';
import PageTitle from '../../components/Common/PageTitle';
import AddNewProject from './AddNewProject';
import ActionMenu from './ActionsMenu';
import SortCaret from '../../components/Tables/SortCaret';
import { withUser } from '../../context/UserContext';
import { useAllProjects } from '../../hooks/allProjects';
import { getAllProjects } from '../../store/allProjects';
import { format } from '../../helpers/formatter';

const projectFormatter = (cell, row) => {
  if (row.deletedAt !== null) {
    return (
      <span
        style={{
          color: '#FCDADA',
          background: '#FF6347  ',
          borderRadius: '10px',
          textAlign: 'center',
          padding: '0px 9px',
        }}
      >
        Deleted
      </span>
    );
  }
  if (!row.exhausted) {
    return (
      <span
        style={{
          color: '#34C38F',
          background: '#CCF0E3',
          borderRadius: '10px',
          textAlign: 'center',
          padding: '0px 10px',
        }}
      >
        Valid
      </span>
    );
  }
  return (
    <span
      style={{
        color: '#50A5F1',
        background: '#D3E8FB',
        borderRadius: '10px',
        textAlign: 'center',
        padding: '0px 10px',
      }}
    >
      Exhausted
    </span>
  );
};

const pdfLinkIconFormater = (cell, row) => {
  if (cell !== null && cell !== '') {
    return (
      <div>
        <a
          title={'Click to follow the link'}
          href={row.pdfLink}
          target="_blank"
          rel="noreferrer"
        >
          <i
            style={{ color: '#32CD32' }}
            className={cx('bx', 'bx bxs-file-pdf', 'bx-md')}
          />
        </a>
      </div>
    );
  }
  return (
    <i
      style={{ color: '#C0C0C0' }}
      className={cx('bx', 'bx bxs-file-pdf', 'bx-md')}
    />
  );
};

const Projects = ({ user }) => {
  const columns = [
    {
      dataField: 'code',
      text: 'Project No',
    },
    {
      dataField: 'title',
      text: 'Project Name',
    },
    {
      dataField: 'indulgenceAmount',
      text: 'Purchased Quantity',
      formatter: format,
    },
    {
      dataField: 'totalRetirement',
      text: 'Total Retirement',
      formatter: format,
    },
    {
      dataField: 'remaning',
      text: 'Remaining',
      formatter: format,
    },
    {
      dataField: 'purchasePrice',
      text: 'Price',
      hidden: true,
    },
    {
      dataField: 'salesPrice',
      text: 'Sales Price',
      hidden: true,
    },
    {
      dataField: 'otherFees',
      text: 'Other Fees',
      hidden: true,
    },
    {
      dataField: 'pdfLink',
      text: 'Document',
      formatter: pdfLinkIconFormater,
    },
    {
      dataField: 'deleted',
      text: 'Status',
      formatter: projectFormatter,
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => <ActionMenu row={row} />,
    },
  ];

  const {
    allProjectsData,
    makeSetFilter,
    availableFilters,
    filters,
    params,
    updateParams,
    filteredCount,
    isProjectsLoading,
    isOpen,
    totalRemaining,
    totalPurchased,
    isLoading,
    sortableColumns,
  } = useAllProjects();

  const firstLoad = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!firstLoad.current) {
      dispatch(getAllProjects(params));
      firstLoad.current = true;
    } else if (allProjectsData.length > 0) {
      firstLoad.current = false;
    }
  }, [allProjectsData]);

  const columnsWithSorting = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        sort: sortableColumns.includes(column.dataField),
        sortCaret: (order) => <SortCaret order={order} />,
      })),
    [sortableColumns.join('')],
  );

  const onAllProjectTableChange = (type, newState) => {
    if (type === 'sort') {
      updateParams({
        ...params,
        sortingColumn: newState.sortField,
        sortingDirection: newState.sortOrder,
      });
    }
    if (type === 'pagination') {
      updateParams({
        ...params,
        page: newState.page,
        pageSize: newState.sizePerPage,
      });
    }
    updateParams(
      {
        ...params,
        page: newState.page,
        pageSize: newState.sizePerPage,
        sortingColumn: newState.sortField,
        sortingDirection: newState.sortOrder,
      },
      params.deleted ? [] : ['deleted'],
    );
  };

  return (
    <div className="page-content">
      <PageTitle title="Projects" />
      <Container fluid>
        <Row>
          <div className="mb-2">
            <div className="d-flex justify-content-between ">
              <h3>Projects</h3>
              {(user && user.role === 'ADMIN') || user.role === 'SALES' ? (
                <span>
                  <Export params={params} />
                  <AddNewProject isOpen={isOpen} isLoading={isLoading} />
                </span>
              ) : null}
            </div>
          </div>
          <Col xl={12}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="mt-2 col-2">
                    <h3 className="h4">List of All projects</h3>
                  </div>
                  <div className="pb-0 col-1">
                    <ProjectFilter
                      availableFilters={availableFilters}
                      filters={filters}
                      makeSetFilter={makeSetFilter}
                    />
                  </div>
                  <h6 className="mt-2 col-6  text-end">
                    {`Purchased Quantity of all valid projects: ${format(
                      totalPurchased,
                    )}`}
                  </h6>
                  <h6 className="mt-2 col-3  text-end">
                    Remaining of all valid projects: {format(totalRemaining)}
                  </h6>
                </div>
                <RetirementsTable
                  columns={columnsWithSorting}
                  data={allProjectsData || []}
                  page={params.page}
                  sort={{
                    dataField: params.sortingColumn,
                    order: params.sortingDirection,
                  }}
                  isLoading={isProjectsLoading}
                  sizePerPage={params.pageSize}
                  totalSize={filteredCount}
                  onTableChange={onAllProjectTableChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Projects.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withUser(Projects);
