import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from '../../components/Common/CloseIcon';

import { clearBillingsError, editBillingSummary } from '../../store/billings';
import { withUser } from '../../context/UserContext';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const SaveButton = styled(Button)`
  width: 190px;
  font-size: 16px;
`;

const CancelButton = styled(Button)`
  width: 140px;
  font-size: 16px;
`;

const UnmarkAsSentModal = ({ row, isOpen, toggleModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearBillingsError());
  }, [isOpen]);

  const onUnmark = () => {
    toggleModal();
    const params = { status: 'UNSENT' };
    dispatch(editBillingSummary({ id: row.id, params }));
  };
  return (
    <>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>Unmark as Sent</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <Row>
            <Col>
              <p>
                You are about to unmark the Billing Summary as sent. That will
                remove the corresponding status and clear the send date if it
                was specified. Continue?
              </p>
            </Col>
          </Row>
          <Row className="justify-content-around">
            <Col md={4}>
              <CancelButton type="submit" onClick={toggleModal}>
                No, cancel
              </CancelButton>
            </Col>
            <Col md={6}>
              <SaveButton onClick={onUnmark} color="primary">
                Yes, unmark as sent
              </SaveButton>
            </Col>
          </Row>
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default withUser(UnmarkAsSentModal);

UnmarkAsSentModal.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
