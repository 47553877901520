import React from 'react';
import PropTypes from 'prop-types';
import Logout from '../../pages/Authentication/Logout';

const Header = ({ toggleSidebarCollapsion }) => (
  <header id="page-topbar">
    <div className="navbar-header">
      <div className="d-flex">
        <button
          type="button"
          onClick={toggleSidebarCollapsion}
          className="btn btn-sm px-3 font-size-16 header-item "
          id="vertical-menu-btn"
        >
          <i className="fa fa-fw fa-bars" />
        </button>
      </div>
      <Logout />
    </div>
  </header>
);

Header.propTypes = {
  toggleSidebarCollapsion: PropTypes.func,
};

export default Header;
