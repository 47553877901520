import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from '../../components/Common/CloseIcon';
import EditSvcpnForm from './EditSvcpnForm';
import { clearEditSvcpnError } from '../../store/svcpns/index';
import { withUser } from '../../context/UserContext';

const StyledButton = styled(Button)`
  width: 60px;
  height: 20px;
  border-radius: 8px;
  padding: 0;
`;

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const EditSvcpns = ({ row, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearEditSvcpnError());
  }, [isOpen]);

  return (
    <>
      {(user && user.role === 'ADMIN') || user.role === 'SALES' ? (
        <StyledButton color="primary" onClick={toggleModal}>
          Edit
        </StyledButton>
      ) : (
        <StyledButton color="primary" disabled onClick={toggleModal}>
          Edit
        </StyledButton>
      )}

      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>Edit SVCPN</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <EditSvcpnForm svcpn={row} toggleModal={toggleModal} />
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default withUser(EditSvcpns);

EditSvcpns.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
