import React from 'react';
import styled from 'styled-components';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { useUsers } from '../../hooks';
import CloseIcon from '../../components/Common/CloseIcon';
import CreateUserForm from './CreateUserForm';

const StyledButton = styled(Button)`
  display: inline-block;
  width: 200px;
  margin-bottom: 10px;
`;

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const CreateUser = () => {
  const { isModalOpen, modalType, toggleModal } = useUsers();

  return (
    <>
      <StyledButton color="primary" onClick={() => toggleModal('CreateUser')}>
        <span>Create New User</span>
      </StyledButton>
      <StyledModal
        toggle={toggleModal}
        isOpen={isModalOpen && modalType === 'CreateUser'}
      >
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>Create New User</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <CreateUserForm />
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default CreateUser;
