import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';
import { format } from '../../helpers/formatter';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 500px;
`;

const CloseButton = styled(Button)`
  border: none;
  padding: 0;
`;

const BottomRow = styled(Row)`
  border-top: 1px #d3d3d3 solid;
  margin-top: 15px;
`;

const StyledButton = styled(Button)`
  width: 130px;
  font-size: 16px;
  border-top: 1px #d3d3d3 solid;
  margin-top: 15px;
  font-size: 14px;
`;

const ReprocessIndulgences = ({
  isOpen,
  toggleModal,
  indulgencesToReprocess,
  handleReprocess,
}) => {
  return (
    <StyledModal toggle={toggleModal} isOpen={isOpen}>
      <ModalBody>
        <HeaderRow>
          <Col xs={11}>
            <h4>Records re-processing</h4>
          </Col>
          <Col xs={1}>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Col>
        </HeaderRow>
        <Row>
          <Col>
            Changing the Offset Date for this Complete Retirement will result in
            the re-processing of {format(indulgencesToReprocess?.length)}{' '}
            records, which will then be re-transmitted.
          </Col>
        </Row>
        <BottomRow>
          <Col sm={4}></Col>
          <Col sm={4}>
            <StyledButton onClick={toggleModal} color="secondary">
              Cancel
            </StyledButton>
          </Col>
          <Col sm={4}>
            <StyledButton
              onClick={() => handleReprocess(indulgencesToReprocess)}
              color="primary"
            >
              Ok, proceed
            </StyledButton>
          </Col>
        </BottomRow>
      </ModalBody>
    </StyledModal>
  );
};

ReprocessIndulgences.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  indulgencesToReprocess: PropTypes.array.isRequired,
  handleReprocess: PropTypes.func.isRequired,
};

export default ReprocessIndulgences;
