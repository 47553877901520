import { asyncAction } from '../common';
import {
  GET_EMAIL_RECIPIENTS,
  CREATE_EMAIL_RECIPIENT,
  DELETE_EMAIL_RECIPIENT,
  UPDATE_EMAIL_RECIPIENT,
} from './actionTypes';

export const getEmailRecipients = asyncAction(GET_EMAIL_RECIPIENTS);
export const createEmailRecipient = asyncAction(CREATE_EMAIL_RECIPIENT);
export const deleteEmailRecipient = asyncAction(DELETE_EMAIL_RECIPIENT);
export const updateEmailRecipient = asyncAction(UPDATE_EMAIL_RECIPIENT);
