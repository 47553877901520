import MetaTags from 'react-meta-tags';
import React from 'react';
import { Row, Col, Card, CardBody, Container, CardTitle } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import email from '../../assets/images/email.svg';

const EmailSentPage = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <MetaTags>
        <title>Reset password</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={6}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <CardTitle>Reset Password</CardTitle>
                    <p>
                      An email has been sent to
                      <b> {history?.location?.state?.email} </b> with
                      instructions to reset your password.
                    </p>
                    <Row>
                      <img src={email} alt="email image" />
                    </Row>
                    <Row>
                      <p>
                        If you do not receive the email within a few minutes,
                        please check your spam folder(s) and/or contact ClimeCo
                        IT support
                      </p>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EmailSentPage.propTypes = {};

export default EmailSentPage;
