import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from '../../components/Common/CloseIcon';

import {
  clearBillingsError,
  sentBillingSummary,
  editBillingSummary,
} from '../../store/billings';
import { withUser } from '../../context/UserContext';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 400px;
`;

const CloseButton = styled(Button)`
  border: none;
`;

const SaveButton = styled(Button)`
  width: 140px;
  font-size: 16px;
`;

const SendDateModal = ({ row, isOpen, toggleModal, toggleEditModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearBillingsError());
  }, [isOpen]);

  const onSetDate = () => {
    toggleModal();
    toggleEditModal();
    const params = { status: 'SENT' };
    dispatch(sentBillingSummary({ id: row.id, params }));
  };

  const onNotSetDate = () => {
    toggleModal();
    const params = { status: 'SENT' };
    dispatch(editBillingSummary({ id: row.id, params }));
  };
  return (
    <>
      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={10}>
              <h4>Add Send Date</h4>
            </Col>
            <Col xs={2}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>
          <Row>
            <Col>
              <p>
                You’ve marked the Billing Summary as sent. Would you like to add
                the date now? If not, you can add the date later in the “Edit
                Billing Summary” modal window.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-around">
            <Col md={7}>
              <SaveButton onClick={onNotSetDate}>Add later</SaveButton>
            </Col>
            <Col md={5}>
              <SaveButton onClick={onSetDate} color="primary">
                Yes, add now
              </SaveButton>
            </Col>
          </Row>
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default withUser(SendDateModal);

SendDateModal.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggleEditModal: PropTypes.func.isRequired,
};
