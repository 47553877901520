import { asyncAction } from '../common';
import {
  GET_ALL_PROJECTS,
  EDIT_PROJECT,
  DELETE_PROJECT,
  ADD_PROJECT,
  TOGGLE_ADD_PROJECT,
  EXPORT_PROJECT_CSV,
  CLEAR_PROJECT_ERROR,
} from './actionTypes';

export const getAllProjects = asyncAction(GET_ALL_PROJECTS);
export const editProject = asyncAction(EDIT_PROJECT);
export const deleteProject = asyncAction(DELETE_PROJECT);
export const addProject = asyncAction(ADD_PROJECT);
export const toggleAddProjectModal = asyncAction(TOGGLE_ADD_PROJECT);
export const exportCsv = asyncAction(EXPORT_PROJECT_CSV);
export const clearProjectError = asyncAction(CLEAR_PROJECT_ERROR);
