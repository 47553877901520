import { makeReducer } from '../common';
import { GET_ORDER } from './actionTypes';

export default makeReducer(
  {
    [GET_ORDER.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [GET_ORDER.SUCCESS]: (state, { payload: { order } }) => ({
      ...state,
      order,
      isLoading: false,
    }),
    [GET_ORDER.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
  },
  {
    order: null,
    error: null,
    isLoading: false,
  },
);
