import { makeReducer } from '../common';
import {
  GET_PROJECTS,
  TOGGLE_ADD_PROJECT,
  FIND_RELATED_RETIREMENT,
  CLEAR_RELATED_RETIREMENT,
} from './actionTypes';

export default makeReducer(
  {
    [GET_PROJECTS.RUN]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [GET_PROJECTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      projects: payload,
      isLoading: false,
    }),
    [GET_PROJECTS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [TOGGLE_ADD_PROJECT.RUN]: (state) => ({
      ...state,
      isOpen: !state.isOpen,
      error: null,
    }),
    [TOGGLE_ADD_PROJECT.SUCCESS]: (state) => ({
      ...state,
      isOpen: false,
      isLoading: false,
    }),
    [TOGGLE_ADD_PROJECT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpen: false,
      error,
    }),
    [FIND_RELATED_RETIREMENT.SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      relatedRetirement: data,
    }),
    [FIND_RELATED_RETIREMENT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      relatedRetirement: null,
      error,
    }),
    [CLEAR_RELATED_RETIREMENT.RUN]: (state) => ({
      ...state,
      relatedRetirement: null,
    }),
  },
  {
    projects: [],
    relatedRetirement: null,
    error: null,
    isLoading: false,
    isOpen: false,
  },
);
