import React, { useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Input, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import TablePage from '../../components/Tables/Page';
import OrdersTable from '../../components/Tables/OrdersTable';
import OrdersFilter from '../../components/Tables/OrdersFilter';
import SortCaret from '../../components/Tables/SortCaret';
import OrderActionButtons from './OrderActionButtons';
import { useOrders } from '../../hooks';
import { setRowDataArray } from '../../store/orders/actions';

const ViewRecordsButton = styled(Button)`
  width: 133px;
  height: 29px;
  border-radius: 15px;
  padding: 4px 20px;
`;

export const statusFormatter = (cell, row) => {
  switch (row.status) {
    case 'BILLED':
      return (
        <span
          style={{
            color: '#343A40',
            background: '#CCCECF',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '0px 10px',
          }}
        >
          Billed
        </span>
      );
    case 'UNPROCESSED':
      return (
        <span
          style={{
            color: '#F46A6A',
            background: '#FCDADA',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '0px 10px',
          }}
        >
          Unprocessed
        </span>
      );
    case 'READY':
      return (
        <span
          style={{
            color: '#F1B44C',
            background: '#FBECD2',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '0px 10px',
          }}
        >
          Ready
        </span>
      );
    case 'PROCESSED':
      return (
        <span
          style={{
            color: '#34C38F',
            background: '#CCF0E3',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '0px 10px',
          }}
        >
          Processed
        </span>
      );
    default:
      return (
        <span
          style={{
            color: '#F46A6A',
            background: '#FCDADA',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '0px 10px',
          }}
        >
          Unknown
        </span>
      );
  }
};

export const dateFormatter = (cell) => {
  if (!cell) {
    return '';
  }

  return moment.utc(cell).format('YYYY.MM.DD');
};

const Orders = () => {
  const {
    data,
    isLoading,
    filteredCount,
    sortableColumns,
    isOpenAssignModal,
    params,
    updateParams,
    filters,
    availableFilters,
    makeSetFilter,
    clearFilter,
    error,
    isOpenBilledModal,
    rowDataArray,
  } = useOrders();

  const dispatch = useDispatch();
  const history = useHistory();
  const columns = [
    {
      dataField: 'orderedAt',
      text: 'Order Date',
      formatter: dateFormatter,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'regionName',
      text: 'Geography',
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'totalRecords',
      text: 'Total Records',
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'totalOffset',
      text: 'Total Offsets',
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'totalRetirement',
      text: 'Retired Offsets',
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'totalRemaning',
      text: 'Total Remaining',
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: statusFormatter,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => (
        <ViewRecordsButton
          color="primary"
          onClick={() => history.push(`/orders/${row.id}`)}
        >
          View records
        </ViewRecordsButton>
      ),
      headerStyle: () => {
        return { width: '15%' };
      },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    selected: rowDataArray.map((row) => row.id),
    style: { backgroundColor: '#556EE654' },
    selectionHeaderRenderer: ({ indeterminate, checked }) => (
      <Input
        type="checkbox"
        readOnly
        checked={checked}
        innerRef={(input) => {
          // eslint-disable-next-line no-param-reassign
          if (input) input.indeterminate = indeterminate;
        }}
      />
    ),
    selectionRenderer: ({ checked }) => (
      <Input type="checkbox" readOnly checked={checked} />
    ),
    onSelect: (row, isSelect) => {
      if (isSelect === true) {
        dispatch(setRowDataArray.success([...rowDataArray, row]));
      } else {
        dispatch(
          setRowDataArray.success(rowDataArray.filter((x) => x.id !== row.id)),
        );
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect === true) {
        dispatch(setRowDataArray.success([...rowDataArray, ...rows]));
      } else {
        const idsToRemove = rows.map((row) => row.id);
        dispatch(
          setRowDataArray.success(
            rowDataArray.filter((x) => !idsToRemove.includes(x.id)),
          ),
        );
      }
    },
  };

  const columnsWithSorting = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        sort: sortableColumns.includes(column.dataField),
        sortCaret: (order) => <SortCaret order={order} />,
      })),
    [sortableColumns.join('')],
  );

  const onTableChange = (type, newState) => {
    if (type === 'sort') {
      updateParams({
        ...params,
        sortingColumn: newState.sortField,
        sortingDirection: newState.sortOrder,
      });
    }
    if (type === 'pagination') {
      updateParams({
        ...params,
        page: newState.page,
        pageSize: newState.sizePerPage,
      });
    }
  };

  return (
    <TablePage title="Orders" subTitle="List of orders">
      <>
        {rowDataArray && rowDataArray.length > 0 ? (
          <OrderActionButtons
            isOpenAssignModal={isOpenAssignModal}
            selectedOrders={rowDataArray}
            params={params}
            error={error}
            data={data}
            isOpenBilledModal={isOpenBilledModal}
          />
        ) : (
          <OrdersFilter
            makeSetFilter={makeSetFilter}
            availableFilters={availableFilters}
            filters={filters}
            clearFilter={clearFilter}
          />
        )}
        <OrdersTable
          data={data}
          page={params.page}
          isLoading={isLoading}
          sizePerPage={params.pageSize}
          sort={{
            dataField: params.sortingColumn,
            order: params.sortingDirection,
          }}
          totalSize={filteredCount}
          columns={columnsWithSorting}
          onTableChange={onTableChange}
          selectRow={selectRow}
        />
      </>
    </TablePage>
  );
};

export default Orders;
