import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Form, Button } from 'reactstrap';
import cx from 'classnames';
import { useToggle } from '../../hooks';
import IconButton from './IconButton';
import SettingsDropdown from './SettingsDropdown';
import {
  SerialNumberFilter,
  OffSetFilter,
  SvcpnFilter,
  MachineShipDateFilter,
  ProjectFilter,
  StatusFilter,
  ShippedToCustomerNameFiler,
  SoldToCustomerNameFiler,
  ShipCountryFilter,
  ReferenceFiler,
  SalesOrderNumberFilter,
  UidFilter,
  ProductFilter,
} from './Filters';

const ClearFilterButton = styled(Button)`
  margin-right: 24px;
  margin-left: 24px;
  border-radius: 5px;
`;

const FilterRecorsOneOrder = ({
  filters,
  availableFilters,
  makeSetFilter,
  clearFilter,
  switchableColumns,
  setSwitchableColumns,
}) => {
  const [isFilterShown, toggleFilterShown] = useToggle();
  const [isSettingsShown, toggleSettingsShown] = useToggle();
  const hasFilter =
    filters &&
    Object.keys(filters).filter(
      (key) => !['serialNumber', 'orderId'].includes(key),
    ).length > 0;

  return (
    <>
      <Row className={cx('mt-3', { 'mb-3': !isFilterShown })}>
        <Form className="align-items-center">
          <Row>
            <Col className="filter-multi-search-wrapper">
              <SerialNumberFilter
                filters={filters}
                makeSetFilter={makeSetFilter}
              />
            </Col>
            <Col xs="auto">
              <span
                className={cx({
                  'red-dot-container': hasFilter,
                })}
              >
                <IconButton
                  color="primary"
                  icon="bx-slider"
                  text="Filter"
                  onClick={toggleFilterShown}
                />
              </span>
              <ClearFilterButton color="primary" outline onClick={clearFilter}>
                <span>Clear filter</span>
              </ClearFilterButton>
              <SettingsDropdown
                isSettingsShown={isSettingsShown}
                onClick={toggleSettingsShown}
                switchableColumns={switchableColumns}
                setSwitchableColumns={setSwitchableColumns}
              />
            </Col>
          </Row>
        </Form>
      </Row>

      {isFilterShown && (
        <>
          <Row>
            <Form className="align-items-center">
              <Row xs={12} className="mt-3 filter-multi-search-wrapper">
                <SalesOrderNumberFilter
                  filters={filters}
                  makeSetFilter={makeSetFilter}
                />
              </Row>
              <Row xs={12} className="mt-3 filter-multi-search-wrapper">
                <UidFilter filters={filters} makeSetFilter={makeSetFilter} />
              </Row>
            </Form>
          </Row>
          <Row>
            <Form className="align-items-center">
              <Row className="mt-3">
                <Col xs={3}>
                  <ShippedToCustomerNameFiler
                    filters={filters}
                    makeSetFilter={makeSetFilter}
                  />
                </Col>
                <Col xs={3}>
                  <ShipCountryFilter
                    filters={filters}
                    makeSetFilter={makeSetFilter}
                  />
                </Col>
                <Col xs={3}>
                  <OffSetFilter
                    makeSetFilter={makeSetFilter}
                    filters={filters}
                  />
                </Col>
                <Col xs={3}>
                  <StatusFilter
                    availableFilters={availableFilters}
                    makeSetFilter={makeSetFilter}
                    filters={filters}
                  />
                </Col>
              </Row>
            </Form>
          </Row>
          <Row className="mb-3">
            <Form className="align-items-center">
              <Row className="mt-3">
                <Col xs={2}>
                  <ProductFilter
                    filters={filters}
                    makeSetFilter={makeSetFilter}
                  />
                </Col>
                <Col xs={2}>
                  <SoldToCustomerNameFiler
                    filters={filters}
                    makeSetFilter={makeSetFilter}
                  />
                </Col>
                <Col xs={2}>
                  <SvcpnFilter
                    availableFilters={availableFilters}
                    makeSetFilter={makeSetFilter}
                    filters={filters}
                  />
                </Col>
                <Col xs={2}>
                  <MachineShipDateFilter
                    makeSetFilter={makeSetFilter}
                    filters={filters}
                  />
                </Col>
                <Col xs={2}>
                  <ProjectFilter
                    availableFilters={availableFilters}
                    makeSetFilter={makeSetFilter}
                    filters={filters}
                  />
                </Col>
                <Col xs={2}>
                  <ReferenceFiler
                    availableFilters={availableFilters}
                    makeSetFilter={makeSetFilter}
                    filters={filters}
                  />
                </Col>
              </Row>
            </Form>
          </Row>
        </>
      )}
    </>
  );
};

FilterRecorsOneOrder.propTypes = {
  filters: PropTypes.object.isRequired,
  availableFilters: PropTypes.object.isRequired,
  makeSetFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  switchableColumns: PropTypes.array.isRequired,
  setSwitchableColumns: PropTypes.func.isRequired,
  isSettingsEnabled: PropTypes.bool.isRequired,
};

export default FilterRecorsOneOrder;
